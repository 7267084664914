import { all, call, fork, put, takeLatest } from 'redux-saga/effects'
import actionTypes from '../action-types'
import callFetchApi from '../../services/api'

export function* getCurrencysSaga(action) {
  try {
    const { url } = action.payload
    const response = yield call(callFetchApi, url, {}, 'GET')
    let payload = [{
      conversionRate: 1,
      country: "",
      name: "INR",
      symbol: '₹'
    }]
    if (response.status === 200) {
      payload = response.data
    }
    yield put({
      type: actionTypes.UPDATE_CURRENCYS,
      payload,
    })
  } catch (error) {
    console.log('here')
  }
}


function* watchGetCurrencysSaga() {
  yield takeLatest(actionTypes.GET_CURRENCY, getCurrencysSaga)
}

export default function* currencySagas() {
  yield all([
    fork(watchGetCurrencysSaga)
  ])
}
