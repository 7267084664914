export const Application_Name = ''
export const Whatapp_Number = '919094950000';
export const Whatsapp_Text = '';

const Dev = 'dev'
const Test = 'test'
const Prod = 'prod'
const env = Prod

export const API_URL = env === Dev ? 'http://localhost:8080' : env === Test ? 'http://68.183.93.166:8080/api-0.0.1' : 'https://narayanapearls.in/api'

export const Merchant_Key = 'YHxluM'

export const Payment_Gateway_Url = 'https://secure.payu.in/_payment'

export const encryptionKey = 'm2oRqUhKsTxCssQw'
export const iV = 'xFtdUPxqP3dfGeRv'
