import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import CartActions from "../../../../redux/actions/cart.actions";
import cartSelector from "../../../../redux/selectors/cart.selector";

const Coupon = ({ cartList, rate, symbol, currency }) => {
  const dispatch = useDispatch();
  const [couponInput, updateCouponInput] = useState("");
  const [couponInputError, updateCouponInputError] = useState("");
  const couponMessage = useSelector(cartSelector.couponMessage);
  const coupon = useSelector(cartSelector.coupon)
  
  useEffect(()=>{
    if(coupon){
      updateCouponInput(coupon.code)
    }
  },[coupon])
  const couponInputChange = (e) => {
    updateCouponInputError("");
    updateCouponInput(e.target.value);
  };

  const submitCoupon = () => {
    if (couponInput !== "") {
      dispatch(CartActions.applyCouponAction({ couponCode: couponInput.trim(), cartList, rate, symbol, currency }));
    } else {
      updateCouponInputError("Please enter coupon code");
    }
  };

  const removeCoupon = () => {
    updateCouponInput('')
    dispatch(CartActions.removeCouponAction());
  };

  return (
    <div className="discount-code-wrapper">
      <div className="title-wrap">
        <h4 className="cart-bottom-title section-bg-gray">Use Coupon Code</h4>
      </div>
      <div className="discount-code">
        <p>Have a coupon code ? Please enter it to avail offers.</p>
        <p style={{fontSize:12}}><span style={{color:'red'}}>*</span>&nbsp;Coupon code is case sensitive</p>
        <form>
          <input
            type="text"
            required
            name="name"
            className="coupon-input"
            placeholder="Enter coupon"
            value={couponInput}
            onChange={(e) => couponInputChange(e)}
            disabled = {coupon !== null}
          />
          {coupon ? (
            <button className="cart-btn-2" type="button" onClick={removeCoupon}>
              Remove Coupon
            </button>
          ) : (
            <button className="cart-btn-2" type="button" onClick={submitCoupon}>
              Apply Coupon
            </button>
          )}
          {couponInputError !== "" && <div className="error">{couponInputError}</div>}
          {couponMessage !== "" && <div className="success">{couponMessage}</div>}
        </form>
      </div>
    </div>
  );
};

export default Coupon;
