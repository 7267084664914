import homePageActions from "../action-types";

const initialState = {
  banners: [],
  categories: [],
  attributes: [],
  subCategories: [],
  topDeals: [],
  topDealsLimit: 4,
};

export default function homePageReducer(state = initialState, action) {
  const newState = Object.assign({}, state);
  switch (action.type) {
    case homePageActions.UPDATE_BANNERS:
      newState.banners = action.payload;
      return newState;

    case homePageActions.UPDATE_CATEGORIES:
      newState.categories = action.payload;
      return newState;

    case homePageActions.UPDATE_ATTRIBUTES:
      newState.attributes = action.payload;
      return newState;

    case homePageActions.UPDATE_SUB_CATEGORIES:
      newState.subCategories = [...action.payload];
      return newState;

    case homePageActions.UPDATE_TOP_DEALS:
      newState.topDeals = action.payload;
      return newState;

    default:
      return state;
  }
}
