import { CREATE_CUSTOMER_URL, LOGIN_URL, REFRESH_CUSTOMER_URL, GET_CUSTOMER_ORDERS_URL,RESET_PASSWORD_URL } from "../../services/api-service";
import actionTypes from "../action-types";

const updateGuestFlowAction = (data) => {
  return {
    type: actionTypes.UPDATE_GUEST_FLOW,
    payload: data,
  };
};

const signInCustomerAction = (data, previousRoute, history, sendNotification) => {
  return {
    type: actionTypes.SIGN_IN_CUSTOMER,
    payload: {
      url: LOGIN_URL,
      data,
      previousRoute,
      history,
      sendNotification,
    },
  };
};

const createCustomerAction = (data, previousRoute, history) => {
  return {
    type: actionTypes.CREATE_CUSTOMER,
    payload: {
      url: CREATE_CUSTOMER_URL,
      data,
      previousRoute,
      history,
    },
  };
};

const refreshCustomerAction = (data) => {
  return {
    type: actionTypes.REFRESH_CUSTOMER,
    payload: {
      url: REFRESH_CUSTOMER_URL,
      data,
    },
  };
};

const getCustomerOrdersAction = (data) => {
  return {
    type: actionTypes.GET_CUSTOMER_ORDERS,
    payload: {
      url: GET_CUSTOMER_ORDERS_URL,
      data,
    },
  };
};

const logoutAction = () => {
  return {
    type: actionTypes.LOGOUT,
  };
};

const setCustomerPasswordUpdateStatus = data => {
  return {
    type: actionTypes.CUSTOMER_PASSWORD_UPDATE_STATUS,
    payload: data,
  }
}

const resetPasswordAction = data => {
  return {
    type: actionTypes.RESET_PASSWORD,
    payload: {
      url: RESET_PASSWORD_URL,
      data,
    },
  }
}

const CustomerActions = {
  createCustomerAction,
  signInCustomerAction,
  updateGuestFlowAction,
  refreshCustomerAction,
  logoutAction,
  getCustomerOrdersAction,
  setCustomerPasswordUpdateStatus,
  resetPasswordAction
};

export default CustomerActions;
