import PropTypes from "prop-types";
import React from "react";
import Currency from "../../header-top/currency/currency";
/* import { multilanguage, changeLanguage } from "redux-multilanguage";
import { connect } from "react-redux";
// import { setCurrency } from "../../../redux/actions/currencyActions"; */

const MobileCurrency = ({closeMobileMenu}) => {

  return (
    <div className="mobile-menu-middle">
      <div className="lang-curr-style">
        <span className="title mb-2">Choose Currency</span>
        <Currency mobile={true} closeMobileMenu= {closeMobileMenu} />
      </div>
    </div>
  );
};

export default MobileCurrency;
