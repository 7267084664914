import PropTypes from "prop-types";
import React, { Fragment, useEffect, useState } from "react";
import { LightgalleryProvider, LightgalleryItem } from "react-lightgallery";
import Swiper from "react-id-swiper";
import ProductZoom from "../../product-zoom/product-zoom";

const ProductImageGalleryLeftThumb = ({ product, thumbPosition, productImages }) => {
  const [gallerySwiper, getGallerySwiper] = useState(null);
  const [thumbnailSwiper, getThumbnailSwiper] = useState(null);

  const [gallerySwiperParams, updateGallerySwiperParams] = useState({
    getSwiper: getGallerySwiper,
    spaceBetween: 10,
    loopedSlides: productImages.length,
    loop: true,
    effect: "fade",
  });
  const [thumbnailSwiperParams, updateThumbnailSwiperParams] = useState({
    getSwiper: getThumbnailSwiper,
    spaceBetween: 10,
    slidesPerView: productImages.length,
    loopedSlides: productImages.length,
    touchRatio: 0.2,
    loop: true,
    slideToClickedSlide: true,
    direction: "vertical",
    breakpoints: {
      1200: {
        slidesPerView: productImages.length,
        direction: "vertical",
      },
      992: {
        slidesPerView: productImages.length,
        direction: "horizontal",
      },
      768: {
        slidesPerView: productImages.length,
        direction: "horizontal",
      },
      640: {
        slidesPerView: productImages.length,
        direction: "horizontal",
      },
      320: {
        slidesPerView: productImages.length,
        direction: "horizontal",
      },
    },
  });
  // effect for swiper slider synchronize
  useEffect(() => {
    if (gallerySwiper !== null && gallerySwiper.controller && thumbnailSwiper !== null && thumbnailSwiper.controller) {
      gallerySwiper.controller.control = thumbnailSwiper;
      thumbnailSwiper.controller.control = gallerySwiper;
    }
  }, [gallerySwiper, thumbnailSwiper]);

  useEffect(() => {
    /* if (productImages.length === 0) {
      const el = document.getElementById('lightgallery');
      if(el !== undefined && el !== null){
        el.lightgallery().destroy(true)
      }
    } */
  }, [productImages]);

  useEffect(() => {
    const galleryParams = {
      getSwiper: getGallerySwiper,
      spaceBetween: 10,
      loopedSlides: productImages.length,
      loop: true,
      effect: "fade",
    };
    const thumbnailParams = {
      getSwiper: getThumbnailSwiper,
      spaceBetween: 10,
      slidesPerView: productImages.length,
      loopedSlides: productImages.length,
      touchRatio: 0.2,
      loop: true,
      slideToClickedSlide: true,
      direction: "vertical",
      breakpoints: {
        1200: {
          slidesPerView: productImages.length,
          direction: "vertical",
        },
        992: {
          slidesPerView: productImages.length,
          direction: "horizontal",
        },
        768: {
          slidesPerView: productImages.length,
          direction: "horizontal",
        },
        640: {
          slidesPerView: productImages.length,
          direction: "horizontal",
        },
        320: {
          slidesPerView: productImages.length,
          direction: "horizontal",
        },
      },
    };
    updateGallerySwiperParams({ ...galleryParams });
    updateThumbnailSwiperParams({ ...thumbnailParams });
  }, [productImages]);

  return (
    <Fragment>
      <div className="row row-5">
        <div className={` ${thumbPosition && thumbPosition === "left" ? "col-xl-10 order-1 order-xl-2" : "col-xl-10"}`}>
          <div className="product-large-image-wrapper lightgallery">
            {/* <LightgalleryProvider lightgallerySettings={
                    {
                      dynamic:true
                    }
                }> */}
            {/* <Swiper {...gallerySwiperParams}>
                {productImages &&
                  productImages.map((single, key) => {
                    return (
                      <div key={key}> */}
            {/* <LightgalleryItem group="any" src={single}>
                          <button>
                            <i className="pe-7s-expand1"></i>
                          </button>
                        </LightgalleryItem> */}
            {/* <div className="single-image">
                          <img src={single} className="img-fluid" alt="" />
                        </div>
                      </div>
                    );
                  })}
              </Swiper> */}
            {/* </LightgalleryProvider> */}

            <ProductZoom src={productImages[0]} />
          </div>
        </div>
        <div className={` ${thumbPosition && thumbPosition === "left" ? "col-xl-2 order-2 order-xl-1" : "col-xl-2"}`}>
          <div className="product-small-image-wrapper product-small-image-wrapper--side-thumb">
            {/* <Swiper {...thumbnailSwiperParams}>
              {productImages &&
                productImages.map((single, key) => {
                  return (
                    <div key={key}>
                      <div className="single-image">
                        <img src={single} className="img-fluid" alt="" />
                      </div>
                    </div>
                  );
                })}
            </Swiper> */}
          </div>
        </div>
      </div>
    </Fragment>
  );
};

ProductImageGalleryLeftThumb.propTypes = {
  product: PropTypes.object,
  thumbPosition: PropTypes.string,
};

export default ProductImageGalleryLeftThumb;
