import React from "react";
import commonService from "../../../services/common-service";

const CheckoutSummary = ({ subTotal, discount, couponDiscount, totalDiscount, total, symbol, rate, currency, shippingCharge }) => {
  return (
    <>
      <ul>
        <li className="order-price">Sub total</li>
        <li>
          <span className="">
            {symbol}{` `}
            {commonService.getConvertedPrice(subTotal, rate, currency)}
          </span>
        </li>
      </ul>
      {discount !== null && discount > 0 && (
        <ul>
          <li className="order-price">Discount</li>
          <li>
            <span className="">
              {symbol}{` `}
              {commonService.getConvertedPrice(discount, rate, currency)}
            </span>
          </li>
        </ul>
      )}
      {couponDiscount !== null && couponDiscount > 0 && (
        <ul>
          <li className="order-price">Coupon discount</li>
          <li>
            <span className="">
              {symbol}{` `}
              {commonService.getConvertedPrice(couponDiscount, rate, currency)}
            </span>
          </li>
        </ul>
      )}
      {discount !== null && discount > 0 && couponDiscount !== null && couponDiscount > 0 && (
        <ul>
          <li className="order-price">Total discount</li>
          <li>
            <span className="">
              {symbol}{` `}
              {commonService.getConvertedPrice(totalDiscount, rate, currency)}
            </span>
          </li>
        </ul>
      )}

      <ul>
        <li className="order-price">Shipping Charge</li>
        {shippingCharge !== null && shippingCharge > 0 ? (
          <li>
            <span className="">
              {symbol}{` `}
              {commonService.getConvertedPrice(shippingCharge, rate, currency)}
            </span>
          </li>
        ) : (
          <li>
            <span className="">Free Shipping</span>
          </li>
        )}
      </ul>

      <ul>
        <li className="order-total">Total</li>
        <li>
          <span className="">
            {symbol}{` `}
            {commonService.getConvertedPrice(total, rate, currency)}
          </span>
        </li>
      </ul>
    </>
  );
};

export default CheckoutSummary;
