import PropTypes from "prop-types";
import React, { useState, useEffect } from "react";
import headerService from "../../header.service";
import { useSelector } from "react-redux";
import { useHistory } from "react-router";
import homePageSelector from "../../../../redux/selectors/home-page.selector";

const MobileMenu = ({ categoryList, classname ,closeMobileMenu }) => {
  const history = useHistory();
  const [categoryMenu, updateCategoryMenu] = useState({});
  // menu-item-has-children
  useEffect(() => {
    const catObj = {};
    if (categoryList.length) {
      categoryList.forEach((category) => {
        catObj[category.name] = false;
      });
    }
    updateCategoryMenu(catObj);
  }, []);

  const toggleCategoryMenu = (selectedCategory) => {
    const catObj = {};

    categoryList.forEach((category) => {
      catObj[category.name] = false;
    });
    catObj[selectedCategory] = !categoryMenu[selectedCategory]

    updateCategoryMenu(catObj);
  };

  return (
    <ul>
      {categoryList.length > 0 &&
        categoryList.map((category) =>
          category.subCategoryList.length ? (
            <li key={category.id} className={classname}>
              <a onClick={() => toggleCategoryMenu(category.name)}>
                {category.name}
                {` `}
                <i className="fa fa-angle-down" /* onClick={() => toggleCategoryMenu(category.name)} */ />
              </a>
              {categoryMenu[category.name] && (
                <ul className={classname === "" ? "submenu" : "sub-menu"}>
                  {category.subCategoryList.map((subMenuItem) => (
                    <li
                      key={subMenuItem.id}
                      onClick={(e) => headerService.handleMenu(e, history, category.name, category.aliasName, subMenuItem.name, subMenuItem.aliasName, closeMobileMenu)}
                    >
                      <a>{subMenuItem.name}</a>
                    </li>
                  ))}
                </ul>
              )}
            </li>
          ) : (
            <li key={category.id} onClick={(e) => headerService.handleSubmenu(e, history, category.name, category.aliasName, closeMobileMenu)}>
              <a>{category.name}</a>
              {/* {classname === "" && <i className="fa fa-angle-down" />} */}
            </li>
          )
        )}
    </ul>
  );
};

export default MobileMenu;
