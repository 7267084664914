import { all, call, /*  call, */ fork, put, takeLatest } from 'redux-saga/effects'
import store from 'store'
import actionTypes from '../action-types'
import callFetchApi from '../../services/api'
import commonService from '../../services/common-service'
import CustomerActions from '../actions/customer.actions'

export function* createCustomerSaga(action) {
  try {
    yield updateCustomerBeingRegistered(true)
    const { url, data, previousRoute, history } = action.payload
    const response = yield call(callFetchApi, url, {}, 'POST', data)
    if (response.status === 200 || response.status === 201) {
      yield put({
        type: actionTypes.UPDATE_CUSTOMER_INFO,
        payload: response.data,
      })
      yield updateCustomerLoggedIn(true)
      commonService.showNotification('success', 'Account created ', 'Account created successfully')
      const customerSignInAction = CustomerActions.signInCustomerAction({
        password: data.password,
        username: data.username,
      },previousRoute,history,false)
      yield put(customerSignInAction)
    } else {
      const { errors } = response.data
      commonService.showErrorNotification(errors)
    }
    yield updateCustomerBeingRegistered(false)
  } catch (error) {
    commonService.showNotification('error', 'Account not created ', '')
    yield updateCustomerBeingRegistered(false)
  }
}

export function* signInCustomerSaga(action) {
  let notify = true
  try {
    yield updateCustomerBeingLoggedIn(true)
    const { url, data,previousRoute, history,sendNotification } = action.payload
    notify = sendNotification
    const response = yield call(callFetchApi, url, {}, 'POST', data)
    if (response.status === 200 || response.status === 201) {
      yield put({
        type: actionTypes.UPDATE_CUSTOMER_INFO,
        payload: response.data.customer,
      })
      yield updateCustomerLoggedIn(true)
      store.set('np-customer', response.data.token)
      if (notify) {
        commonService.showNotification('success', '', 'Signed in successfully')
      }
      history.push(previousRoute)
    } else {
      commonService.showNotification('error', '', 'Not a registered user')
    }
    yield updateCustomerBeingLoggedIn(false)
  } catch (error) {
    console.log('here')
    if(notify){
      commonService.showNotification('error', '', 'Not a registered user')
    }
    yield updateCustomerBeingLoggedIn(false)
  }
}

export function* refreshCustomerSaga(action) {
  try {
    yield updateCustomerBeingLoggedIn(true)
    const { url, data } = action.payload
    const response = yield call(callFetchApi, url, {}, 'POST', data)
    if (response.status === 200 || response.status === 201) {
      yield put({
        type: actionTypes.UPDATE_CUSTOMER_INFO,
        payload: response.data,
      })
      yield updateCustomerLoggedIn(true)
    }
    yield updateCustomerBeingLoggedIn(false)
  } catch (error) {
    console.log('failed to log in on refresh')
    
  }
}

function* updateCustomerBeingRegistered(data) {
  yield put({
    type: actionTypes.IS_CUSTOMER_BEING_REGISTERED,
    payload: data,
  })
}


function* updateCustomerLoggedIn(data) {
  yield put({
    type: actionTypes.UPDATE_CUSTOMER_LOGGED_IN,
    payload: data,
  })
}

function* updateCustomerBeingLoggedIn(data) {
  yield put({
    type: actionTypes.IS_CUSTOMER_BEING_LOGGED_IN,
    payload: data,
  })
}

export function* getCustomerOrdersSaga(action) {
  try {
    yield put({
      type: actionTypes.IS_CUSTOMER_ORDERS_BEING_FETCHED,
      payload: true,
    })
    const { url, data } = action.payload
    let payloadData = []
    const response = yield call(callFetchApi, `${url}/${data}`, {}, 'GET')
    yield put({
      type: actionTypes.IS_CUSTOMER_ORDERS_BEING_FETCHED,
      payload: false,
    })
    if (response.status === 200) {
      payloadData = response.data
    }
    yield put({
      type: actionTypes.UPDATE_CUSTOMER_ORDERS,
      payload: payloadData,
    })
  } catch (error) {
    console.log('here')
  }
}

export function* resetPasswordSaga(action) {
  try {
    yield put({
      type: actionTypes.IS_PASSWORD_BEING_UPDATED,
      payload: true,
    })
    const { url, data } = action.payload
    const response = yield call(callFetchApi, url, {}, 'POST', data)
    yield put({
      type: actionTypes.IS_PASSWORD_BEING_UPDATED,
      payload: false,
    })
    if (response !== undefined) {
      if (response.status === 200) {
        commonService.showNotification('success', 'Password updated', 'Password updated successfully')
      } else {
        const { errors } = response.data
        commonService.showErrorNotification(errors)
      }
    }
  } catch (error) {
    console.log('error ', error)
    yield put({
      type: actionTypes.IS_PASSWORD_BEING_UPDATED,
      payload: false,
    })
  }
}

function* watchResetPasswordSaga() {
  yield takeLatest(actionTypes.RESET_PASSWORD, resetPasswordSaga)
}

function* watchGetCustomerOrdersSaga() {
  yield takeLatest(actionTypes.GET_CUSTOMER_ORDERS, getCustomerOrdersSaga)
}

function* watchRefreshCustomerSaga() {
  yield takeLatest(actionTypes.REFRESH_CUSTOMER, refreshCustomerSaga)
}

function* watchCreateCustomerSaga() {
  yield takeLatest(actionTypes.CREATE_CUSTOMER, createCustomerSaga)
}

function* watchSignInCustomerSaga() {
  yield takeLatest(actionTypes.SIGN_IN_CUSTOMER, signInCustomerSaga)
}

/* 
function* watchUpdateCartFromCacheSaga() {
  yield takeLatest(actionTypes.UPDATE_CART_FROM_CACHE, updateCartFromCacheSaga)
} */

export default function* customerSagas() {
  yield all([
    fork(watchCreateCustomerSaga),
    fork(watchSignInCustomerSaga),
    fork(watchRefreshCustomerSaga),
    fork(watchGetCustomerOrdersSaga),
    fork(watchResetPasswordSaga)
  ])
}
