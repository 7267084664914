import PropTypes from "prop-types";
import React from "react";
import Filter from "./filter/filter";

const ShopSidebar = ({ filterProducts, sideSpaceClass }) => {
  
  return (
    <div className={`sidebar-style ${sideSpaceClass ? sideSpaceClass : ""}`}>
      {/* shop search */}
      {/* <ShopSearch /> */}
      <Filter filterProducts={filterProducts} />
    </div>
  );
};

ShopSidebar.propTypes = {
  filterProducts: PropTypes.func,
  sideSpaceClass: PropTypes.string
};

export default ShopSidebar;
