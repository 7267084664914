import { all,call, fork, takeLatest, put } from 'redux-saga/effects'
import actionTypes from '../action-types'
import callFetchApi from '../../services/api'
import paymentActions from '../actions/payment.actions'
import commonService from '../../services/common-service'
// import store from 'store'

export function* placeOrderSaga(action) {
  try {
    yield put({
      type: actionTypes.UPDATE_ORDER_LOADING,
      payload : true,
    })
    const { url,data,history } = action.payload
    const response = yield call(callFetchApi, url, {}, 'POST', data)
    let payload = null
    if (response.status === 200 || response.status === 201) {
      payload = response.data
      
      if(data.paymentMode === 'COD'){
        yield put({
          type: actionTypes.UPDATE_ORDER,
          payload,
        })
        
        // yield put({ type: actionTypes.RESET_CART })
        // store.remove('cart-products')
        history.push(`/orderSuccess/${response.data.id}/${data.orderCustomerInfo.orderCustomerPhone}`)
        /* yield put({
          type: actionTypes.UPDATE_ORDER_LOADING,
          payload : false,
        }) */
      } else {
        const productIdString = data.productList.map(product => product.id).toString();
        const paymentData = {
          amount : data.orderAmountInfo.orderTotal,
          productInfo : productIdString,
          firstName : data.orderCustomerInfo.orderCustomerName,
          email : data.orderCustomerInfo.orderCustomerEmail,
          phone : data.orderCustomerInfo.orderCustomerPhone,
          orderId : response.data.id
        }
        const paymentAction = paymentActions.getHashAction(paymentData)
        yield put(paymentAction)
      }
    }else{
      yield put({
        type: actionTypes.UPDATE_ORDER_LOADING,
        payload : false,
      })
      const { errors } = response.data
      commonService.showErrorNotification(errors)
    }
  } catch (error) {
  //  console.log('here',error)
  }
}

export function* getOrderByIdSaga(action) {
  const { url,data,history } = action.payload
  try {
    const response = yield call(callFetchApi, `${url}/${data.orderId}/${data.mobile}`, {}, 'POST', {})
    let payload = null
    if (response.status === 200 || response.status === 201) {
      payload = response.data
      yield put({
        type: actionTypes.UPDATE_ORDER,
        payload,
      })
    }else{
      history.push('/error')
    }
    
  } catch (error) {
    console.log('here',error)
    history.push('/error')
  }
}

export function* getTrackingInfoSaga(action) {
  try {
    yield put({
      type: actionTypes.IS_TRACKING_INFO_BEING_FETCHED,
      payload: true,
    })
    const { url, data } = action.payload
    const response = yield call(callFetchApi, `${url}/${data.orderId}`, {} , 'GET')
    let payload = []
    if (response.status === 200) {
      payload = response.data
    }
    yield put({
      type: actionTypes.UPDATE_TRACKING_INFO,
      payload,
    })
    yield put({
      type: actionTypes.IS_TRACKING_INFO_BEING_FETCHED,
      payload: false,
    })
  } catch (error) {
    yield put({
      type: actionTypes.UPDATE_TRACKING_INFO,
      payload: [],
    })
    yield put({
      type: actionTypes.IS_TRACKING_INFO_BEING_FETCHED,
      payload: false,
    })
  }
}

function* watchGetTrackingInfoSaga() {
  yield takeLatest(actionTypes.GET_TRACKING_INFO, getTrackingInfoSaga)
}

function* watchPlaceOrderSaga() {
  yield takeLatest(actionTypes.PLACE_ORDER, placeOrderSaga)
}

function* watchGetOrderByIdSaga() {
  yield takeLatest(actionTypes.GET_ORDER_BY_ID, getOrderByIdSaga)
}

export default function* orderSagas() {
  yield all([
    fork(watchPlaceOrderSaga),
    fork(watchGetOrderByIdSaga),
    fork(watchGetTrackingInfoSaga)
  ])
}
