import React from 'react'
import ReactDOM from 'react-dom'
import store, { sagaMiddleware } from './shared/store'
import saga from './redux/saga'
// import './index.scss'
import App from './App'
import * as serviceWorker from './serviceWorker'
import "./assets/scss/style.scss";

sagaMiddleware.run(saga)

ReactDOM.render(
  <React.StrictMode>
    <App store={store} />
  </React.StrictMode>,
  document.getElementById('root'),
)

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister()
