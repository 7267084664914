import React from "react";
import { useDispatch, useSelector } from "react-redux";
import currencyActions from "../../../../redux/actions/currency.actions";
import currencySelector from "../../../../redux/selectors/currency.selector";

const Currency = ({ mobile, closeMobileMenu }) => {
  const currencyList = useSelector(currencySelector.currencyList);
  const currency = useSelector(currencySelector.currency);
  const dispatch = useDispatch();

  const onCurrencyClick = (currency) => {
    dispatch(currencyActions.updateSelectedCurrencyAction(currency));
  };

  return mobile ? (
    <select
      onChange={(e) => {
        onCurrencyClick(currency);
        closeMobileMenu();
      }}
    >
      {currencyList.length &&
        currencyList.map((currency) => (
          <option key={currency.name} value={currency.name}>
            {currency.name}
          </option>
        ))}
    </select>
  ) : (
    <div className="same-language-currency use-style">
      <span>
        {currency} <i className="fa fa-angle-down" />
      </span>
      <div className="lang-car-dropdown">
        <ul>
          {currencyList.length &&
            currencyList.map((currency) => (
              <li key={currency.name}>
                <button value={currency.name} onClick={(e) => onCurrencyClick(currency)}>
                  {currency.name}
                </button>
              </li>
            ))}
        </ul>
      </div>
    </div>
  );
};

export default Currency;
