import React, { Fragment } from "react";
import ProductGridSingleHome from "../../components/product/ProductGridSingleHome";
import { useSelector, useDispatch } from 'react-redux'
import currencySelector from "../../redux/selectors/currency.selector";
import commonService from "../../services/common-service";
import CartActions from "../../redux/actions/cart.actions";
import cartSelector from "../../redux/selectors/cart.selector";
import { useHistory } from "react-router";
import { isMobile } from "react-device-detect";

const ProductGridHome = ({
  products,
  sliderClassName,
  spaceBottomClass
}) => {
  const dispatch = useDispatch()
  const history = useHistory()
  const symbol = useSelector(currencySelector.symbol)
  const currency = useSelector(currencySelector.currency)
  const rate = useSelector(currencySelector.rate)
  const cartList = useSelector(cartSelector.cartList)
  const wishList = useSelector(cartSelector.wishList)
  const compareList = useSelector(cartSelector.compareList)
  
  const addToCart=(product)=>{
    commonService.addToCart(dispatch, CartActions, product, cartList)
  }

  const addToWishList=(product)=>{
    commonService.addToWishList(dispatch, CartActions, product, wishList)
  }

  const addToCompareList=(product)=>{
    commonService.addToCompareList(dispatch, CartActions, product, compareList)
  }
  
  const buyNow=(product)=>{
    commonService.buyNow(history, dispatch, CartActions, product, cartList)
  }

  const removeFromCart=(product)=>{
    commonService.removeFromCart(dispatch, CartActions,cartList, product)
  }

  const removeFromWishList=(product)=>{
    commonService.removeFromWishList(dispatch, CartActions,product)
  }

  const removeFromCompareList=(product)=>{
    commonService.removeFromCompareList(dispatch, CartActions,product)
  }



  return (
    <div className='np-top-products'>
      { products !== undefined &&
        products.map(product => {
        return (
          isMobile ? 
          <div className='np-top-products-single'>
          <ProductGridSingleHome
            sliderClassName={sliderClassName}
            spaceBottomClass={spaceBottomClass}
            product={product}
            currency={currency}
            symbol={symbol}
            rate={rate}
            addToCart={addToCart}
            addToWishList={addToWishList}
            addToCompareList={addToCompareList}
            cartItem={commonService.isProductInCart(product, cartList)}
            wishlistItem={commonService.isProductInCart(product, wishList)}
            compareItem={commonService.isProductInCart(product, compareList)}
            key={product.id}
            buyNow={buyNow}
            removeFromCart={removeFromCart}
            removeFromWishList={removeFromWishList}
            removeFromCompareList={removeFromCompareList}
          />
          </div>
          :
          <ProductGridSingleHome
            sliderClassName={sliderClassName}
            spaceBottomClass={spaceBottomClass}
            product={product}
            currency={currency}
            symbol={symbol}
            rate={rate}
            addToCart={addToCart}
            addToWishList={addToWishList}
            addToCompareList={addToCompareList}
            cartItem={commonService.isProductInCart(product, cartList)}
            wishlistItem={commonService.isProductInCart(product, wishList)}
            compareItem={commonService.isProductInCart(product, compareList)}
            key={product.id}
            buyNow={buyNow}
            removeFromCart={removeFromCart}
            removeFromWishList={removeFromWishList}
            removeFromCompareList={removeFromCompareList}
          />
        );
      })}
    </div>
  );
};

export default ProductGridHome;
