import PropTypes from "prop-types";
import React , { useState, useEffect } from "react";
import headerService from "../../header.service";
import { useSelector } from "react-redux";
import { useHistory } from "react-router";
import homePageSelector from "../../../../redux/selectors/home-page.selector";
import MobileMenu from "./mobile-menu";

const MobileNavMenu = ({closeMobileMenu}) => {

  const categories = useSelector(homePageSelector.categoryList);
  const [categoryList, updateCategoryList] = useState([]);
  // menu-item-has-children
  useEffect(() => {
    updateCategoryList(categories);
  }, [categories]);
  
  return (
    <nav className="offcanvas-navigation" id="offcanvas-navigation">
      {/* {headerService.renderMenu(categoryList,history,'menu-item-has-children',closeMobileMenu)} */}
      <MobileMenu categoryList={categoryList} closeMobileMenu={closeMobileMenu} classname='menu-item-has-children' />
    </nav>
  );
};

MobileNavMenu.propTypes = {
  strings: PropTypes.object
};

export default MobileNavMenu;
