import React, { Fragment, useState, useEffect } from "react";
import { Link } from "react-router-dom";
// import { useToasts } from "react-toast-notifications";
// import Rating from "../../product/sub-components/ProductRating";
import ProductModal from "../../product/ProductModal";
import commonService from "../../../services/common-service";

const ProductGridListSingle = ({
  product,
  currency,
  symbol,
  rate,
  addToCart,
  addToWishList,
  addToCompareList,
  cartItem,
  wishlistItem,
  compareItem,
  sliderClassName,
  spaceBottomClass,
  buyNow,
  removeFromCart,
  removeFromWishList,
  removeFromCompareList,
}) => {
  const [modalShow, setModalShow] = useState(false);
  const [productImages, updateProductImages] = useState([]);

  useEffect(() => {
    const images = [];
    if (product.productImage.mainImageUrl !== undefined && product.productImage.mainImageUrl !== null && product.productImage.mainImageUrl !== "") {
      images.push(product.productImage.mainImageUrl);
    }
    if (
      product.productImage.otherImage1Url !== undefined &&
      product.productImage.otherImage1Url !== null &&
      product.productImage.otherImage1Url !== ""
    ) {
      images.push(product.productImage.otherImage1Url);
    }
    if (
      product.productImage.otherImage2Url !== undefined &&
      product.productImage.otherImage2Url !== null &&
      product.productImage.otherImage2Url !== ""
    ) {
      images.push(product.productImage.otherImage2Url);
    }
    if (
      product.productImage.otherImage3Url !== undefined &&
      product.productImage.otherImage3Url !== null &&
      product.productImage.otherImage3Url !== ""
    ) {
      images.push(product.productImage.otherImage3Url);
    }
    updateProductImages(images);
  }, []);

  return (
    <Fragment>
      <div className={`col-xl-4 col-sm-6 ${sliderClassName ? sliderClassName : ""}`}>
        <div className={`product-wrap ${spaceBottomClass ? spaceBottomClass : ""}`}>
          <div className="product-img">
            <Link to={`${process.env.PUBLIC_URL}/product/${product.name}/${product.id}`}>
              <img className="default-img" src={product.productImage.mainImageUrl} alt="" loading="lazy" />
              {product.productImage.otherImage1Url !== undefined &&
              product.productImage.otherImage1Url !== null &&
              product.productImage.otherImage1Url !== "" ? (
                <img className="hover-img" src={product.productImage.otherImage1Url} alt="" loading="lazy" />
              ) : (
                <img className="hover-img" src={product.productImage.mainImageUrl} alt="" loading="lazy" />
              )}
            </Link>
            {product.discountList !== undefined && product.discountList !== null && product.discountList.length ? (
              <div className="product-img-badges">
                {product.discountList.map((productDiscount) => {
                  return (
                    <span className="pink">
                      {commonService.getDiscountTagValue(
                        productDiscount.name,
                        productDiscount.discountType === "CASH" ? productDiscount.cashValue : productDiscount.percentageValue,
                        productDiscount.discountType,
                        symbol,
                        rate,
                        currency
                      )}
                    </span>
                  );
                })}
              </div>
            ) : (
              ""
            )}

            <div className="product-action">
              <div className="pro-same-action pro-wishlist">
                <button
                  className={wishlistItem ? "active" : ""}
                  title={wishlistItem ? "Added to wishlist" : "Add to wishlist"}
                  onClick={() => (wishlistItem ? removeFromWishList(product) : addToWishList(product))}
                >
                  <i className="pe-7s-like" />
                </button>
              </div>
              <div className="pro-same-action pro-cart">
                {/* {
                  cartItem
                }
                <a href={product.affiliateLink} rel="noopener noreferrer" target="_blank">
                  {" "}
                  Buy now{" "}
                </a> */}
                <button
                  onClick={() => (cartItem ? removeFromCart(product) : addToCart(product))}
                  className={cartItem ? "active" : ""}
                  title={cartItem ? "Remove from cart" : "Add to cart"}
                >
                  {" "}
                  <i className="pe-7s-cart"></i> {cartItem ? "Remove from cart" : "Add to cart"}
                </button>
              </div>
              <div className="pro-same-action pro-quickview">
                <button onClick={() => setModalShow(true)} title="Quick View">
                  <i className="pe-7s-look" />
                </button>
              </div>
            </div>
          </div>
          <div className="product-content text-center">
            <h3>
              <Link to={`${process.env.PUBLIC_URL}/product/${product.name}/${product.id}`}>{product.name}</Link>
            </h3>
            {/* {product.rating && product.rating > 0 ? (
              <div className="product-rating">
                <Rating ratingValue={product.rating} />
              </div>
            ) : (
              ""
            )} */}
            <div className="product-price">
              {product.discountList !== undefined && product.discountList !== null && product.discountList.length ? (
                <>
                  <span>
                    {symbol}
                    {` `}
                    {` `}
                    {commonService.getConvertedPrice(product.specialPrice, rate, currency)}
                  </span>{" "}
                  <span className="old">
                    {symbol}
                    {` `}
                    {commonService.getConvertedPrice(product.price, rate, currency)}
                  </span>
                </>
              ) : (
                <span>
                  {symbol}
                  {` `}
                  {commonService.getConvertedPrice(product.price, rate, currency)}
                </span>
              )}
            </div>
          </div>
        </div>
        <div className="shop-list-wrap mb-30">
          <div className="row">
            <div className="col-xl-4 col-md-5 col-sm-6">
              <div className="product-list-image-wrap">
                <div className="product-img">
                  <Link to={`${process.env.PUBLIC_URL}/product/${product.name}/${product.id}`}>
                    <img className="default-img img-fluid" src={product.productImage.mainImageUrl} alt="" />
                    {product.productImage.otherImage1Url !== undefined &&
                    product.productImage.otherImage1Url !== null &&
                    product.productImage.otherImage1Url !== "" ? (
                      <img className="hover-img img-fluid" src={product.productImage.otherImage1Url} alt="" />
                    ) : (
                      ""
                    )}
                  </Link>
                  {product.discountList !== undefined && product.discountList !== null && product.discountList.length ? (
                    <div className="product-img-badges">
                      {product.discountList.map((productDiscount) => {
                        return (
                          <span className="pink">
                            {commonService.getDiscountTagValue(
                              productDiscount.name,
                              productDiscount.discountType === "CASH" ? productDiscount.cashValue : productDiscount.percentageValue,
                              productDiscount.discountType,
                              symbol,
                              rate,
                              currency
                            )}
                          </span>
                        );
                      })}
                    </div>
                  ) : (
                    ""
                  )}
                </div>
              </div>
            </div>
            <div className="col-xl-8 col-md-7 col-sm-6">
              <div className="shop-list-content">
                <h3>
                  <Link to={`${process.env.PUBLIC_URL}/product/${product.name}/${product.id}`}>{product.name}</Link>
                </h3>
                <div className="product-list-price">
                  {product.discountList !== undefined && product.discountList !== null && product.discountList.length ? (
                    <>
                      <span>
                        {symbol}
                        {` `}
                        {commonService.getConvertedPrice(product.specialPrice, rate, currency)}
                      </span>{" "}
                      <span className="old">
                        {symbol}
                        {` `}
                        {commonService.getConvertedPrice(product.price, rate, currency)}
                      </span>
                    </>
                  ) : (
                    <span>
                      {symbol}
                      {` `}
                      {commonService.getConvertedPrice(product.price, rate, currency)}
                    </span>
                  )}
                </div>
                {/* {product.rating && product.rating > 0 ? (
                  <div className="rating-review">
                    <div className="product-list-rating">
                      <Rating ratingValue={product.rating} />
                    </div>
                  </div>
                ) : (
                  ""
                )} */}
                {product.shortDescription ? <p>{product.shortDescription}</p> : ""}

                <div className="shop-list-actions d-flex align-items-center">
                  <div className="shop-list-btn btn-hover align-btns">
                    {/* <button onClick={() => buyNow(product)} className="active" title="Buy now">
                      {" "}
                      <i className="pe-7s-cart"></i> Buy now
                    </button> */}
                    <button
                      onClick={() => (cartItem ? removeFromCart(product) : addToCart(product))}
                      className={cartItem ? "active" : ""}
                      title={cartItem ? "Remove from cart" : "Add to cart"}
                    >
                      {" "}
                      <i className="pe-7s-cart"></i> {cartItem ? "Remove from cart" : "Add to cart"}
                    </button>
                    <div className="pro-details-cart btn-hover">
                      <button onClick={() => buyNow(product)}> Buy Now </button>
                    </div>
                  </div>

                  <div className="shop-list-wishlist ml-10">
                    <button
                      className={wishlistItem ? "active" : ""}
                      // disabled={wishlistItem !== undefined}
                      title={wishlistItem ? "Remove from wishlist" : "Add to wishlist"}
                      onClick={() => (wishlistItem ? removeFromWishList(product) : addToWishList(product))}
                    >
                      {wishlistItem ? <i className="fa fa-heart" /> : <i className="fa fa-heart-o" />}
                    </button>
                  </div>
                  <div className="shop-list-compare ml-10">
                    <button
                      className={compareItem ? "active" : ""}
                      title={compareItem ? "Remove from compare" : "Add to compare"}
                      onClick={() => (compareItem ? removeFromCompareList(product) : addToCompareList(product))}
                    >
                      <i className="fa fa-retweet" />
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* product modal */}
      <ProductModal
        show={modalShow}
        onHide={() => setModalShow(false)}
        product={product}
        currency={currency}
        symbol={symbol}
        rate={rate}
        cartItem={cartItem}
        wishlistItem={wishlistItem}
        compareItem={compareItem}
        addToCart={addToCart}
        addToWishList={addToWishList}
        addToCompareList={addToCompareList}
        productImages={productImages}
        buyNow={buyNow}
        removeFromCart={removeFromCart}
        removeFromWishList={removeFromWishList}
        removeFromCompareList={removeFromCompareList}
      />
    </Fragment>
  );
};

export default ProductGridListSingle;
