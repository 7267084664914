import React, { useState, useEffect } from "react";
import { Modal } from "react-bootstrap";
import { Checkbox, Form, Radio } from "antd";
import AddressForm from "../../customer-address/address-form.component";
import CheckoutCart from "./checkout-cart";
import CheckoutSummary from "./checkout-summary";
import EmptyCheckout from "./empty-checkout";
import { useSelector, useDispatch } from "react-redux";
import cartSelector from "../../../redux/selectors/cart.selector";
import currencySelector from "../../../redux/selectors/currency.selector";
import customerSelector from "../../../redux/selectors/customer.selector";
import commonService from "../../../services/common-service";
import { Link } from "react-router-dom";
import Breadcrumb from "../../../wrappers/breadcrumb/Breadcrumb";
import checkoutProcessService from "./checkout-service";
import OrderActions from "../../../redux/actions/order.actions";
import { useHistory } from "react-router";
import utilActions from "../../../redux/actions/util.actions";
import utilSelector from "../../../redux/selectors/util.selector";
import CartActions from "../../../redux/actions/cart.actions";
import orderSelector from "../../../redux/selectors/order.selector";
import Coupon from "../cart-page/coupon/coupon";
import actionTypes from "../../../redux/action-types";

const CheckoutRoot = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const cartList = useSelector(cartSelector.cartList);
  const currency = useSelector(currencySelector.currency);
  const symbol = useSelector(currencySelector.symbol);
  const rate = useSelector(currencySelector.rate);
  const subTotal = useSelector(cartSelector.subTotal);
  const total = useSelector(cartSelector.total);
  const discount = useSelector(cartSelector.discount);
  const couponDiscount = useSelector(cartSelector.couponDiscount);
  const totalDiscount = useSelector(cartSelector.totalDiscount);
  const shippingCharge = useSelector(cartSelector.shippingCharge);
  const defaultTotal = useSelector(cartSelector.defaultTotal);

  const codLimit = useSelector(utilSelector.codLimit);
  const countryList = useSelector(utilSelector.countryList);
  const selectedSC = useSelector(utilSelector.selectedSC);

  const isCustomerLoggedIn = useSelector(customerSelector.isCustomerLoggedIn);
  const customerInfo = useSelector(customerSelector.customerInfo);
  const isCouponApplied = useSelector(cartSelector.isCouponApplied);
  const coupon = useSelector(cartSelector.coupon);

  const [form] = Form.useForm();

  const [isBillingSameAsShipping, updateIsBillingSameAsShipping] = useState(true);

  const [billingPhoneValue, updateBillingPhoneValue] = useState('');
  const [billingPhoneError, updateBillingPhoneError] = useState('');

  const [shippingPhoneValue, updateShippingPhoneValue] = useState('');
  const [shippingPhoneError, updateShippingPhoneError] = useState('');

  useEffect(() => {
    commonService.scrollToTop();
    dispatch(utilActions.updateRoutesAction("/checkout"));
    dispatch(utilActions.getSCCountriesAction());
    dispatch(CartActions.updateShippingChargeAction(0));
    dispatch({
      type : actionTypes.RESET_SC
    });
  }, []);

  const updatePhone=(type,value)=>{
    if(type==='shipping'){
      updateShippingPhoneValue(value)
      if(value.isValid){
        updateShippingPhoneError('')
      } else{
        updateShippingPhoneError('Enter a valid number')
      }
    } else{
      if(value.isValid){
        updateBillingPhoneValue(value)
        updateBillingPhoneError('')
      } else{
        updateBillingPhoneError('Enter a valid number')
      }
    }
    
  }

  const confirmOrder = (values) => {
    // validating order
    // If validation returns true proceed with order
    if(shippingPhoneValue.isValid && (isBillingSameAsShipping || !isBillingSameAsShipping && billingPhoneValue.isValid)){
      if (checkoutProcessService.validateOrder(values, selectedSC, codLimit, total)) {
        checkoutProcessService.confirmOrder(
          values,
          shippingPhoneValue,
          billingPhoneValue,
          cartList,
          subTotal,
          total,
          discount,
          totalDiscount,
          couponDiscount,
          shippingCharge,
          isBillingSameAsShipping,
          dispatch,
          OrderActions,
          history,
          isCustomerLoggedIn,
          customerInfo,
          currency,
          rate,
          symbol,
          isCouponApplied,
          coupon,
          selectedSC
        );
      } else {
        checkoutProcessService.codError();
      }
    } else{
      checkoutProcessService.phoneError(shippingPhoneValue,billingPhoneValue);
    }
  };

  const updateSelectedCountry = (e, type) => {
    if (type === "shipping") {
      form.setFieldsValue({
        'paymentType' : '',
       })
      const id = countryList.find(country=> country.country === e).id
      dispatch(utilActions.getSCByIdAction(id));
    }
  };

  const calculateAdditionalShipping = () => {
    let totalOrderAmount = defaultTotal;
    let additionalShippingAmount = 0;
    while (totalOrderAmount > selectedSC.baseAmountLimit) {
      additionalShippingAmount += selectedSC.additionalAmount;
      totalOrderAmount -= selectedSC.baseAmountLimit;
    }
    return additionalShippingAmount;
  };

  const getShippingCharge = () => {
    let charge = 0;
    if (selectedSC !== null && selectedSC.country !== "India") {
      charge = defaultTotal > selectedSC.baseAmountLimit ? selectedSC.baseAmount + calculateAdditionalShipping() : selectedSC.baseAmount;
    }
    dispatch(CartActions.updateShippingChargeAction(charge));
  };

  useEffect(() => {
    getShippingCharge();
  }, [selectedSC]);

  useEffect(() => {
    if (customerInfo !== null) {
      form.setFieldsValue({
        shipping_firstName: customerInfo.firstName,
        shipping_lastName: customerInfo.lastName,
        shipping_email: customerInfo.user.email,
        shipping_phone: customerInfo.user.mobile,
      });
      updateShippingPhoneValue({
        value : `${customerInfo.user.countryCode}${customerInfo.user.mobile}`,
        format2 : `${customerInfo.user.countryCode} ${customerInfo.user.mobile}`,
        isValid : true ,
        format1 : customerInfo.user.mobile,
      })
    } else {
      form.setFieldsValue({
        shipping_firstName: "",
        shipping_lastName: "",
        shipping_email: "",
        shipping_phone: "",
      });
    }
  }, [customerInfo]);

  const fillValues=()=>{
    form.setFieldsValue({
      shipping_firstName: 'test',
      shipping_lastName: 'test1',
      shipping_email: 'hem.r10@gmail.com',
      shipping_phone: '9999999999',
      
      shipping_city:'chennai',
      shipping_address: 'chennai',
      shipping_state: 'tn',
      shipping_country: 'India',
      shipping_pinCode: '600035'
    });
  }
  
  return (
    <>
      {cartList && cartList.length >= 1 ? (
        <Form
          onFinish={confirmOrder}
          initialValues={{
            isBillingSameAsShipping,
          }}
          layout="vertical"
          form={form}
        >
          <div className="row">
            <div className="col-lg-7">
              <AddressForm
                title="Shipping Address"
                type="shipping"
                validation
                countryList={countryList}
                updateSelectedCountry={updateSelectedCountry}
                fillValues={fillValues}
                history={window.location.hostname}
                phoneError={shippingPhoneError}
                updatePhoneValue={updatePhone}
                phoneValue={shippingPhoneValue.value}
              />
              <div>
                <Form.Item name="isBillingSameAsShipping" valuePropName="checked">
                  <Checkbox onChange={(e) => updateIsBillingSameAsShipping(e.target.checked)}>
                    {" "}
                    Is Billing Address same as Shipping Address ?{" "}
                  </Checkbox>
                </Form.Item>
              </div>
              {!isBillingSameAsShipping && (
                <div className={!isBillingSameAsShipping ? `show-form` : `no-form`}>
                  <AddressForm
                    title="Billing Address"
                    type="billing"
                    validation={!isBillingSameAsShipping}
                    countryList={countryList}
                    updateSelectedCountry={updateSelectedCountry}
                    history=''
                    phoneError={billingPhoneError}
                    updatePhoneValue={updatePhone}
                    phoneValue={billingPhoneValue.format2}
                  />
                </div>
              )}
            </div>

            <div className="col-lg-5">
              <Coupon cartList={cartList} rate={rate} currency={currency} symbol={symbol} />
              <div className="your-order-area">
                <h3>Your order</h3>
                <div className="your-order-wrap gray-bg-4">
                  <div className="your-order-product-info">
                    <div className="your-order-top">
                      <ul>
                        <li>Product</li>
                        <li>Total</li>
                      </ul>
                    </div>
                    <div className="your-order-middle">
                      <CheckoutCart cartItems={cartList} symbol={symbol} rate={rate} currency={currency} />
                    </div>
                    <div className="your-order-bottom">
                      <ul>
                        <li className="your-order-shipping">Shipping</li>
                        <li>{getShippingCharge(selectedSC)}</li>
                      </ul>
                    </div>
                    <div className="your-order-total">
                      <CheckoutSummary
                        symbol={symbol}
                        rate={rate}
                        currency={currency}
                        subTotal={subTotal}
                        total={total}
                        discount={discount}
                        couponDiscount={couponDiscount}
                        totalDiscount={totalDiscount}
                        shippingCharge={shippingCharge}
                      />
                    </div>
                  </div>
                  <div className="payment-method">
                    <Form.Item
                      name="paymentType"
                      label="Payment Mode"
                      rules={[
                        {
                          required: true,
                          message: "Please select payment method",
                        },
                      ]}
                    >
                      <Radio.Group>
                        {((selectedSC == null) || (selectedSC.country === "India")) && <Radio value="COD">Cash on Delivery</Radio>}

                        <Radio value="PAYMENT_GATEWAY">Credit Card / Debit Card / Net Banking / Other Wallets</Radio>
                      </Radio.Group>
                    </Form.Item>
                  </div>
                </div>
                <div className="place-order mt-25">
                  <button type="submit" className="btn-hover">
                    Place Order
                  </button>
                </div>
              </div>
            </div>
          </div>
        </Form>
      ) : (
        <div className="row">
          <div className="col-lg-12">
            <EmptyCheckout Link={Link} />
          </div>
        </div>
      )}
    </>
  );
};

export default CheckoutRoot;
