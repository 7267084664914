import React from "react";

const EmptyCheckout = ({Link}) => {
  return (
    <div className="item-empty-area text-center">
      <div className="item-empty-area__icon mb-30">
        <i className="pe-7s-cash"></i>
      </div>
      <div className="item-empty-area__text">
        No items found in cart to checkout <br /> <Link to={process.env.PUBLIC_URL + "/shop-grid-standard"}>Shop Now</Link>
      </div>
    </div>
  );
};

export default EmptyCheckout;
