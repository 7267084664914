import { applyMiddleware, compose, createStore } from 'redux';
import createSagaMiddleware from 'redux-saga';
import rootReducer from '../redux/rootReducer';

export const sagaMiddleware = createSagaMiddleware();

const store = createStore(
  rootReducer,
  compose(applyMiddleware(sagaMiddleware), window.devToolsExtension ? window.__REDUX_DEVTOOLS_EXTENSION__() : (f) => f),
);

export default store;