const cartSelector = {
  cartList: state => state.cartReducer.cartList,
  total: state => state.cartReducer.total,
  defaultTotal: state => state.cartReducer.defaultTotal,
  subTotal: state => state.cartReducer.subTotal,
  discount: state => state.cartReducer.discount,
  couponDiscount: state => state.cartReducer.couponDiscount,
  totalDiscount: state => state.cartReducer.totalDiscount,
  shippingCharge: state => state.cartReducer.shippingCharge,
  couponMessage:state=>state.cartReducer.couponMessage,
  isCouponApplied:state=>state.cartReducer.isCouponApplied,
  coupon:state=>state.cartReducer.coupon,
  wishList: state => state.cartReducer.wishList,
  compareList: state => state.cartReducer.compareList,
  isCartLoaded: state => state.cartReducer.isCartLoaded,
  isCartValidated: state => state.cartReducer.isCartValidated
}

export default cartSelector
