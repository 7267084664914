import axios from 'axios'
// import store from 'store'
// import history from '../utils/shared/History';

function callFetchAxios(
  endpoint,
  params,
  method,
  reqbody = {},
  data /* , cookieEnabled = false */,
) {
  const axiosInstance = axios.create({
    headers: {
      'Content-Type': 'application/json',
    },
  })

  const axiosInstanceWithCookieEnabled = axios.create({
    headers: {
      'Content-Type': 'application/json',
    },
    withCredentials: true,
  })

  const axiosInstanceForMultipartForm = axios.create({
    headers: {
      'Content-Type': 'multipart/form-data',
    },
  })
  const successResponseHandler = response => {
    // Any status code that lie within the range of 2xx cause this function to trigger
    // Do something with response data
    return response
  }

  const failureResponseHandler = error => {
    // Any status codes that falls outside the range of 2xx cause this function to trigger
    // Do something with response error
    if (error.response && error.response.status === 401) {
      if (error.response.data && error.response.data.message === 'Session Invalid') {
        console.info('Your session is invalid, redirecting to login page')
        // history.push('/login');
      }
    }
    return Promise.reject(error)
  }

  /* axiosInstance.interceptors.request.use(request => {
    const accessToken = store.get('accessToken')
    if (accessToken) {
      request.headers.Authorization = `Bearer ${accessToken}`
      request.headers.AccessToken = accessToken
    }
    return request
  })

  axiosInstanceForMultipartForm.interceptors.request.use(request => {
    const accessToken = store.get('accessToken')
    if (accessToken) {
      request.headers.Authorization = `Bearer ${accessToken}`
      request.headers.AccessToken = accessToken
    }
    return request
  }) */

  // Add a response interceptor
  axiosInstance.interceptors.response.use(successResponseHandler, failureResponseHandler)
  axiosInstanceWithCookieEnabled.interceptors.response.use(
    successResponseHandler,
    failureResponseHandler,
  )
  axiosInstanceForMultipartForm.interceptors.response.use(
    successResponseHandler,
    failureResponseHandler,
  )

  if (method === 'POST_MULTIPART') {
    // console.log(data)
  }

  switch (method) {
    case 'GET':
      /* if (cookieEnabled) {
        return ( axiosInstanceWithCookieEnabled
          .get(endpoint, {
            params,
          })
          .then((response) => response)
          .catch((error) => {
            throw error;
          })
        )
      } else {
        return axiosInstance
          .get(endpoint, {
            params,
          })
          .then((response) => response)
          .catch((error) => {
            throw error;
          });
      } */
      return axiosInstance
        .get(endpoint, {
          params,
        })
        .then(response => response)
        .catch(error => {
          throw error
        })
    case 'POST':
      return axiosInstance
        .post(endpoint, reqbody, { params })
        .then(response => response)
        .catch(error => {
          return error.response
        })
    case 'POST_MULTIPART':
      return axiosInstanceForMultipartForm
        .post(endpoint, data, {
          headers: {
            'Content-Type': 'multipart/form-data',
          },
        })
        .then(response => response)
        .catch(error => {
          throw error.response
        })
    case 'PUT':
      return axiosInstance
        .put(endpoint, reqbody, { params })
        .then(response => response)
        .catch(error => {
          console.log('error in azios ', error)
          throw error.response
        })
    case 'DELETE':
      return axiosInstance
        .delete(endpoint)
        .then(response => response)
        .catch(error => {
          throw error
        })
    case 'GET_MULTIPART':
      return axiosInstanceForMultipartForm
        .get(endpoint, {
          params,
        })
        .then(response => response)
        .catch(error => {
          throw error
        })
    default:
      return ''
  }
}

export const callFetchApi = (...params) => callFetchAxios(...params)

export default callFetchApi
