import { all, call, fork, put, takeLatest } from "redux-saga/effects";
import actionTypes from "../action-types";
import callFetchApi from "../../services/api";

export function* getBannersSaga(action) {
  try {
    const { url } = action.payload;
    const response = yield call(callFetchApi, url, {}, "GET");
    let payload = [];
    if (response.status === 200) {
      payload = response.data;
    }
    yield put({
      type: actionTypes.UPDATE_BANNERS,
      payload,
    });
  } catch (error) {
    console.log("here");
  }
}

export function* getCategoriesSaga(action) {
  try {
    const { url, data } = action.payload;
    const response = yield call(callFetchApi, url, data, "GET");
    let payload = [];
    if (response.status === 200) {
      payload = response.data;
    }
    yield put({
      type: actionTypes.UPDATE_CATEGORIES,
      payload,
    });
  } catch (error) {
    console.log("here");
  }
}

export function* getTopDealsSaga(action) {
  try {
    const { url, data } = action.payload;
    const response = yield call(callFetchApi, url, data, "GET");
    let payload = [];
    if (response.status === 200) {
      payload = response.data.productList;
    }
    yield put({
      type: actionTypes.UPDATE_TOP_DEALS,
      payload,
    });
  } catch (error) {
    console.log("here");
  }
}

export function* getSubCategoriesSaga(action) {
  try {
    const { url, data } = action.payload;
    const response = yield call(callFetchApi, url, data, "GET");
    let payload = [];
    if (response.status === 200) {
      payload = response.data;
    }
    yield put({
      type: actionTypes.UPDATE_SUB_CATEGORIES,
      payload,
    });
  } catch (error) {
    console.log("here");
  }
}

export function* getAttributesSaga(action) {
  try {
    const { url, data } = action.payload;
    const response = yield call(callFetchApi, url, data, "GET");
    let payload = [];
    if (response.status === 200) {
      payload = response.data;
    }
    yield put({
      type: actionTypes.UPDATE_ATTRIBUTES,
      payload,
    });
  } catch (error) {
    console.log("here");
  }
}

function* watchGetBannersSaga() {
  yield takeLatest(actionTypes.GET_BANNERS, getBannersSaga);
}

function* watchGetAttributessSaga() {
  yield takeLatest(actionTypes.GET_ATTRIBUTES, getAttributesSaga);
}

function* watchGetCategoriesSaga() {
  yield takeLatest(actionTypes.GET_CATEGORIES, getCategoriesSaga);
}

function* watchGetSubCategoriesSaga() {
  yield takeLatest(actionTypes.GET_SUB_CATEGORIES, getSubCategoriesSaga);
}

function* watchGetTopDealsSaga() {
  yield takeLatest(actionTypes.GET_TOP_DEALS, getTopDealsSaga);
}

export default function* homePageSagas() {
  yield all([
    fork(watchGetCategoriesSaga),
    fork(watchGetSubCategoriesSaga),
    fork(watchGetTopDealsSaga),
    fork(watchGetAttributessSaga),
    fork(watchGetBannersSaga),
  ]);
}
