import React from "react";
import Currency from "./currency/currency";

const LanguageCurrencyChanger = () => {
  
  return (
    <div className="language-currency-wrap">
      <Currency />
      <div className="same-language-currency">
        <p>Call Us +91 90949 50000</p>
      </div>
    </div>
  );
};

LanguageCurrencyChanger.propTypes = {
  
};

export default LanguageCurrencyChanger;
