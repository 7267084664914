import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import commonService from "../../services/common-service";
import ProductModal from "./ProductModal";

const ProductGridSingleHome = ({
  product,
  currency,
  symbol,
  rate,
  addToCart,
  addToWishList,
  addToCompareList,
  cartItem,
  wishlistItem,
  compareItem,
  sliderClassName,
  spaceBottomClass,
  buyNow,
  removeFromCart,
  removeFromWishList,
  removeFromCompareList,
}) => {
  const [modalShow, setModalShow] = useState(false);
  const [productImages, updateProductImages] = useState([]);

  useEffect(() => {
    const images = [];
    if (product.productImage.mainImageUrl !== undefined && product.productImage.mainImageUrl !== null && product.productImage.mainImageUrl !== "") {
      images.push(product.productImage.mainImageUrl);
    }
    if (
      product.productImage.otherImage1Url !== undefined &&
      product.productImage.otherImage1Url !== null &&
      product.productImage.otherImage1Url !== ""
    ) {
      images.push(product.productImage.otherImage1Url);
    }
    if (
      product.productImage.otherImage2Url !== undefined &&
      product.productImage.otherImage2Url !== null &&
      product.productImage.otherImage2Url !== ""
    ) {
      images.push(product.productImage.otherImage2Url);
    }
    if (
      product.productImage.otherImage3Url !== undefined &&
      product.productImage.otherImage3Url !== null &&
      product.productImage.otherImage3Url !== ""
    ) {
      images.push(product.productImage.otherImage3Url);
    }
    updateProductImages(images);
  }, []);
  return (
    <>
      <div className={`col-lg-3 ${sliderClassName ? sliderClassName : ""}`}>
        <div className={`product-wrap-2 pro-glaucous-color ${spaceBottomClass ? spaceBottomClass : ""}`}>
          <div className="product-img">
            <Link to={`${process.env.PUBLIC_URL}/product/${product.name}/${product.id}`}>
              <img className="default-img" src={product.productImage.mainImageUrl} alt="" />
              {product.productImage.otherImage1Url !== undefined &&
              product.productImage.otherImage1Url !== null &&
              product.productImage.otherImage1Url !== "" ? (
                <img className="hover-img" src={product.productImage.otherImage1Url} alt="" />
              ) : (
                <img className="hover-img" src={product.productImage.mainImageUrl} alt="" />
              )}
            </Link>
            {product.discountList !== undefined && product.discountList !== null && product.discountList.length ? (
              <div className="product-img-badges">
                {product.discountList.map((productDiscount) => {
                  return (
                    <span className="pink" key={productDiscount.percentageValue}>
                      {commonService.getDiscountTagValue(
                        productDiscount.name,
                        productDiscount.discountType === "CASH" ? productDiscount.cashValue : productDiscount.percentageValue,
                        productDiscount.discountType,
                        symbol,
                        rate,
                        currency
                      )}
                    </span>
                  );
                })}
              </div>
            ) : (
              ""
            )}

            <div className="product-action-2">
              {cartItem ? (
                <button onClick={() => removeFromCart(product)} className="active">
                  {" "}
                  <i className="fa fa-shopping-cart"></i>{" "}
                </button>
              ) : (
                <button onClick={() => addToCart(product)} title={cartItem !== undefined ? "Added to cart" : "Add to cart"}>
                  {" "}
                  <i className="fa fa-shopping-cart"></i>{" "}
                </button>
              )}

              <button onClick={() => setModalShow(true)} title="Quick View">
                <i className="fa fa-eye"></i>
              </button>

              <button className={compareItem ? "active" : ""} title={compareItem ? "Added to compare" : "Add to compare"}>
                {compareItem ? (
                  <i className="fa fa-retweet" onClick={() => removeFromCompareList(product)} />
                ) : (
                  <i className="fa fa-retweet" onClick={() => addToCompareList(product)} />
                )}
              </button>
            </div>
          </div>
          <div className="product-content-2">
            <div className="title-price-wrap-2">
              <h3>
                <Link to={`${process.env.PUBLIC_URL}/product/${product.name}/${product.id}`}>{product.name}</Link>
              </h3>
              <div className="price-2">
                {product.discountList !== undefined && product.discountList !== null && product.discountList.length ? (
                  <>
                    <span>
                      {symbol}{` `}{` `}
                      {commonService.getConvertedPrice(product.specialPrice, rate, currency)}
                    </span>{" "}
                    <span className="old">
                      {symbol}{` `}
                      {commonService.getConvertedPrice(product.price, rate, currency)}
                    </span>
                  </>
                ) : (
                  <span>
                    {symbol}{` `}
                    {commonService.getConvertedPrice(product.price, rate, currency)}
                  </span>
                )}
              </div>
            </div>
            <div className="pro-wishlist-2">
              <button
                className={wishlistItem ? "active" : ""}
                // disabled={wishlistItem !== undefined}
                title={wishlistItem ? "Added to wishlist" : "Add to wishlist"}
              >
                {wishlistItem ? (
                  <i className="fa fa-heart" onClick={() => removeFromWishList(product)} />
                ) : (
                  <i className="fa fa-heart-o" onClick={() => addToWishList(product)} />
                )}
              </button>
            </div>
          </div>
        </div>
      </div>

      {/* product modal */}
      {modalShow && (
        <ProductModal
          show={modalShow}
          onHide={() => setModalShow(false)}
          product={product}
          currency={currency}
          symbol={symbol}
          rate={rate}
          cartItem={cartItem}
          wishlistItem={wishlistItem}
          compareItem={compareItem}
          addToCart={addToCart}
          addToWishList={addToWishList}
          addToCompareList={addToCompareList}
          productImages={productImages}
          buyNow={buyNow}
          removeFromCart={removeFromCart}
          removeFromWishList={removeFromWishList}
          removeFromCompareList={removeFromCompareList}
        />
      )}
    </>
  );
};

export default ProductGridSingleHome;
