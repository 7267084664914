import PropTypes from "prop-types";
import React from "react";
import { setActiveLayout } from "../../../helpers/product";

const ShopTopAction = ({
  getLayout,
  getFilterSortParams,
  productCount,
  filteredProductCount,
  toggleFilter,
  mobile
}) => {
  return (
    <div className="shop-top-bar mb-35">
      <div className="select-shoing-wrap">
        <div className="shop-select">
          <div className='mobile-filter' onClick={() => toggleFilter()}><i className="fa fa-filter" style={{ fontSize: 24 }}></i></div>
          <select
            onChange={e => getFilterSortParams("filterSort", e.target.value)}
          >
            <option value="default">Default</option>
            <option value="priceHighToLow">Price - High to Low</option>
            <option value="priceLowToHigh">Price - Low to High</option>
          </select>
        </div>
        <p>
          {productCount} products found
        </p>
      </div>

      <div className="shop-tab">
        {
          !mobile &&
          <>
            <button
              onClick={e => {
                getLayout("grid two-column");
                setActiveLayout(e);
              }}
            >
              <i className="fa fa-th-large" />
            </button>
            <button
              onClick={e => {
                getLayout("grid three-column");
                setActiveLayout(e);
              }}
            >
              <i className="fa fa-th" />
            </button>
            <button
              onClick={e => {
                getLayout("list");
                setActiveLayout(e);
              }}
            >
              <i className="fa fa-list-ul" />
            </button>
          </>
        }

      </div>
    </div>
  );
};

ShopTopAction.propTypes = {
  getFilterSortParams: PropTypes.func,
  getLayout: PropTypes.func,
  productCount: PropTypes.number,
  sortedProductCount: PropTypes.number
};

export default ShopTopAction;
