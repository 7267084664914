const customerSelector = {
  isCustomerLoggedIn: state => state.customerReducer.isCustomerLoggedIn,
  isGuestFlow: state => state.customerReducer.isGuestFlow,
  customerInfo: state => state.customerReducer.customerInfo,
  isCustomerBeingRegistered : state => state.customerReducer.isCustomerBeingRegistered,
  isCustomerBeingLoggedIn : state => state.customerReducer.isCustomerBeingLoggedIn,
  customerOrders: state => state.customerReducer.customerOrders,
  isCustomerOrdersBeingFetched : state => state.customerReducer.isCustomerOrdersBeingFetched,
  isPasswordBeingUpdated : state => state.customerReducer.isPasswordBeingUpdated
}

export default customerSelector
