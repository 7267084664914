import orderActions from "../action-types";

const initialState = {
  order: null,
  orderBeingFetched: false,
  orderLoading: false,

  trackingInfo: null,
  trackingInfoLoading: false,
};

export default function orderReducer(state = initialState, action) {
  const newState = Object.assign({}, state);
  switch (action.type) {
    case orderActions.UPDATE_ORDER:
      newState.order = action.payload;
      return newState;

    case orderActions.IS_FETCHING_ORDER:
      newState.orderBeingFetched = action.payload;
      return newState;

    case orderActions.UPDATE_ORDER_LOADING:
      newState.orderLoading = action.payload;
      return newState;

    case orderActions.UPDATE_TRACKING_INFO:
      const trackingInfo = action?.payload;
      newState.trackingInfo = trackingInfo ? trackingInfo?.[0] : null;
      return newState;

    case orderActions.IS_TRACKING_INFO_BEING_FETCHED:
      newState.trackingInfoLoading = action.payload;
      return newState;
    default:
      return state;
  }
}
