import React from "react";
import { Link } from "react-router-dom";
import { useDispatch } from "react-redux";
import CustomerActions from "../../../redux/actions/customer.actions";
import { Checkbox } from "antd";

const UserRoot = ({ isGuestFlow }) => {
  const dispatch = useDispatch();

  const updateGuestFlow = (checked) => {
    dispatch(CustomerActions.updateGuestFlowAction(checked));
  };

  return (
    <div className="user-container">
      <div className="row">
        <div className="col-lg-6">
          <Checkbox checked={isGuestFlow} onChange={(e) => updateGuestFlow(e.target.checked)}>
            {" "}
            Checkout as Guest{" "}
          </Checkbox>
        </div>
        {!isGuestFlow && (
          <div className="col-lg-6">
            <button>
              <Link to={process.env.PUBLIC_URL + "/login-signup"}>Login / Register</Link>
            </button>
          </div>
        )}
      </div>
    </div>
  );
};

export default UserRoot;
