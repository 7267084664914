import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import MenuCart from "./header-bottom/sub-components/MenuCart";
import { useDispatch, useSelector } from "react-redux";
import cartSelector from "../../redux/selectors/cart.selector";
import customerSelector from "../../redux/selectors/customer.selector";
import { Dropdown } from "antd";
import CustomerActions from "../../redux/actions/customer.actions";
import commonService from "../../services/common-service";
import { isMobile } from "react-device-detect";

const IconGroup = ({ iconWhiteClass }) => {
  useEffect(() => {
    if(isMobile){
      window.addEventListener("click", (e) => {
        listenOnTouch(e);
      });
    }
  }, []);

  const dispatch = useDispatch();
  const cartList = useSelector(cartSelector.cartList);
  const wishList = useSelector(cartSelector.wishList);
  const compareList = useSelector(cartSelector.compareList);
  const isCustomerLoggedIn = useSelector(customerSelector.isCustomerLoggedIn);

  const [showMobile, updateShowMobile] = useState(false);
  /* const handleClick = (e) => {
    e.currentTarget.nextSibling.classList.toggle("active");
  }; */

  const listenOnTouch = (e) => {
    const classlist = e.target.classList.value;
    if (classlist.includes("mobile-cart-menu") && classlist.includes("default-btn")) {
      updateShowMobile(false);
    } else {
      if (!classlist.includes("pe-7s-shopbag")) {
        updateShowMobile(false);
      }
    }
  };

  const triggerMobileMenu = () => {
    const offcanvasMobileMenu = document.querySelector("#offcanvas-mobile-menu");
    offcanvasMobileMenu.classList.add("active");
  };

  const cartMenu = () => {
    if (showMobile) {
      updateShowMobile(false);
    } else {
      updateShowMobile(true);
    }
  };

  const logout = () => {
    dispatch(CustomerActions.logoutAction());
  };

  return (
    <div className={`header-right-wrap ${iconWhiteClass ? iconWhiteClass : ""}`}>
      {/* <div className="same-style header-search d-none d-lg-block">
        <button className="search-active" onClick={(e) => handleClick(e)}>
          <i className="pe-7s-search" />
        </button>
        <div className="search-content">
          <form action="#">
            <input type="text" placeholder="Search" />
            <button className="button-search">
              <i className="pe-7s-search" />
            </button>
          </form>
        </div>
      </div> */}
      {/* <Dropdown
        overlay={
          <div className="same-style header-search d-none d-lg-block">
            <div className="search-content">
              <form action="#">
                <input type="text" placeholder="Search" />
                <button className="button-search">
                  <i className="pe-7s-search" />
                </button>
              </form>
            </div>
          </div>
        }
      >
        <div className="same-style header-search d-none d-lg-block">
          <button className="search-active">
            <i className="pe-7s-search" />
          </button>
        </div>
      </Dropdown> */}
      <Dropdown
        overlay={
          <div className="same-style account-setting d-lg-block">
            <div className="account-dropdown">
              <ul>
                {isCustomerLoggedIn ? (
                  <>
                    <li>
                      <Link to={process.env.PUBLIC_URL + "/myAccount"}>My account</Link>
                    </li>
                    <li>
                      <a aria-hidden={true} onClick={() => logout()}>
                        Logout
                      </a>
                    </li>
                  </>
                ) : (
                  <>
                    <li>
                      <Link to={process.env.PUBLIC_URL + "/login-signup/#login"}>Login</Link>
                    </li>
                    <li>
                      <Link to={process.env.PUBLIC_URL + "/login-signup/#register"}>Register</Link>
                    </li>
                  </>
                )}
              </ul>
            </div>
          </div>
        }
      >
        <div className="same-style account-setting d-lg-block">
          <button className="account-setting-active">
            <i className="pe-7s-user-female" />
          </button>
        </div>
      </Dropdown>
      <div className="same-style header-compare">
        <Link to={process.env.PUBLIC_URL + "/compare"}>
          <i className="pe-7s-shuffle" />
          <span className="count-style">{compareList && compareList.length ? compareList.length : 0}</span>
        </Link>
      </div>
      <div className="same-style header-wishlist">
        <Link to={process.env.PUBLIC_URL + "/wishlist"}>
          <i className="pe-7s-like" />
          <span className="count-style">{wishList && wishList.length ? wishList.length : 0}</span>
        </Link>
      </div>

      {isMobile ? (
        <>
          <div className="same-style cart-wrap d-lg-block" onClick={() => cartMenu()}>
            <button className="icon-cart">
              <i className="pe-7s-shopbag" />
              <span className="count-style">{cartList && cartList.length ? cartList.length : 0}</span>
            </button>
          </div>
          {showMobile && (
            <div className="mobile-cart-menu">
              <MenuCart />
            </div>
          )}
        </>
      ) : (
        <Dropdown
          overlay={
            <div className="same-style cart-wrap d-lg-block">
              <MenuCart />
            </div>
          }
        >
          <div className="same-style cart-wrap d-lg-block">
            <button className="icon-cart">
              <i className="pe-7s-shopbag" />
              <span className="count-style">{cartList && cartList.length ? cartList.length : 0}</span>
            </button>
          </div>
        </Dropdown>
      )}
      {/* <div className="same-style cart-wrap d-block d-lg-none">
        <Link className="icon-cart" to={process.env.PUBLIC_URL + "/cart"}>
          <i className="pe-7s-shopbag" />
          <span className="count-style">{cartList && cartList.length ? cartList.length : 0}</span>
        </Link>
      </div> */}

      <div className="same-style mobile-off-canvas d-block d-lg-none">
        <button className="mobile-aside-button" onClick={() => triggerMobileMenu()}>
          <i className="pe-7s-menu" />
        </button>
      </div>
    </div>
  );
};

export default IconGroup;
