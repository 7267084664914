import { VALIDATE_CART_PRODUCTS_URL,APPLY_COUPON_URL } from '../../services/api-service'
import actionTypes from '../action-types'

const addProductToCartAction = (data) => {
  return {
    type: actionTypes.ADD_PRODUCT_TO_CART,
    payload: {
      data
    },
  }
}

const removeProductFromCartAction = (data,cartList) => {
  return {
    type: actionTypes.REMOVE_PRODUCT_FROM_CART,
    payload: {
      data,
      cartList
    },
  }
}

const updateCartFromCacheAction = () => {
  return {
    type: actionTypes.UPDATE_CART_FROM_CACHE,
  }
}

const validateCartProductsAction = (data,history) => {
  return {
    type: actionTypes.VALIDATE_CART_PRODUCTS,
    payload: {
      url : VALIDATE_CART_PRODUCTS_URL,
      data,
      history
    },
  }
}

const removeAllProductsFromCartAction= () =>{
  return {
    type: actionTypes.REMOVE_ALL_PRODUCTS_FROM_CART,
  }
}

const applyCouponAction = (data) => {
  return {
    type: actionTypes.APPLY_COUPON,
    payload: {
      url : APPLY_COUPON_URL,
      data
    },
  }
}

const removeCouponAction = (data) => {
  return {
    type: actionTypes.REMOVE_COUPON,
  }
}

const addProductToWishListAction = (data) => {
  return {
    type: actionTypes.ADD_PRODUCT_TO_WISHLIST,
    payload: {
      data
    },
  }
}

const removeProductFromWishListAction = (data) => {
  return {
    type: actionTypes.REMOVE_PRODUCT_FROM_WISHLIST,
    payload: {
      data
    },
  }
}

const updateWishListFromCacheAction = () => {
  return {
    type: actionTypes.UPDATE_WISHLIST_FROM_CACHE,
  }
}

const removeAllProductsFromWishListAction= () =>{
  return {
    type: actionTypes.REMOVE_ALL_PRODUCTS_FROM_WISHLIST,
  }
}

const addProductToCompareListAction = (data) => {
  return {
    type: actionTypes.ADD_PRODUCT_TO_COMPARE_LIST,
    payload: {
      data
    },
  }
}

const removeProductFromCompareListAction = (data) => {
  return {
    type: actionTypes.REMOVE_PRODUCT_FROM_COMPARE_LIST,
    payload: {
      data
    },
  }
}

const updateCompareListFromCacheAction = () => {
  return {
    type: actionTypes.UPDATE_COMPARE_LIST_FROM_CACHE,
  }
}

const removeAllProductsFromCompareListAction= () =>{
  return {
    type: actionTypes.REMOVE_ALL_PRODUCTS_FROM_COMPARE_LIST,
  }
}

const resetCartAction= () =>{
  return {
    type: actionTypes.RESET_CART,
  }
}

const updateShippingChargeAction = charge =>{
  return {
    type: actionTypes.UPDATE_SHIPPING_CHARGE,
    payload : charge
  }
}

const buyNowAction = (history,product) =>{
  return {
    type: actionTypes.BUY_NOW,
    payload : {
      history,
      product
    }
  }
}

const CartActions = {
  addProductToCartAction,
  removeProductFromCartAction,
  updateCartFromCacheAction,
  validateCartProductsAction,
  applyCouponAction,
  addProductToWishListAction,
  removeProductFromWishListAction,
  updateWishListFromCacheAction,
  removeAllProductsFromWishListAction,
  removeAllProductsFromCompareListAction,
  updateCompareListFromCacheAction,
  removeProductFromCompareListAction,
  addProductToCompareListAction,
  resetCartAction,
  updateShippingChargeAction,
  removeAllProductsFromCartAction,
  removeCouponAction,
  buyNowAction
}

export default CartActions
