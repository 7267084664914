import productActions from '../action-types'

const initialState = {
  productList: [],
  isProductsBeingFetched: false,
  count: 20,
  page: 1,
  productDetail: null,
  filteredProductList:[],
  selectedFilters : [],
  similarProductList: [],
  paginationData: null
}

export default function productReducer(state = initialState, action) {
  const newState = Object.assign({}, state)
  switch (action.type) {
    case productActions.UPDATE_PRODUCTS: {
      const { list = [], paginationData } = action.payload
      newState.productList = [...list]
      newState.filteredProductList = [...list]
      newState.paginationData = paginationData
      return newState
    }

    case productActions.UPDATE_SIMILAR_PRODUCTS: {
      newState.similarProductList = [...action.payload]
      return newState
    }

    case productActions.UPDATE_PRODUCT_DETAIL:
      newState.productDetail = { ...action.payload }
      return newState

    case productActions.UPDATE_FILTERED_PRODUCTS: {
      const {filteredProductList,selectedFilters} = action.payload
      newState.filteredProductList = [...filteredProductList]
      newState.selectedFilters = selectedFilters
      return newState
    }

    case productActions.UPDATE_SELECTED_FILTERS: {
      newState.selectedFilters = action.payload
      return newState
    }

    case productActions.IS_PRODUCTS_BEING_FETCHED: {
      newState.isProductsBeingFetched = action.payload
      return newState
    }
    
    
    default:
      return state
  }
}
