import { GET_CURRENCYS_URL } from '../../services/api-service'
import actionTypes from '../action-types'

const getCurrencyAction = () => {
  return {
    type: actionTypes.GET_CURRENCY,
    payload: {
      url: GET_CURRENCYS_URL
    },
  }
}

const updateSelectedCurrencyAction = (data) => {
  return {
    type: actionTypes.UPDATED_SELECTED_CURRENCY,
    payload: data,
  }
}

const currencyActions = {
  getCurrencyAction,
  updateSelectedCurrencyAction
}

export default currencyActions
