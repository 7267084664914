import PropTypes from "prop-types";
import React, { Fragment } from "react";
import ShopTopAction from "./ShopTopAction";

const ShopTopbar = ({
  getLayout,
  getFilterSortParams,
  productCount,
  filteredProductCount,
  toggleFilter,
  mobile
}) => {
  return (
    <Fragment>
      {/* shop top action */}
      <ShopTopAction
        getLayout={getLayout}
        getFilterSortParams={getFilterSortParams}
        productCount={productCount}
        filteredProductCount={filteredProductCount}
        toggleFilter={toggleFilter}
        mobile={mobile}
      />
    </Fragment>
  );
};

ShopTopbar.propTypes = {
  getFilterSortParams: PropTypes.func,
  getLayout: PropTypes.func,
  productCount: PropTypes.number,
  filteredProductCount: PropTypes.number
};

export default ShopTopbar;
