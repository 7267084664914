import React, { useState, useEffect } from "react";
import Swiper from "react-id-swiper";
import { Modal } from "react-bootstrap";
// import Rating from "./sub-components/ProductRating";
import commonService from "../../services/common-service";
import ProductZoom from "../product-zoom/product-zoom";

const ProductModal = (props) => {
  const {
    product,
    currency,
    symbol,
    rate,
    productImages,
    addToCart,
    addToWishList,
    addToCompareList,
    wishlistItem,
    compareItem,
    cartItem,
    buyNow,
    removeFromCart,
    removeFromWishList,
    removeFromCompareList,
  } = props;
  const [gallerySwiper, getGallerySwiper] = useState(null);
  const [thumbnailSwiper, getThumbnailSwiper] = useState(null);

  useEffect(() => {
    if (gallerySwiper !== null && gallerySwiper.controller && thumbnailSwiper !== null && thumbnailSwiper.controller) {
      gallerySwiper.controller.control = thumbnailSwiper;
      thumbnailSwiper.controller.control = gallerySwiper;
    }
  }, [gallerySwiper, thumbnailSwiper]);

  const gallerySwiperParams = {
    getSwiper: getGallerySwiper,
    spaceBetween: 10,
    loopedSlides: productImages.length,
    loop: true,
  };

  const thumbnailSwiperParams = {
    getSwiper: getThumbnailSwiper,
    spaceBetween: 50,
    slidesPerView: productImages.length,
    touchRatio: 0.2,
    slideToClickedSlide: true,
    loop: true,
    navigation: {
      nextEl: ".swiper-button-next",
      prevEl: ".swiper-button-prev",
    },
    renderPrevButton: () => (
      <button className="swiper-button-prev ht-swiper-button-nav">
        <i className="pe-7s-angle-left" />
      </button>
    ),
    renderNextButton: () => (
      <button className="swiper-button-next ht-swiper-button-nav">
        <i className="pe-7s-angle-right" />
      </button>
    ),
  };

  return (
    <>
      <Modal show={props.show} onHide={props.onHide} className="product-quickview-modal-wrapper">
        <Modal.Header closeButton></Modal.Header>

        <div className="modal-body">
          <div className="row">
            <div className="col-md-6 col-sm-12 col-xs-12">
              <div className="product-large-image-wrapper">
                {/* <Swiper {...gallerySwiperParams}>
                  {productImages.length > -1 &&
                    productImages.map((image, key) => {
                      return (
                        <div key={key}>
                          <div className="single-image">
                            <img src={image} className="img-fluid" alt="" />
                          </div>
                        </div>
                      );
                    })}
                </Swiper> */}
              </div>
              <div className="product-small-image-wrapper mt-15">
                {/* <Swiper {...thumbnailSwiperParams}>
                  {productImages.length > -1 &&
                    productImages.map((image, key) => {
                      return (
                        <div key={key}>
                          <div className="single-image">
                            <img src={image} className="img-fluid" alt="" />
                          </div>
                        </div>
                      );
                    })}
                </Swiper> */}
                <ProductZoom src={productImages[0]} />
              </div>
            </div>
            <div className="col-md-6 col-sm-12 col-xs-12">
              <div className="product-details-content quickview-content">
                <h2>{product.name}</h2>
                <h5>sku : {product.sku}</h5>
                <div className="product-details-price">
                  {product.discountList !== undefined && product.discountList !== null && product.discountList.length ? (
                    <>
                      <span>
                        {symbol}{` `}
                        {commonService.getConvertedPrice(product.specialPrice, rate, currency)}
                      </span>{" "}
                      <span className="old">
                        {symbol}{` `}
                        {commonService.getConvertedPrice(product.price, rate, currency)}
                      </span>
                    </>
                  ) : (
                    <span>
                      {symbol}{` `}
                      {commonService.getConvertedPrice(product.price, rate, currency)}
                    </span>
                  )}
                </div>
                {/* {product.rating && product.rating > 0 ? (
                  <div className="pro-details-rating-wrap">
                    <div className="pro-details-rating">
                      <Rating ratingValue={product.rating} />
                    </div>
                  </div>
                ) : (
                  ""
                )} */}
                <div className="pro-details-list">
                  <p>{product.shortDescription}</p>
                </div>

                {/* {product.variation ? (
                  <div className="pro-details-size-color">
                    <div className="pro-details-color-wrap">
                      <span>Color</span>
                      <div className="pro-details-color-content">
                        {product.variation.map((single, key) => {
                          return (
                            <label
                              className={`pro-details-color-content--single ${single.color}`}
                              key={key}
                            >
                              <input
                                type="radio"
                                value={single.color}
                                name="product-color"
                                checked={
                                  single.color === selectedProductColor
                                    ? "checked"
                                    : ""
                                }
                                onChange={() => {
                                  setSelectedProductColor(single.color);
                                  setSelectedProductSize(single.size[0].name);
                                  setProductStock(single.size[0].stock);
                                  setQuantityCount(1);
                                }}
                              />
                              <span className="checkmark"></span>
                            </label>
                          );
                        })}
                      </div>
                    </div>
                    <div className="pro-details-size">
                      <span>Size</span>
                      <div className="pro-details-size-content">
                        {product.variation &&
                          product.variation.map(single => {
                            return single.color === selectedProductColor
                              ? single.size.map((singleSize, key) => {
                                  return (
                                    <label
                                      className={`pro-details-size-content--single`}
                                      key={key}
                                    >
                                      <input
                                        type="radio"
                                        value={singleSize.name}
                                        checked={
                                          singleSize.name ===
                                          selectedProductSize
                                            ? "checked"
                                            : ""
                                        }
                                        onChange={() => {
                                          setSelectedProductSize(
                                            singleSize.name
                                          );
                                          setProductStock(singleSize.stock);
                                          setQuantityCount(1);
                                        }}
                                      />
                                      <span className="size-name">
                                        {singleSize.name}
                                      </span>
                                    </label>
                                  );
                                })
                              : "";
                          })}
                      </div>
                    </div>
                  </div>
                ) : (
                  ""
                )} */}
                <div className="modal-btns" style={{ display: "flex" }}>
                  <div className="pro-details-quality">
                    {cartItem ? (
                      <div className="pro-details-cart btn-hover">
                        <button onClick={() => removeFromCart(product)}> Remove from Cart </button>
                      </div>
                    ) : (
                      <div className="pro-details-cart btn-hover">
                        <button onClick={() => addToCart(product)}> Add To Cart </button>
                      </div>
                    )}

                    <div className="pro-details-cart btn-hover">
                      <button onClick={() => buyNow(product)}> Buy Now </button>
                    </div>

                    <div className="pro-details-wishlist">
                      <button
                        className={wishlistItem ? "active" : ""}
                        title={wishlistItem ? "Added to wishlist" : "Add to wishlist"}
                        onClick={() => (wishlistItem ? removeFromWishList(product) : addToWishList(product))}
                      >
                        {wishlistItem ? <i className="fa fa-heart" /> : <i className="fa fa-heart-o" />}
                      </button>
                    </div>
                    <div className="pro-details-compare">
                      <button className={compareItem ? "active" : ""} title={compareItem ? "Added to compare" : "Add to compare"}>
                        {compareItem ? (
                          <i className="fa fa-retweet" onClick={() => removeFromCompareList(product)} />
                        ) : (
                          <i className="fa fa-retweet" onClick={() => addToCompareList(product)} />
                        )}
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Modal>
    </>
  );
};

export default ProductModal;
