import { API_URL } from "../shared/config"

const API_BASE_URL =  API_URL
export const LOGIN_URL = `${API_BASE_URL}/api/auth/signin`


// Home Page Urls
export const GET_BANNERS_URL = `${API_BASE_URL}/api/banner/`
export const GET_CATEGORIES_URL = `${API_BASE_URL}/api/category/list`
export const GET_SUB_CATEGORIES_URL = `${API_BASE_URL}/api/subCategory/list`
export const GET_TOP_DEALS_URL = `${API_BASE_URL}/api/hotDeals/main`
export const GET_ATTRIBUTES_URL = `${API_BASE_URL}/api/productAttribute/list`

// Product Url
export const GET_PRODUCTS_URL = `${API_BASE_URL}/api/product/list/products`
export const GET_PRODUCTS_BY_CAT_AND_SUBCAT_URL = `${API_BASE_URL}/api/product/listByCategoryAndSubCategoryAliasName`
export const GET_PRODUCTS_BY_CAT_OR_SUBCAT_URL = `${API_BASE_URL}/api/product/listByCategoryOrSubCategoryAliasName`
export const GET_PRODUCT_BY_ID = `${API_BASE_URL}/api/product`
export const GET_SIMILAR_PRODUCT_BY_ID = `${API_BASE_URL}/api/product/similar-product`


// Customers Url
export const GET_CUSTOMERS_URL = `${API_BASE_URL}/api/customer/list`
export const CREATE_CUSTOMER_URL = `${API_BASE_URL}/api/customer/create`
export const UPDATE_CUSTOMER_URL = `${API_BASE_URL}/api/customer/update`
export const GET_CUSTOMER_BY_ID = `${API_BASE_URL}/api/customer/get`
export const REFRESH_CUSTOMER_URL = `${API_BASE_URL}/api/customer/getInfoByToken`
export const GET_CUSTOMER_ORDERS_URL = `${API_BASE_URL}/api/order/getOrdersByCustomer`
export const RESET_PASSWORD_URL = `${API_BASE_URL}/api/auth/resetPassword`

// cart urls

export const VALIDATE_CART_PRODUCTS_URL = `${API_BASE_URL}/api/cart/list`
export const APPLY_COUPON_URL = `${API_BASE_URL}/api/coupon/applyCoupon`

// order urls

export const PLACE_ORDER_URL = `${API_BASE_URL}/api/order/create`
export const GET_ORDER_BY_ID_URL = `${API_BASE_URL}/api/order/getOrderInfo`

// Currency Urls
export const GET_CURRENCYS_URL = `${API_BASE_URL}/api/currency/activeList`

export const GENERATE_HASH_URL = `${API_BASE_URL}/api/payment/generateHash`


// payment urls

export const PAYMENT_SUCCESS_URL = `${API_BASE_URL}/api/order/postOrderProcess`
export const PAYMENT_FAILURE_URL = `${API_BASE_URL}/api/order/postOrderProcess`
export const PAYMENT_C_URL = `${API_BASE_URL}/api/order/postOrderProcess`

export const GET_SETTINGS_URL = `${API_BASE_URL}/api/siteSettings/main`
export const GET_SC_COUNTRIES_URL = `${API_BASE_URL}/api/shippingCharge/countryList`
export const GET_SC_BY_ID_URL = `${API_BASE_URL}/api/shippingCharge`

// Order tracking Urls
export const GET_TRACKING_INFO_URL = `${API_BASE_URL}/api/shipping`