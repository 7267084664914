import { all, call, fork, put, takeLatest } from 'redux-saga/effects'
import actionTypes from '../action-types'
import callFetchApi from '../../services/api'

export function* getSettingsSaga(action) {
  try {
    const { url } = action.payload
    const response = yield call(callFetchApi, url, {}, 'GET')
    if (response.status === 200) {
      yield put({
        type: actionTypes.UPDATE_SETTINGS,
        payload: response.data,
      })
    }
  } catch (error) {
    console.log('here')
  }
}

export function* getSCCountriesSaga(action) {
  try {
    const { url } = action.payload
    const response = yield call(callFetchApi, url, {}, 'GET')
    if (response.status === 200) {
      yield put({
        type: actionTypes.UPDATE_SC_COUNTRIES,
        payload: response.data,
      })
    }
  } catch (error) {
    console.log('here')
  }
}

export function* getSCByIdSaga(action) {
  try {
    const { url,id } = action.payload
    const response = yield call(callFetchApi, `${url}/${id}`, {}, 'GET')
    if (response.status === 200) {
      yield put({
        type: actionTypes.UPDATE_SC,
        payload: response.data,
      })
    }
  } catch (error) {
    console.log('here')
  }
}

function* watchGetSCByIdSaga() {
  yield takeLatest(actionTypes.GET_SC_BY_ID, getSCByIdSaga)
}

function* watchGetSettingsSaga() {
  yield takeLatest(actionTypes.GET_SETTINGS, getSettingsSaga)
}

function* watchGetSCCountriesSaga() {
  yield takeLatest(actionTypes.GET_SC_COUNTRIES, getSCCountriesSaga)
}

export default function* utilSaga() {
  yield all([
    fork(watchGetSettingsSaga),
    fork(watchGetSCCountriesSaga),
    fork(watchGetSCByIdSaga)
  ])
}
