import PropTypes from "prop-types";
import React, { Fragment } from "react";
import { Link } from "react-router-dom";
import { getDiscountPrice } from "../../../../helpers/product";
// import { useToasts } from "react-toast-notifications";
import { useSelector, useDispatch } from "react-redux";
import cartSelector from "../../../../redux/selectors/cart.selector";
import currencySelector from "../../../../redux/selectors/currency.selector";
import commonService from "../../../../services/common-service";
import CartActions from "../../../../redux/actions/cart.actions";

const MenuCart = () => {
  const dispatch = useDispatch();
  const cartList = useSelector(cartSelector.cartList);
  const currency = useSelector(currencySelector.currency);
  const symbol = useSelector(currencySelector.symbol);
  const rate = useSelector(currencySelector.rate);
  const subTotal = useSelector(cartSelector.subTotal);
  const total = useSelector(cartSelector.total);
  const couponDiscount = useSelector(cartSelector.couponDiscount);
  const totalDiscount = useSelector(cartSelector.totalDiscount);
  const discount = useSelector(cartSelector.discount);

  //const { addToast } = useToasts();
  return (
    <div className="shopping-cart-content">
      {cartList && cartList.length > 0 ? (
        <Fragment>
          <ul>
            {cartList.map((product, key) => {
              return (
                <li className="single-shopping-cart" key={key}>
                  <div className="shopping-cart-img">
                    <Link to={`${process.env.PUBLIC_URL}/product/${product.name}/${product.id}`}>
                      <img alt="" src={product.productImage.mainImageUrl} className="img-fluid" />
                    </Link>
                  </div>
                  <div className="shopping-cart-title">
                    <h4>
                      <Link to={`${process.env.PUBLIC_URL}/product/${product.name}/${product.id}`}> {product.name} </Link>
                    </h4>
                    {/* <h6>Qty: {product.quantity}</h6> */}
                    <span>
                      {product.discountList !== undefined && product.discountList !== null && product.discountList.length
                        ? `${symbol} ${commonService.getConvertedPrice(product.specialPrice, rate, currency)}`
                        : `${symbol} ${commonService.getConvertedPrice(product.price, rate, currency)}`}
                    </span>
                    {/*  {product.selectedProductColor &&
                    product.selectedProductSize ? (
                      <div className="cart-item-variation">
                        <span>Color: {product.selectedProductColor}</span>
                        <span>Size: {product.selectedProductSize}</span>
                      </div>
                    ) : (
                      ""
                    )} */}
                  </div>
                  <div className="shopping-cart-delete">
                    <button onClick={() => commonService.removeFromCart(dispatch, CartActions, cartList, product, cartList)}>
                      <i className="fa fa-times-circle" />
                    </button>
                  </div>
                </li>
              );
            })}
          </ul>
          <div className="shopping-cart-total">
            <h4>
              Sub Total : <span className="shop-total">{`${symbol} ${commonService.getConvertedPrice(subTotal, rate, currency)}`}</span>
            </h4>
            {discount !== null && discount > 0 && (
              <h4>
                Discount : <span className="shop-total">{`${symbol} ${commonService.getConvertedPrice(discount, rate, currency)}`}</span>
              </h4>
            )}
            {couponDiscount !== null && couponDiscount > 0 && (
              <h4>
                Coupon Discount : <span className="shop-total">{`${symbol} ${commonService.getConvertedPrice(couponDiscount, rate, currency)}`}</span>
              </h4>
            )}
            {discount !== null && discount > 0 && couponDiscount !== null && couponDiscount > 0 && (
              <h4>
                Total Discount : <span className="shop-total">{`${symbol} ${commonService.getConvertedPrice(totalDiscount, rate, currency)}`}</span>
              </h4>
            )}
            <h4>
              Total : <span className="shop-total">{`${symbol} ${commonService.getConvertedPrice(total, rate, currency)}`}</span>
            </h4>
          </div>
          <div className="shopping-cart-btn btn-hover text-center">
            <Link className="default-btn" to={process.env.PUBLIC_URL + "/cart"}>
              view cart
            </Link>
            <Link className="default-btn" to={process.env.PUBLIC_URL + "/checkout"}>
              checkout
            </Link>
          </div>
        </Fragment>
      ) : (
        <p className="text-center">No items added to cart</p>
      )}
    </div>
  );
};

export default MenuCart;
