import PropTypes from "prop-types";
import React, { useEffect, useState } from "react";
import ProductImageGallery from "./ProductImageGallery";
import ProductImageFixed from "./ProductImageFixed";
import ProductDescriptionInfo from "./ProductDescriptionInfo";
import ProductImageGallerySideThumb from "./ProductImageGallerySideThumb";
import { useSelector, useDispatch } from "react-redux";
import cartSelector from "../../../redux/selectors/cart.selector";
import currencySelector from "../../../redux/selectors/currency.selector";
import commonService from "../../../services/common-service";
import CartActions from "../../../redux/actions/cart.actions";
import { useHistory } from "react-router";

const ProductImageDescription = ({ spaceTopClass, spaceBottomClass, galleryType, product, productImages }) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const cartList = useSelector(cartSelector.cartList);
  const currency = useSelector(currencySelector.currency);
  const symbol = useSelector(currencySelector.symbol);
  const rate = useSelector(currencySelector.rate);
  const wishList = useSelector(cartSelector.wishList);
  const compareList = useSelector(cartSelector.compareList);

  const addToCart = (product) => {
    commonService.addToCart(dispatch, CartActions, product, cartList);
  };

  const addToWishList = (product) => {
    commonService.addToWishList(dispatch, CartActions, product, wishList);
  };

  const addToCompareList = (product) => {
    commonService.addToCompareList(dispatch, CartActions, product, compareList);
  };

  /* const buyNow=(product)=>{
    commonService.proceedToCheckout(history,cartList,dispatch,CartActions,product)
  } */

  const buyNow = (product) => {
    commonService.buyNow(history, dispatch, CartActions, product, cartList);
  };

  const removeFromCart = (product) => {
    commonService.removeFromCart(dispatch, CartActions, cartList, product);
  };

  const removeFromWishList = (product) => {
    commonService.removeFromWishList(dispatch, CartActions, product);
  };

  const removeFromCompareList = (product) => {
    commonService.removeFromCompareList(dispatch, CartActions, product);
  };

  return (
    <div className={`shop-area ${spaceTopClass ? spaceTopClass : ""} ${spaceBottomClass ? spaceBottomClass : ""}`}>
      <div className="container">
        <div className="row">
          <div className="col-lg-6 col-md-6">
            {/* product image gallery */}
            {galleryType === "leftThumb" ? (
              <ProductImageGallerySideThumb product={product} thumbPosition="left" productImages={productImages} />
            ) : galleryType === "rightThumb" ? (
              <ProductImageGallerySideThumb product={product} productImages={productImages} />
            ) : galleryType === "fixedImage" ? (
              <ProductImageFixed product={product} productImages={productImages} />
            ) : (
              <ProductImageGallery product={product} productImages={productImages} />
            )}
          </div>
          <div className="col-lg-6 col-md-6">
            {/* product description info */}
            <ProductDescriptionInfo
              product={product}
              currency={currency}
              cartItems={cartList}
              symbol={symbol}
              rate={rate}
              addToCart={addToCart}
              buyNow={buyNow}
              removeFromCart={removeFromCart}
              removeFromWishList={removeFromWishList}
              removeFromCompareList={removeFromCompareList}
              cartItem={commonService.isProductInCart(product, cartList)}
              wishlistItem={commonService.isProductInCart(product, wishList)}
              compareItem={commonService.isProductInCart(product, compareList)}
              addToWishList={addToWishList}
              addToCompareList={addToCompareList}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

ProductImageDescription.propTypes = {
  cartItems: PropTypes.array,
  compareItems: PropTypes.array,
  currency: PropTypes.object,
  galleryType: PropTypes.string,
  product: PropTypes.object,
  spaceBottomClass: PropTypes.string,
  spaceTopClass: PropTypes.string,
  wishlistItems: PropTypes.array,
};

export default ProductImageDescription;
