import { GENERATE_HASH_URL } from '../../services/api-service'
import actionTypes from '../action-types'

const getHashAction = (data) => {
  return {
    type: actionTypes.GENERATE_HASH,
    payload: {
      url : GENERATE_HASH_URL,
      data
    },
  }
}

const paymentActions = {
  getHashAction
}

export default paymentActions
