import PropTypes from "prop-types";
import React from "react";
import ProductgridList from "./ProductgridList";

const ShopProducts = ({ products, layout, productsLoading }) => {
  return (
    <div className="shop-bottom-area mt-35">
      <div className={`row ${layout ? layout : ""}`}>
        {productsLoading ?
        <div className='np-loading'><i class="fa fa-spinner fa-spin fa-3x fa-fw"></i></div>
        :
        products.length ? <ProductgridList products={products} spaceBottomClass="mb-25" /> : <div className='np-not-found'><img src="/assets/images/not-found.jpg" /></div>
        }
        
      </div>
    </div>
  );
};

ShopProducts.propTypes = {
  layout: PropTypes.string,
  products: PropTypes.array,
};

export default ShopProducts;
