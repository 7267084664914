import React, { useEffect } from "react";
import { Link, useHistory } from "react-router-dom";
import Constants from "../../../shared/constants";
// import MobileMenuSearch from "./sub-components/MobileSearch";
import MobileNavMenu from "./navigation/MobileNavMenu";
import MobileCurrency from "./sub-components/MobileCurrency";
import MobileMenuHeader from "./sub-components/MobileMenuHeader";
import MobileWidgets from "./sub-components/MobileWidgets";

const MobileMenu = () => {
const history = useHistory()

  useEffect(() => {
    const offCanvasNav = document.querySelector("#offcanvas-navigation");
    const offCanvasNavSubMenu = offCanvasNav.querySelectorAll(".sub-menu");
    const anchorLinks = offCanvasNav.querySelectorAll("a");

    for (let i = 0; i < offCanvasNavSubMenu.length; i++) {
      offCanvasNavSubMenu[i].insertAdjacentHTML("beforebegin", "<span class='menu-expand'><i></i></span>");
    }

    const menuExpand = offCanvasNav.querySelectorAll(".menu-expand");
    const numMenuExpand = menuExpand.length;

    for (let i = 0; i < numMenuExpand; i++) {
      menuExpand[i].addEventListener("click", (e) => {
        sideMenuExpand(e);
      });
    }

    for (let i = 0; i < anchorLinks.length; i++) {
      anchorLinks[i].addEventListener("click", () => {
        closeMobileMenu();
      });
    }
  }, []);

  const sideMenuExpand = (e) => {
    e.currentTarget.parentElement.classList.toggle("active");
  };

  const closeMobileMenu = () => {
    const offcanvasMobileMenu = document.querySelector("#offcanvas-mobile-menu");
    offcanvasMobileMenu.classList.remove("active");
  };

  const navigateToOrderStatusPage = () => {
    closeMobileMenu()
    history.push('/orderStatus')
  }

  return (
    <div className="offcanvas-mobile-menu" id="offcanvas-mobile-menu">
      <button className="offcanvas-menu-close" id="mobile-menu-close-trigger" onClick={() => closeMobileMenu()}>
        <i className="pe-7s-close"></i>
      </button>
      <div className="offcanvas-wrapper">
        <div className="offcanvas-inner-content">
          {/* mobile search */}
          {/* <MobileMenuSearch /> */}
          <MobileMenuHeader />

          {/* <div className="mobile-header-order-status">
            <a onClick={()=>navigateToOrderStatusPage()}>
              <i className="fa fa-file-text" aria-hidden="true" style={{ marginRight: 5 }} />
              <span className="">{Constants.links.orderStatus}</span>
            </a>
          </div> */}
          {/* mobile nav menu */}
          <MobileNavMenu closeMobileMenu={closeMobileMenu} />

          {/* mobile language and currency */}
          <MobileCurrency closeMobileMenu={closeMobileMenu} />

          {/* mobile widgets */}
          <MobileWidgets />
        </div>
      </div>
    </div>
  );
};

export default MobileMenu;
