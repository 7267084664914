import actionTypes from '../action-types'
import { GET_SC_COUNTRIES_URL, GET_SETTINGS_URL, GET_SC_BY_ID_URL } from '../../services/api-service'

const updateRoutesAction = (route) => {
  return {
    type: actionTypes.UPDATE_ROUTES,
    payload: route
  }
}

const getSettingsAction = () => {
  return {
    type: actionTypes.GET_SETTINGS,
    payload: {
      url: GET_SETTINGS_URL,
    },
  }
}

const getSCCountriesAction = () => {
  return {
    type: actionTypes.GET_SC_COUNTRIES,
    payload: {
      url: GET_SC_COUNTRIES_URL,
    },
  }
}

const getSCByIdAction = id => {
  return {
    type: actionTypes.GET_SC_BY_ID,
    payload: {
      url: GET_SC_BY_ID_URL,
      id
    },
  }
}

const utilActions = {
  updateRoutesAction,
  getSettingsAction,
  getSCCountriesAction,
  getSCByIdAction
}

export default utilActions
