import { PLACE_ORDER_URL,GET_ORDER_BY_ID_URL, GET_TRACKING_INFO_URL } from '../../services/api-service'
import actionTypes from '../action-types'

const placeOrderAction = (data,history) => {
  return {
    type: actionTypes.PLACE_ORDER,
    payload: {
      url : PLACE_ORDER_URL,
      data,
      history
    },
  }
}

const getOrderByIdAction = (data,history) => {
  return {
    type: actionTypes.GET_ORDER_BY_ID,
    payload: {
      url : GET_ORDER_BY_ID_URL,
      data,
      history
    },
  }
}

const geTrackingInfoAction = orderId => {
  return {
    type: actionTypes.GET_TRACKING_INFO,
    payload: {
      url: GET_TRACKING_INFO_URL,
      data: {
        orderId
      },
    },
  }
}

const OrderActions = {
  placeOrderAction,
  getOrderByIdAction,
  geTrackingInfoAction
}

export default OrderActions
