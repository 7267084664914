import PropTypes from "prop-types";
import React from "react";
import Constants from "../../../shared/constants";
import LanguageCurrencyChanger from "./LanguageCurrencyChanger";
import { isMobile } from "react-device-detect";
import { Link } from "react-router-dom";

const HeaderTop = ({ borderStyle }) => {
  return (
    <div className={`header-top-wap ${borderStyle === "fluid-border" ? "border-bottom" : ""}`}>
      {!isMobile && <LanguageCurrencyChanger />}

      <div className="header-offer">
        {/* <span className="header-order-status">
          <Link to={process.env.PUBLIC_URL + "/orderStatus"}>
            <i className="fa fa-file-text" aria-hidden="true" style={{marginRight:5}} />
            <span className="">{Constants.links.orderStatus}</span>
          </Link>
        </span> */}
        <span className="header-free-delivery">
          <i className="fa fa-truck" aria-hidden="true" /> {Constants.staticContent.freeDelivery}{" "}
        </span>
      </div>
    </div>
  );
};

HeaderTop.propTypes = {
  borderStyle: PropTypes.string,
};

export default HeaderTop;
