import actionTypes from '../action-types'
import store from 'store'
import commonService from '../../services/common-service'

const initialState = {
 isCustomerLoggedIn : false,
 customerInfo : null,
 isGuestFlow : true,
 isCustomerBeingRegistered : false,
 isCustomerBeingLoggedIn : false,
 customerOrders : undefined,
 isCustomerOrdersBeingFetched: false,
 isPasswordBeingUpdated: false
}

export default function customerReducer(state = initialState, action) {
  const newState = Object.assign({}, state)
  switch (action.type) {
    case actionTypes.UPDATE_CUSTOMER_INFO: {
      newState.customerInfo = {...action.payload}
      return newState
    }

    case actionTypes.UPDATE_GUEST_FLOW:
      newState.isGuestFlow = action.payload
      return newState

    case actionTypes.UPDATE_CUSTOMER_LOGGED_IN:
      newState.isCustomerLoggedIn = action.payload
      newState.isGuestFlow = !action.payload
      return newState

    case actionTypes.IS_CUSTOMER_BEING_LOGGED_IN:
      newState.isCustomerBeingLoggedIn = action.payload
      return newState

    case actionTypes.IS_CUSTOMER_BEING_REGISTERED:
      newState.isCustomerBeingRegistered = action.payload
      return newState

    case actionTypes.IS_CUSTOMER_ORDERS_BEING_FETCHED:
      newState.isCustomerOrdersBeingFetched = action.payload
      return newState

    case actionTypes.UPDATE_CUSTOMER_ORDERS: {
      newState.customerOrders = [...action.payload]
      return newState
    }

    case actionTypes.IS_PASSWORD_BEING_UPDATED: {
      newState.isPasswordBeingUpdated = action.payload
      return newState
    }
    
    case actionTypes.LOGOUT: 
      store.remove('np-customer')
      newState.customerInfo = null
      newState.isGuestFlow = true
      newState.isCustomerLoggedIn = false
      newState.customerOrders = []
      newState.isCustomerOrdersBeingFetched = false
      commonService.showNotification('success','Logged out successfully`')
      return newState

    default:
      return state
  }
}
