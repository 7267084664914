import commonService from "../../services/common-service";
import React from "react";

const handleSubmenu = (e, history, itemName, aliasName,closeMobileMenu=null) => {
  commonService.navigateToCollection(e, history, itemName, aliasName);
  if(closeMobileMenu !== null){
    closeMobileMenu()
  }
  
};

const handleMenu = (e, history, categoryName, categoryAliasName, subMenuItemName, subCategoryAliasName, closeMobileMenu=null) => {
  commonService.navigateToCollection(e, history, categoryName, categoryAliasName, subMenuItemName, subCategoryAliasName)
  if(closeMobileMenu !== null){
    closeMobileMenu()
  }
}

const renderMenu = (categoryList, history, classname = "",closeMobileMenu) => {
  return (
    <ul>
      {categoryList.length > 0 &&
        categoryList.map((category) =>
          category.subCategoryList.length ? (
            <li key={category.id} className={classname}>
              <a>
                {category.name}
                {/* {classname === "" && <i className="fa fa-angle-down" />} */}
              </a>
              <ul className= {classname === "" ? "submenu" : "sub-menu" }>
                {category.subCategoryList.map((subMenuItem) => (
                  <li
                    key={subMenuItem.id}
                    onClick={(e) => handleMenu(e, history, category.name, category.aliasName, subMenuItem.name, subMenuItem.aliasName,closeMobileMenu)}
                  >
                    <a>{subMenuItem.name}</a>
                  </li>
                ))}
              </ul>
            </li>
          ) : (
            <li key={category.id} onClick={(e) => handleSubmenu(e, history, category.name, category.aliasName,closeMobileMenu)}>
              <a>{category.name}</a>
              {/* {classname === "" && <i className="fa fa-angle-down" />} */}
            </li>
          )
        )}
    </ul>
  );
};

const headerService = {
  renderMenu,
  handleSubmenu,
  handleMenu
};

export default headerService;
