import React from 'react'
import { Whatapp_Number, Whatsapp_Text } from '../../shared/config'
import './whatsapp.scss'

const Whatsapp = () => {
  
  return (
    <a
      href={`https://api.whatsapp.com/send?phone=${Whatapp_Number}&text=${Whatsapp_Text}`}
      className="whatsapp_float"
      target="_blank"
      rel="noopener noreferrer"
    >
      <i className="fa fa-whatsapp whatsapp-icon" />
    </a>
  )
}

export default Whatsapp
