import PropTypes from "prop-types";
import React, { Fragment, useState } from "react";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import commonService from "../../../services/common-service";
// import { getProductCartQuantity } from "../../helpers/product";
/* import { addToCart } from "../../redux/actions/cartActions";
import { addToWishlist } from "../../redux/actions/wishlistActions";
import { addToCompare } from "../../redux/actions/compareActions"; */
// import Rating from "./sub-components/ProductRating";

const ProductDescriptionInfo = ({
  product,
  currency,
  symbol,
  rate,
  cartItem,
  wishlistItem,
  compareItem,
  addToCart,
  addToWishList,
  addToCompareList,
  buyNow,
  removeFromCart,
  removeFromWishList,
  removeFromCompareList,
}) => {
  return (
    <div className="product-details-content ml-70">
      <h2>{product.name}</h2>
      <h4>{`sku : ${product.sku}`}</h4>
      <div className="product-details-price">
        {product.discountList !== undefined && product.discountList !== null && product.discountList.length ? (
          <>
            <span>
              {symbol}
              {` `}
              {commonService.getConvertedPrice(product.specialPrice, rate, currency)}
            </span>{" "}
            <span className="old">
              {symbol}
              {` `}
              {commonService.getConvertedPrice(product.price, rate, currency)}
            </span>
          </>
        ) : (
          <span>
            {symbol}
            {` `}
            {commonService.getConvertedPrice(product.price, rate, currency)}
          </span>
        )}
      </div>
      {/* {product.rating && product.rating > 0 ? (
        <div className="pro-details-rating-wrap">
          <div className="pro-details-rating">
            <Rating ratingValue={product.rating} />
          </div>
        </div>
      ) : (
        ""
      )} */}
      <div className="pro-details-list">
        <p>{product.shortDescription}</p>
      </div>
      <div className="pro-details-size-color">
        {product.attributeList &&
          product.attributeList.map((attribute) => (
            <div className="pro-details-color-content">
              <span>{attribute.key} :</span>
              {attribute.value && attribute.value.map((values, key) => <span key={key}>{`${key !== 0 ? `, ${values}` : values}`}</span>)}
            </div>
          ))}
      </div>
      <div className="pro-details-size-color">
        <div className="pro-details-color-content">
          <span>{product.categoryList[0].name}</span>
        </div>
        {product.subCategoryList && product.subCategoryList.length && (
          <div className="pro-details-color-content">
            <span>{product.subCategoryList[0].name}</span>
          </div>
        )}
      </div>
      <div className="pro-details-quality">
        {cartItem ? (
          <div className="pro-details-cart btn-hover">
            <button onClick={() => removeFromCart(product)}> Remove from cart</button>
          </div>
        ) : (
          <div className="pro-details-cart btn-hover">
            <button onClick={() => addToCart(product)}> Add To Cart </button>
          </div>
        )}

        <div className="pro-details-cart btn-hover">
          <button onClick={() => buyNow(product)}> Buy now</button>
        </div>

        <div className="pro-details-wishlist">
          <button
            className={wishlistItem ? "active" : ""}
            title={wishlistItem ? "Added to wishlist" : "Add to wishlist"}
            onClick={() => (wishlistItem ? removeFromWishList(product) : addToWishList(product))}
          >
            {wishlistItem ? <i className="fa fa-heart" /> : <i className="fa fa-heart-o" />}
          </button>
        </div>
        <div className="pro-details-compare">
          <button
            className={compareItem ? "active" : ""}
            title={compareItem ? "Remove from compare" : "Add to compare"}
            onClick={() => (compareItem ? removeFromCompareList(product) : addToCompareList(product))}
          >
            <i className="fa fa-retweet" />
          </button>
        </div>
      </div>

      {/* <div className="pro-details-social">
        <ul>
          <li>
            <a href="//facebook.com">
              <i className="fa fa-facebook" />
            </a>
          </li>
          <li>
            <a href="//dribbble.com">
              <i className="fa fa-dribbble" />
            </a>
          </li>
          <li>
            <a href="//pinterest.com">
              <i className="fa fa-pinterest-p" />
            </a>
          </li>
          <li>
            <a href="//twitter.com">
              <i className="fa fa-twitter" />
            </a>
          </li>
          <li>
            <a href="//linkedin.com">
              <i className="fa fa-linkedin" />
            </a>
          </li>
        </ul>
      </div> */}
    </div>
  );
};

export default ProductDescriptionInfo;
