const categoryActions = {
  GET_CATEGORIES: 'GET_CATEGORIES',
  GET_ACTIVE_CATEGORIES: 'GET_ACTIVE_CATEGORIES',
  CREATE_CATEGORY: 'CREATE_CATEGORY',
  UPDATE_CATEGORY: 'UPDATE_CATEGORY',
  DELETE_ALL_CATEGORIES: 'DELETE_ALL_CATEGORIES',
  DELETE_CATEGORY: 'DELETE_CATEGORY',
  GET_CATEGORY_BY_ID: 'GET_CATEGORY_BY_ID',
  GET_PRODUCT_BY_CATEGORY: 'GET_PRODUCT_BY_CATEGORY',
  UPDATE_CATEGORY_HIERARCHY:'UPDATE_CATEGORY_HIERARCHY',

  UPDATE_CATEGORIES: 'UPDATE_CATEGORIES',
  UPDATE_ACTIVE_CATEGORIES: 'UPDATE_ACTIVE_CATEGORIES',
  SET_CATEGORY: 'SET_CATEGORY',
  SET_CATEGORY_UPDATE_STATUS: 'SET_CATEGORY_UPDATE_STATUS',
  CATEGORY_UPDATE_STATUS: 'CATEGORY_UPDATE_STATUS',
  UPDATE_CATEGORY_BASED_PRODUCTS: 'UPDATE_CATEGORY_BASED_PRODUCTS',
}

const homePageActions = {
  GET_SUB_CATEGORIES: 'GET_SUB_CATEGORIES',
  GET_CATEGORIES: 'GET_CATEGORIES',
  GET_TOP_DEALS: 'GET_TOP_DEALS',
  GET_ATTRIBUTES: 'GET_ATTRIBUTES',
  GET_BANNERS: 'GET_BANNERS',
  
  UPDATE_BANNERS: 'UPDATE_BANNERS',
  UPDATE_ATTRIBUTES: 'UPDATE_ATTRIBUTES',
  UPDATE_CATEGORIES: 'UPDATE_CATEGORIES',
  UPDATE_SUB_CATEGORIES: 'UPDATE_SUB_CATEGORIES',
  UPDATE_TOP_DEALS: 'UPDATE_TOP_DEALS',
}

const productActions = {
  GET_PRODUCTS: 'GET_PRODUCTS',
  GET_PRODUCT_BY_ID: 'GET_PRODUCT_BY_ID',
  GET_SIMILAR_PRODUCTS_BY_ID: 'GET_SIMILAR_PRODUCTS_BY_ID',
  FILTER_PRODUCTS: 'FILTER_PRODUCTS',
  
  UPDATE_PRODUCTS: 'UPDATE_PRODUCTS',
  UPDATE_SIMILAR_PRODUCTS: 'UPDATE_SIMILAR_PRODUCTS',
  UPDATE_PRODUCT_DETAIL: 'UPDATE_PRODUCT_DETAIL',
  UPDATE_FILTERED_PRODUCTS : 'UPDATE_FILTERED_PRODUCTS',
  UPDATE_SELECTED_FILTERS:'UPDATE_SELECTED_FILTERS',
  IS_PRODUCTS_BEING_FETCHED: 'IS_PRODUCTS_BEING_FETCHED'
}

const commonActions = {
  SEND_ORDER_EMAIL: 'SEND_ORDER_EMAIL',
}

const cartActions = {
  ADD_PRODUCT_TO_CART : 'ADD_PRODUCT_TO_CART',
  REMOVE_PRODUCT_FROM_CART : 'REMOVE_PRODUCT_FROM_CART',
  UPDATE_CART_FROM_CACHE : 'UPDATE_CART_FROM_CACHE',
  VALIDATE_CART_PRODUCTS : 'VALIDATE_CART_PRODUCTS',
  APPLY_COUPON : 'APPLY_COUPON',
  UPDATE_WISHLIST_FROM_CACHE: 'UPDATE_WISHLIST_FROM_CACHE',
  ADD_PRODUCT_TO_WISHLIST : 'ADD_PRODUCT_TO_WISHLIST',
  REMOVE_PRODUCT_FROM_WISHLIST : 'REMOVE_PRODUCT_FROM_WISHLIST',
  REMOVE_ALL_PRODUCTS_FROM_WISHLIST : 'REMOVE_ALL_PRODUCTS_FROM_WISHLIST',
  BUY_NOW : 'BUY_NOW',

  UPDATE_COMPARE_LIST_FROM_CACHE: 'UPDATE_COMPARE_LIST_FROM_CACHE',
  ADD_PRODUCT_TO_COMPARE_LIST : 'ADD_PRODUCT_TO_COMPARE_LIST',
  REMOVE_PRODUCT_FROM_COMPARE_LIST : 'REMOVE_PRODUCT_FROM_COMPARE_LIST',
  REMOVE_ALL_PRODUCTS_FROM_COMPARE_LIST : 'REMOVE_ALL_PRODUCTS_FROM_COMPARE_LIST',
  REMOVE_ALL_PRODUCTS_FROM_CART : 'REMOVE_ALL_PRODUCTS_FROM_CART',

  ADD_TO_CART:'ADD_TO_CART',
  REMOVE_FROM_CART:'REMOVE_FROM_CART',
  UPDATE_CART_PRODUCTS: 'UPDATE_CART_PRODUCTS',
  UPDATE_COUPON:'UPDATE_COUPON',
  UPDATE_COUPON_MESSAGE:'UPDATE_COUPON_MESSAGE',
  UPDATE_COUPON_DISCOUNT:'UPDATE_COUPON_DISCOUNT',
  RESET_CART : 'RESET_CART',
  REMOVE_COUPON : 'REMOVE_COUPON',

  ADD_TO_WISHLIST:'ADD_TO_WISHLIST',
  REMOVE_FROM_WISHLIST:'REMOVE_FROM_WISHLIST',
  UPDATE_WISHLIST_PRODUCTS: 'UPDATE_WISHLIST_PRODUCTS',
  RESET_WISHLIST : 'RESET_WISHLIST',

  ADD_TO_COMPARE_LIST:'ADD_TO_COMPARE_LIST',
  REMOVE_FROM_COMPARE_LIST:'REMOVE_FROM_COMPARE_LIST',
  UPDATE_COMPARE_LIST_PRODUCTS: 'UPDATE_COMPARE_LIST_PRODUCTS',
  RESET_COMPARE_LIST : 'RESET_COMPARE_LIST',

  UPDATE_SHIPPING_CHARGE : 'UPDATE_SHIPPING_CHARGE',
  UPDATE_CART_STATUS: 'UPDATE_CART_STATUS'
}

const orderActions = {
  PLACE_ORDER : 'PLACE_ORDER',
  GET_ORDER_BY_ID : 'GET_ORDER_BY_ID',
  
  UPDATE_ORDER : 'UPDATE_ORDER',
  IS_FETCHING_ORDER : 'IS_FETCHING_ORDER',
  UPDATE_ORDER_LOADING : 'UPDATE_ORDER_LOADING'
}

const customerActions = {
  CREATE_CUSTOMER : 'CREATE_CUSTOMER',
  SIGN_IN_CUSTOMER:'SIGN_IN_CUSTOMER',
  REFRESH_CUSTOMER : 'REFRESH_CUSTOMER',
  LOGOUT: 'LOGOUT',
  GET_CUSTOMER_ORDERS : 'GET_CUSTOMER_ORDERS',
  RESET_PASSWORD : 'RESET_PASSWORD',
  
  UPDATE_GUEST_FLOW:'UPDATE_GUEST_FLOW',
  UPDATE_CUSTOMER_INFO:'UPDATE_CUSTOMER_INFO',
  UPDATE_CUSTOMER_LOGGED_IN:'UPDATE_CUSTOMER_LOGGED_IN',
  UPDATE_ORDER : 'UPDATE_ORDER',
  IS_CUSTOMER_BEING_REGISTERED:'IS_CUSTOMER_BEING_REGISTERED',
  IS_CUSTOMER_BEING_LOGGED_IN:'IS_CUSTOMER_BEING_LOGGED_IN',
  IS_CUSTOMER_ORDERS_BEING_FETCHED:'IS_CUSTOMER_ORDERS_BEING_FETCHED',
  UPDATE_CUSTOMER_ORDERS:'UPDATE_CUSTOMER_ORDERS',
  IS_PASSWORD_BEING_UPDATED: 'IS_PASSWORD_BEING_UPDATED'
}

const currencyActions = {
  GET_CURRENCY: 'GET_CURRENCY',
  UPDATE_CURRENCY: 'UPDATE_CURRENCY',
  
  UPDATE_CURRENCYS: 'UPDATE_CURRENCYS',
  SET_CURRENCY: 'SET_CURRENCY',
  SET_CURRENCY_UPDATE_STATUS: 'SET_CURRENCY_UPDATE_STATUS',
  CURRENCY_UPDATE_STATUS: 'CURRENCY_UPDATE_STATUS',
  UPDATED_SELECTED_CURRENCY:'UPDATED_SELECTED_CURRENCY'
}

const utilActions = {
  UPDATE_ROUTES: 'UPDATE_ROUTES',
  GET_SETTINGS: 'GET_SETTINGS',
  GET_SC_COUNTRIES: 'GET_SC_COUNTRIES',
  GET_SC_BY_ID: 'GET_SC_BY_ID',
  
  
  UPDATE_SETTINGS: 'UPDATE_SETTINGS',
  UPDATE_SC_COUNTRIES: 'UPDATE_SC_COUNTRIES',
  UPDATE_SC: 'UPDATE_SC',
  RESET_SC: 'RESET_SC',
}

const paymentActions = {
  GENERATE_HASH : 'GENERATE_HASH',
  UPDATE_PAYMENT_DATA : 'UPDATE_PAYMENT_DATA',
  UPDATE_PAYMENT_DATA_LOADED : 'UPDATE_PAYMENT_DATA_LOADED'
}

const actionTypes = {
  ...categoryActions,
  ...homePageActions,
  ...productActions,
  ...commonActions,
  ...cartActions,
  ...orderActions,
  ...customerActions,
  ...currencyActions,
  ...utilActions,
  ...paymentActions,

  IS_TRACKING_INFO_BEING_FETCHED: "IS_TRACKING_INFO_BEING_FETCHED",
  UPDATE_TRACKING_INFO: "UPDATE_TRACKING_INFO",
  GET_TRACKING_INFO: "GET_TRACKING_INFO"
}

export default actionTypes
