import { Checkbox } from "antd";
import React, { useEffect, useState } from "react";
// import InputRange from 'react-input-range'
// import 'react-input-range/lib/css/index.css'
import { useSelector } from "react-redux";
// import SlideToggle from 'react-slide-toggle'
import homePageSelector from "../../../../redux/selectors/home-page.selector";
import productSelector from "../../../../redux/selectors/product.selector";
// import filterService from 'services/filter-service'
/* 

import {getBrands, getColors, getMinMaxPrice} from '../../../services';
import {filterBrand, filterColor, filterPrice} from '../../../actions'
 */
const Filter = ({ filterProducts }) => {
 // const [openFilter, updateOpenFilter] = useState(false);
 // const [price, updatePrice] = useState({ min: 0, max: 1000 });
  // const [selectedFilters, updateSelectedFilters] = useState([])
  // const prices = filterService.getMinMaxPrice()
  const filteredBrands = []; // filters.brand
  /* const categories = useSelector(homePageSelector.categoryList)
  const subCategories = useSelector(homePageSelector.subCategoryList) */
  const attributes = useSelector(homePageSelector.attributeList);
  const selectedFilters = useSelector(productSelector.selectedFilters);
  
  useEffect(() => {
   // updateOpenFilter(false);
  }, []);

  /* const filterPrice = (value) => {
    updatePrice(value);
    const newSelectedFilters = { ...selectedFilters };
    newSelectedFilters.price = value;
    filterProducts("price", newSelectedFilters);
  }; */

  /* const closeFilter = () => {
    document.querySelector(".collection-filter").style = "left: -365px";
  }; */

  /* const clickBrandHendle = event => {
    const index = filteredBrands.indexOf(event.target.value)
    if (event.target.checked) filteredBrands.push(event.target.value)
    // push in array checked value
    else filteredBrands.splice(index, 1) // removed in array unchecked value

    filterBrand()
  } */
  /*  const colorHandle = (event, color) => {
    const elems = document.querySelectorAll('.color-selector ul li')
    ;[].forEach.call(elems, el => {
      el.classList.remove('active')
    })
    event.target.classList.add('active')
    filterColor(color)
  } */

  /* const onCategoryFilterClick=(e,name)=>{
    console.log(e)
    console.log(name)
    const filters = [...selectedFilter]
    filters.push(name)
    
    updateSelectedFilters(filters)
  } */

  const filterAttribute = (e, name, value) => {
    /* console.log(e,name,value)
    const newSelectedFilters = selectedFilters
    console.log(newSelectedFilters[name])
    if(newSelectedFilters[name] === null || newSelectedFilters[name] === undefined ){
      console.log('hereeee ')
      newSelectedFilters[name] = [value]
    }else if(!e.target.checked){
      console.log('hereeee asdas')
      const selectedValues = newSelectedFilters[name]
      const newSelectedValue = []
      selectedValues.map((val)=>{
        if(val !== value){
          newSelectedValue.push(val)
        }
        return null
      })
      newSelectedFilters[name] = newSelectedValue
      delete newSelectedFilters[name]
    }else{
      newSelectedFilters[name].push(value)
    } */

    const newSelectedFilters = selectedFilters;
    if (!e.target.checked) {
      const selectedValues = newSelectedFilters[name];
      const newSelectedValue = [];
      selectedValues.map((val) => {
        if (val !== value) {
          newSelectedValue.push(val);
        }
        return null;
      });
      newSelectedFilters[name] = newSelectedValue;
      if (newSelectedValue.length === 0) {
        delete newSelectedFilters[name];
      }
    } else if (newSelectedFilters[name] === null || newSelectedFilters[name] === undefined) {
      newSelectedFilters[name] = [value];
    } else {
      newSelectedFilters[name].push(value);
    }

    // updateSelectedFilters(newSelectedFilters)
    filterProducts("attributes", newSelectedFilters);
  };

  return (
    <div>
      {attributes !== undefined &&
        attributes.length &&
        attributes.map((attribute,ind) => {
          return (
            <div className="sidebar-widget" key={`${attribute.name}-${ind}`}>
              <h4 className="pro-sidebar-title">{attribute.name} </h4>
              <div className="sidebar-widget-list mt-30 mb-30">
                <ul>
                  {/* <li>
                <div className="sidebar-widget-list-left">
                  <button
                    onClick={(e) => {
                      getSortParams("category", "");
                      setActiveSort(e);
                    }}
                  >
                    <span className="checkmark" /> All Categories
                  </button>
                </div>
              </li> */}
                  {attribute.value.map((attrbuteValue, key) => {
                    return (
                      <li key={key}>
                        <div className="sidebar-widget-list-left">
                          {/* <button
                        onClick={(e) => {
                          getSortParams("category", category);
                          setActiveSort(e);
                        }}
                      >
                        {" "}
                        <span className="checkmark" /> {category}{" "}
                      </button> */}
                          <Checkbox
                            onChange={(e) => filterAttribute(e, attribute.name, attrbuteValue)}
                            // value={attrbuteValue}
                            defaultChecked={filteredBrands.includes(attrbuteValue)}
                            id={attrbuteValue}
                          >
                            {attrbuteValue}
                          </Checkbox>
                        </div>
                      </li>
                    );
                  })}
                </ul>
              </div>
            </div>
          );
        })}
    </div>
  );
};

export default Filter;
