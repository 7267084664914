import PropTypes from "prop-types";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useHistory } from "react-router";
import homePageSelector from "../../../../redux/selectors/home-page.selector";
import headerService from "../../header.service";

const NavMenu = ({ menuWhiteClass }) => {
  const history = useHistory();
  const categories = useSelector(homePageSelector.categoryList);
  const [categoryList, updateCategoryList] = useState([]);
  
  useEffect(() => {
    updateCategoryList(categories);
  }, [categories]);

  

  return (
    <div className={`main-menu ${menuWhiteClass ? menuWhiteClass : ""}`}>
      <nav>
        {headerService.renderMenu(categoryList,history)}
      </nav>
    </div>
  );
};

NavMenu.propTypes = {
  menuWhiteClass: PropTypes.string
};

export default NavMenu;
