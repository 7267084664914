import React from "react";
import {
  Magnifier,
  GlassMagnifier,
  SideBySideMagnifier,
  PictureInPictureMagnifier,
  MOUSE_ACTIVATION,
  TOUCH_ACTIVATION,
} from "react-image-magnifiers";

import { isMobile } from "react-device-detect";

const ProductZoom = ({ src }) => {
  return (
    <>
      {/* <GlassMagnifier
              imageSrc={productImages[0]}
              imageAlt="Example"
              
            /> */}
      {isMobile ? (
        <Magnifier
          imageSrc={src}
          imageAlt="Example"
          mouseActivation={MOUSE_ACTIVATION.DOUBLE_CLICK} // Optional
          touchActivation={TOUCH_ACTIVATION.DOUBLE_TAP} // Optional
        />
      ) : (
        <SideBySideMagnifier
          imageSrc={src}
          imageAlt="Example"
          mouseActivation={MOUSE_ACTIVATION.DOUBLE_CLICK} // Optional
          touchActivation={TOUCH_ACTIVATION.DOUBLE_TAP} // Optional
        />
      )}
      {/* <Magnifier
              imageSrc={src}
              imageAlt="Example"
              mouseActivation={MOUSE_ACTIVATION.DOUBLE_CLICK} // Optional
              touchActivation={TOUCH_ACTIVATION.DOUBLE_TAP} // Optional
            /> */}

      {/* <SideBySideMagnifier
        imageSrc={src}
        imageAlt="Example"
        mouseActivation={MOUSE_ACTIVATION.DOUBLE_CLICK} // Optional
        touchActivation={TOUCH_ACTIVATION.DOUBLE_TAP} // Optional
        alwaysInPlace={true}
      /> */}
      <div className="tip">Double (click / press) on the image to zoom (in / out).</div>
    </>
  );
};

export default ProductZoom;
