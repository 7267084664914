import utilActions from "../action-types";

const initialState = {
  currentRoute: "",
  previousRoute: "",
  codLimit: null,
  countryList: [],
  selectedSC: null
};

export default function utilReducer(state = initialState, action) {
  const newState = Object.assign({}, state);
  switch (action.type) {
    case utilActions.UPDATE_ROUTES:
      newState.previousRoute = newState.currentRoute;
      newState.currentRoute = action.payload;
      return newState;

    case utilActions.UPDATE_SETTINGS:
      const { codLimit } = action.payload;
      newState.codLimit = codLimit;
      return newState;

    case utilActions.UPDATE_SC_COUNTRIES:
      newState.countryList = [...action.payload];
      return newState;

    case utilActions.UPDATE_SC:
      newState.selectedSC = {...action.payload};
      return newState;

    case utilActions.RESET_SC:
      newState.selectedSC = null
      return newState;
      
    default:
      return state;
  }
}
