// const ip = require('ip')

const isValidObject=(obj)=>{
  let bool = true
  if(obj === undefined || obj === null || obj === ''){
    bool = false
  }
  return bool
}


const blockInspect=()=>{
  document.onkeydown = function(e) {
    if(e.keyCode == 123) {
       return false;
    }
    if(e.ctrlKey && e.shiftKey && e.keyCode == 'I'.charCodeAt(0)) {
       return false;
    }
    if(e.ctrlKey && e.shiftKey && e.keyCode == 'C'.charCodeAt(0)) {
       return false;
    }
    if(e.ctrlKey && e.shiftKey && e.keyCode == 'J'.charCodeAt(0)) {
       return false;
    }
    if(e.ctrlKey && e.keyCode == 'U'.charCodeAt(0)) {
       return false;
    }
  }
}

/* const getIP=()=>{
  return ip.address()
}
 */
const commonUtil={
  isValidObject,
  blockInspect,
 //  getIP
}

export default commonUtil