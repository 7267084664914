import React, { useState, useEffect } from "react";
// import MetaTags from 'react-meta-tags';
import { Pagination } from 'antd';
/* import { BreadcrumbsItem } from 'react-breadcrumbs-dynamic'; */
// import { getSortedProducts } from "../../../helpers/product";
import Breadcrumb from "../../../wrappers/breadcrumb/Breadcrumb";
import ShopSidebar from "./ShopSidebar";
import ShopTopbar from "./ShopTopbar";
import { BreadcrumbsItem } from "react-breadcrumbs-dynamic";
import ShopProducts from "./ShopProducts";
import productActions from "../../../redux/actions/products.actions";
import productSelector from "../../../redux/selectors/product.selector";
import { useHistory } from "react-router";
import { useDispatch, useSelector } from "react-redux";
import HomePageActions from "../../../redux/actions/home-page.actions";
import commonService from "../../../services/common-service";
import utilActions from "../../../redux/actions/util.actions";
import { Drawer } from "antd";
import { isMobile } from "react-device-detect";

const CollectionsRoot = () => {
  const [layout, setLayout] = useState("grid three-column");
  /* const [sortType, setSortType] = useState("");
  const [sortValue, setSortValue] = useState("");
  const [filterSortType, setFilterSortType] = useState(""); */
  const [filterSortValue, setFilterSortValue] = useState("default");
  const [currentPage, setCurrentPage] = useState(null);
  const [sortedProducts, setSortedProducts] = useState([]);
  const [mobileFilterVisible, updateMobileFilterVisibile] = useState(false);

  const history = useHistory();
  const { pathname, search } = history.location;
  const dispatch = useDispatch();
  const productList = useSelector(productSelector.productList);
  const paginationData = useSelector(productSelector.paginationData);
  const filteredProductList = useSelector(productSelector.filteredProductList);

  const productsLoading = useSelector(productSelector.isProductsBeingFetched);

  useEffect(() => {
    let type = "or";
    const data = {
      status: "Web",
      count: 21,
      page: 0,
    };
    if (pathname) {
      const queryParams = new URLSearchParams(search)
      const pageNo = queryParams.get("page")
      if (!currentPage) {
        setCurrentPage(parseInt(pageNo ?? 1))
      }
      const collectionData = pathname.split("/");
      if (collectionData.length) {
        if (collectionData.length === 4) {
          data.categoryAliasName = collectionData[2];
          data.subCategoryAliasName = collectionData[3];
          type = "and";
        }
        if (collectionData.length === 3) {
          data.aliasName = collectionData[2];
          if (data.aliasName === "newArrivals") {
            type = "newArrivals";
          } else if (data.aliasName === "bestSelling") {
            type = "bestSelling";
          } else {
            type = "or";
          }
        }
        data.page = parseInt(pageNo ?? 1)-1;
        data.count = 21;
      }
    }
    dispatch(productActions.getProductsAction(type, data));
    dispatch(HomePageActions.getAttributesAction());
  }, [pathname, search]);

  useEffect(() => {
    commonService.scrollToTop();
    dispatch(utilActions.updateRoutesAction("/collections"));
    if (isMobile) {
      setLayout("list");
    }
  }, []);

  const pageLimit = 15;
  // const { pathname } = location;

  const getLayout = (layout) => {
    setLayout(layout);
  };

  /* const getSortParams = (sortType, sortValue) => {
     setSortType(sortType);
    setSortValue(sortValue);
  }; */

  const getFilterSortParams = (sortType, sortValue) => {
    /* setFilterSortType(sortType);
    setFilterSortValue(sortValue); */
    setFilterSortValue(sortValue);
    getSortedProducts(productList, sortValue);
  };

  const filterProducts = (type, selectedFilters) => {
    dispatch(
      productActions.filterProductsAction({
        type,
        selectedFilters,
        productList,
      })
    );
  };

  useEffect(() => {
    if (pathname && currentPage !== null) {
      history.push({
        search: `page=${currentPage}&s=20`
      })
    }
    
  },[currentPage])

  const toggleFilter = () => {
    if (mobileFilterVisible) {
      updateMobileFilterVisibile(false);
    } else {
      updateMobileFilterVisibile(true);
    }
  };

  const getSortedProducts = (productList, sortValue) => {
    const newList = productList.sort((a, b) => {
      if (sortValue === "priceLowToHigh") {
        if (a.price < b.price) {
          return -1;
        }
        if (a.price > b.price) {
          return 1;
        }
      } else if (sortValue === "priceHighToLow") {
        if (a.price < b.price) {
          return 1;
        }
        if (a.price > b.price) {
          return -1;
        }
      }
      return 0;
    });
    setSortedProducts([...newList]);
  };

  const setPaginatorCurrentPage = e => {
    setCurrentPage(e)
    commonService.scrollToTop();
  }
  
  
  return (
    <>
      <BreadcrumbsItem to={process.env.PUBLIC_URL + "/"}>Home</BreadcrumbsItem>
      <BreadcrumbsItem to={process.env.PUBLIC_URL + pathname}>Collections</BreadcrumbsItem>
      {/* breadcrumb */}
      <Breadcrumb />

      <div className="shop-area pt-95 pb-100">
        <div className="container-fluid">
          <div className="row">
            <div className="col-lg-3 order-2 order-lg-1">
              {/* shop sidebar */}
              <div className="filter-block">
                <ShopSidebar products={filteredProductList} filterProducts={filterProducts} sideSpaceClass="mr-30" />
              </div>
            </div>
            <div className="col-lg-9 order-1 order-lg-2">
              {/* shop topbar default */}
              <ShopTopbar
                toggleFilter={toggleFilter}
                getLayout={getLayout}
                getFilterSortParams={getFilterSortParams}
                productCount={paginationData?.totalCount ?? 0}
                filteredProductCount={filteredProductList.length}
                mobile={isMobile}
              />
              {/* shop page content default */}
              <ShopProducts
                layout={layout}
                products={filterSortValue === "default" ? filteredProductList : sortedProducts}
                productsLoading={productsLoading}
              />

              {/* shop product pagination */}
              <div className="qpro-pagination-style text-center mt-30">
                <Pagination pageSize={20} defaultCurrent={1} showSizeChanger={false} current={currentPage} total={paginationData?.totalCount} onChange={setPaginatorCurrentPage} align="end"/>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Drawer title="Filter" placement="left" closable={true} onClose={toggleFilter} visible={mobileFilterVisible}>
        <ShopSidebar products={filteredProductList} filterProducts={filterProducts} sideSpaceClass="mr-30" />
      </Drawer>
    </>
  );
};

export default CollectionsRoot;
