import PropTypes from "prop-types";
import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { animateScroll } from "react-scroll";
import FooterCopyright from "../../components/footer/FooterCopyright";
import FooterNewsletter from "../../components/footer/FooterNewsletter";
import Whatsapp from "../../components/whatsapp/whatsapp";
import Constants from "../../shared/constants";

const MainFooter = ({
  backgroundColorClass,
  spaceTopClass,
  spaceBottomClass,
  spaceLeftClass,
  spaceRightClass,
  containerClass,
  extraFooterClass,
  sideMenu,
}) => {
  const [scroll, setScroll] = useState(0);
  const [top, setTop] = useState(0);

  useEffect(() => {
    setTop(100);
    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  const scrollToTop = () => {
    animateScroll.scrollToTop();
  };

  const handleScroll = () => {
    setScroll(window.scrollY);
  };

  return (
    <footer
      className={`footer-area ${backgroundColorClass ? backgroundColorClass : ""} ${spaceTopClass ? spaceTopClass : ""} ${
        spaceBottomClass ? spaceBottomClass : ""
      } ${extraFooterClass ? extraFooterClass : ""} ${spaceLeftClass ? spaceLeftClass : ""} ${spaceRightClass ? spaceRightClass : ""}`}
    >
      <div className={`${containerClass ? containerClass : "container"}`}>
        <div className="row">
          <div className={`${sideMenu ? "col-xl-2 col-sm-4" : "col-lg-3 col-sm-4"}`}>
            {/* footer copyright */}
            <FooterCopyright footerLogo="/assets/images/narayana-logo.png" spaceBottomClass="mb-30" />
          </div>
          <div className={`${sideMenu ? "col-xl-2 col-sm-4" : "col-lg-2 col-sm-4"}`}>
            <div className="footer-widget mb-30 ml-10">
              <div className="footer-title">
                <h3>COMPANY</h3>
              </div>
              <div className="footer-list">
                <ul>
                  <li>
                    <Link to={process.env.PUBLIC_URL + "/about"}>About us</Link>
                  </li>
                  <li>
                    <Link to={process.env.PUBLIC_URL + "/franchise"}>Franchise Opportunity</Link>
                  </li>
                  <li>
                    <Link to={process.env.PUBLIC_URL + "/testimonial"}>Testimonials</Link>
                  </li>
                  <li>
                    <Link to={process.env.PUBLIC_URL + "/store-locator"}>Store Locator</Link>
                  </li>
                  <li>
                    <Link to={process.env.PUBLIC_URL + "/contact"}>Contact us</Link>
                  </li>
                </ul>
              </div>
            </div>
          </div>
          <div className={`${sideMenu ? "col-xl-2 col-sm-4" : "col-lg-2 col-sm-4"}`}>
            <div className={`${sideMenu ? "footer-widget mb-30 ml-40" : "footer-widget mb-30 ml-10"}`}>
              <div className="footer-title">
                <h3>POLICY</h3>
              </div>
              <div className="footer-list">
                <ul>
                  <li>
                    <Link to={process.env.PUBLIC_URL + "/terms-of-sale"}>Terms of Sale</Link>
                  </li>
                  <li>
                    <Link to={process.env.PUBLIC_URL + "/payment-method"}>Payment Method</Link>
                  </li>
                  <li>
                    <Link to={process.env.PUBLIC_URL + "/shipping-info"}>Shipping Info</Link>
                  </li>
                  <li>
                    <Link to={process.env.PUBLIC_URL + "/privacy-policy"}>Privacy Policy</Link>
                  </li>
                  <li>
                    <Link to={process.env.PUBLIC_URL + "/disclaimer"}>Disclaimer</Link>
                  </li>
                </ul>
              </div>
            </div>
          </div>
          <div className={`${sideMenu ? "col-xl-3 col-sm-4" : "col-lg-2 col-sm-6"}`}>
            <div className={`${sideMenu ? "footer-widget mb-30 ml-145" : "footer-widget mb-30 ml-10"}`}>
              <div className="footer-title">
                <h3>HELP</h3>
              </div>
              <div className="footer-list">
                <ul>
                  <li>
                    <Link to={process.env.PUBLIC_URL + "/how-to-order"}>How to Order</Link>
                  </li>
                  <li>
                    <Link to={process.env.PUBLIC_URL + "/size-cart"}>Size Chart</Link>
                  </li>
                  <li>
                    <Link to={process.env.PUBLIC_URL + "/shipping-charge"}>Shipping Charges</Link>
                  </li>
                  <li>
                    <Link to={process.env.PUBLIC_URL + "/jewellery-care"}>Jewellery Care</Link>
                  </li>
                  <li>
                    <Link to={process.env.PUBLIC_URL + "/customised-service"}>Customized Service</Link>
                  </li>
                  <li>
                    <Link to={process.env.PUBLIC_URL + "/orderStatus"}>{Constants.links.orderStatus}</Link>
                  </li>
                </ul>
              </div>
            </div>
          </div>
          <div className={`${sideMenu ? "col-xl-3 col-sm-8" : "col-lg-2 col-sm-6"}`}>
            <FooterNewsletter spaceBottomClass="mb-30" spaceLeftClass="ml-10" sideMenu={sideMenu} />
          </div>
        </div>
      </div>
      <Whatsapp />
      <button className={`scroll-top ${scroll > top ? "show" : ""}`} onClick={() => scrollToTop()}>
        <i className="fa fa-angle-double-up"></i>
      </button>
    </footer>
  );
};

MainFooter.propTypes = {
  backgroundColorClass: PropTypes.string,
  containerClass: PropTypes.string,
  extraFooterClass: PropTypes.string,
  sideMenu: PropTypes.bool,
  spaceBottomClass: PropTypes.string,
  spaceTopClass: PropTypes.string,
  spaceLeftClass: PropTypes.string,
  spaceRightClass: PropTypes.string,
};

export default MainFooter;
