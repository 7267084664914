import {
  GET_SIMILAR_PRODUCT_BY_ID,
  GET_PRODUCTS_BY_CAT_AND_SUBCAT_URL,
  GET_PRODUCTS_BY_CAT_OR_SUBCAT_URL,
  GET_PRODUCTS_URL,
  GET_PRODUCT_BY_ID,
  GET_TOP_DEALS_URL,
} from "../../services/api-service";
import actionTypes from "../action-types";

/* const getProductsAction = (path) => {
  return {
    type: actionTypes.GET_PRODUCTS,
    payload: {
      url: GET_PRODUCTS_URL,
      path
    }
  }
}

const getProductsByCategoryAndSubCategoryAction = (path) => {
  return {
    type: actionTypes.GET_PRODUCTS_BY_CAT_AND_SUBCAT,
    payload: {
      url: GET_PRODUCTS_BY_CAT_AND_SUBCAT_URL,
      path
    }
  }
}

const getProductsByCategoryOrSubCategoryAction = (path) => {
  return {
    type: actionTypes.GET_PRODUCTS_BY_CAT_OR_SUBCAT,
    payload: {
      url: GET_PRODUCTS_BY_CAT_OR_SUBCAT_URL,
      path
    }
  }
} */

const getProductsAction = (type, data) => {
  const url =
    type === "bestSelling" || type === "newArrivals"
      ? GET_TOP_DEALS_URL
      : type === "or"
      ? GET_PRODUCTS_BY_CAT_OR_SUBCAT_URL
      : GET_PRODUCTS_BY_CAT_AND_SUBCAT_URL;
  return {
    type: actionTypes.GET_PRODUCTS,
    payload: {
      url,
      data,
      type,
    },
  };
};

const getProductById = (id) => {
  return {
    type: actionTypes.GET_PRODUCT_BY_ID,
    payload: {
      url: GET_PRODUCT_BY_ID,
      id,
    },
  };
};

const updateSelectedFilters = (data) => {
  return {
    type: actionTypes.UPDATE_SELECTED_FILTERS,
    payload: data,
  };
};

const filterProductsAction = (data) => {
  return {
    type: actionTypes.FILTER_PRODUCTS,
    payload: {
      data,
    },
  };
};

const getSimilarProductsAction = (productId) => {
  const url = GET_SIMILAR_PRODUCT_BY_ID;
  return {
    type: actionTypes.GET_SIMILAR_PRODUCTS_BY_ID,
    payload: {
      url,
      productId,
    },
  };
};

const productActions = {
  getProductById,
  getProductsAction,
  updateSelectedFilters,
  filterProductsAction,
  getSimilarProductsAction,
};

export default productActions;
