import { all,call,/*  call, */ fork, put, takeLatest } from 'redux-saga/effects'
import store from 'store';
import actionTypes from '../action-types'
import callFetchApi from '../../services/api'
import commonService from '../../services/common-service';
import { VALIDATE_CART_PRODUCTS_URL } from '../../services/api-service';

// import callFetchApi from 'services/api'

export function* buyNowSaga(action) {
  try {
    const { history, product } = action.payload
    yield put({
      type: actionTypes.ADD_TO_CART,
      payload : product,
    })
    history.push('/checkout')
  } catch (error) {
    console.log('buy action error')
  }
}

export function* addProductToCartSaga(action) {
  try {
    const { data } = action.payload
    yield put({
      type: actionTypes.ADD_TO_CART,
      payload : data,
    })
  } catch (error) {
    console.log('here')
  }
}

export function* removeProductFromCartSaga(action) {
  try {
    const { data, cartList } = action.payload
    const updatedProducts = cartList.filter(item => item.id !== data.id)
    if(updatedProducts.length === 0) {
      yield put({
        type: actionTypes.REMOVE_COUPON
      })
    }
    yield put({
      type: actionTypes.REMOVE_FROM_CART,
      payload : updatedProducts
    })
     
    if(updatedProducts.length === 0) {
      /* yield put({
        type: actionTypes.REMOVE_COUPON
      }) */
      yield put({
        type: actionTypes.UPDATE_SHIPPING_CHARGE,
        payload: 0
      })
      yield put({
        type: actionTypes.RESET_SC,
      })
    }
  } catch (error) {
    console.log('here')
  }
}

export function* removeAllProductsFromCartSaga(action) {
  try {
    /* yield put({
      type: actionTypes.REMOVE_COUPON
    }) */
    yield put({
      type: actionTypes.RESET_CART
    })
    yield put({
      type: actionTypes.UPDATE_SHIPPING_CHARGE,
      payload: 0
    })
  } catch (error) {
    console.log('here')
  }
}

export function* updateCartFromCacheSaga() {
  try {
    yield put({
      type: actionTypes.UPDATE_CART_STATUS,
      payload : false,
    })
    const storeObj = store.get('cart-products')
    let cartproductsList = []
    
    if (storeObj) {
      cartproductsList = JSON.parse(storeObj)
    }
    const cartProductIds = getCartProductIdsFromList(cartproductsList)
    const queryData = {
      'productIdList': cartProductIds.toString()
    }
    const response = yield call(callFetchApi, VALIDATE_CART_PRODUCTS_URL, queryData, 'GET')
    yield put({
      type: actionTypes.UPDATE_CART_PRODUCTS,
      payload : response.data,
    })
    
    yield put({
      type: actionTypes.UPDATE_CART_STATUS,
      payload : true,
    })
  } catch (error) {
    console.log('here')
  }
}

export function* validateCartProductsSaga(action) {
  try {
    const { url,data,history } = action.payload
    const cartProductIds = getCartProductIdsFromList(data)
    const queryData = {
      'productIdList': cartProductIds.toString()
    }
    const response = yield call(callFetchApi, url, queryData, 'GET')
    yield put({
      type: actionTypes.UPDATE_CART_PRODUCTS,
      payload : response.data,
    })
    yield put({
      type: 'UPDATE_IS_CART_VALIDATED',
      payload : true,
    })
    if(response.data.length > 0){
      // history.push('/checkout')
      if(JSON.stringify(response.data) !== JSON.stringify(data)){
        commonService.showNotification('success','Cart updated','Products in cart are updated')
      }
    }else{
      commonService.showNotification('warning','Cart updated','Products in cart are updated.')
      history.push('/')
    }
  } catch (error) {
    console.log('here')
  }
}

export function* applyCouponSaga(action) {
  try {
    const { url,data } = action.payload
    // const cartProductIds = getCartProductIdsFromList(data.cartList)
    const postData = {
      'productList': data.cartList,
      'couponCode' : data.couponCode
    }
    const response = yield call(callFetchApi, url, {}, 'POST', postData)
    let couponMessage = ''
    let coupon = null
    let couponDiscount = 0

    if(response.status === 200){
      const {productList} = response.data
       coupon = {...response.data.coupon}
      // add coupon message
      if(productList.length){
        couponMessage = 'Coupon Applied !!'
        productList.map((product)=>{
          couponDiscount += commonService.getProductCouponDiscount(coupon,product)
          return product
        })
      }else{
        // coupon applied but no eligible products
        couponMessage = 'Coupon Applied but no valid products in cart !!'
      }
      // update coupon
    }else{
        couponMessage = ''
        commonService.showNotification('error','Not a valid coupon')
    }
    yield put({
      type: actionTypes.UPDATE_COUPON,
      payload : coupon,
    })
    yield put({
      type: actionTypes.UPDATE_COUPON_MESSAGE,
      payload : couponMessage,
    })
    yield put({
      type: actionTypes.UPDATE_COUPON_DISCOUNT,
      payload : {
        cDiscount : couponDiscount,
        isValidCoupon : coupon
      },
    })
  } catch (error) {
    console.log('here')
  }
}

const getCartProductIdsFromList=(productList)=>{
  const productIds = []
  if(productList.length > 0){
    productList.map(product=> productIds.push(product.id))
  }
  return productIds
}


export function* addProductToWishListSaga(action) {
  try {
    const { data } = action.payload
    yield put({
      type: actionTypes.ADD_TO_WISHLIST,
      payload : data,
    })
  } catch (error) {
    console.log('here')
  }
}

export function* removeProductFromWishListSaga(action) {
  try {
    const { data } = action.payload
    yield put({
      type: actionTypes.REMOVE_FROM_WISHLIST,
      payload : data
    })
  } catch (error) {
    console.log('here')
  }
}

export function* removeAllProductsFromWishListSaga() {
  try {
    yield put({
      type: actionTypes.RESET_WISHLIST,
    })
  } catch (error) {
    console.log('here')
  }
}

export function* updateWishListFromCacheSaga() {
  try {
    const storeObj = store.get('wishList-products')
    let wishListproductsList = []
    if (storeObj) {
      wishListproductsList = JSON.parse(storeObj)
    }

    const wishListProductIds = getCartProductIdsFromList(wishListproductsList)
    const wishQueryData = {
      'productIdList': wishListProductIds.toString()
    }
    const wishResponse = yield call(callFetchApi, VALIDATE_CART_PRODUCTS_URL, wishQueryData, 'GET')
    yield put({
      type: actionTypes.UPDATE_WISHLIST_PRODUCTS,
      payload : wishResponse.data,
    })
   
  } catch (error) {
    console.log('here')
  }
}

export function* addProductToCompareListSaga(action) {
  try {
    const { data } = action.payload
    yield put({
      type: actionTypes.ADD_TO_COMPARE_LIST,
      payload : data,
    })
  } catch (error) {
    console.log('here')
  }
}

export function* removeProductFromCompareListSaga(action) {
  try {
    const { data } = action.payload
    yield put({
      type: actionTypes.REMOVE_FROM_COMPARE_LIST,
      payload : data
    })
  } catch (error) {
    console.log('here')
  }
}

export function* removeAllProductsFromCompareListSaga() {
  try {
    yield put({
      type: actionTypes.RESET_COMPARE_LIST,
    })
  } catch (error) {
    console.log('here')
  }
}

export function* updateCompareListFromCacheSaga() {
  try {
    const storeObj = store.get('compareList-products')
    let compareListproductsList = []
    if (storeObj) {
      compareListproductsList = JSON.parse(storeObj)
    }
    const compareProductIds = getCartProductIdsFromList(compareListproductsList)
    const queryData = {
      'productIdList': compareProductIds.toString()
    }
    const compareResponse = yield call(callFetchApi, VALIDATE_CART_PRODUCTS_URL, queryData, 'GET')
    yield put({
      type: actionTypes.UPDATE_COMPARE_LIST_PRODUCTS,
      payload : compareResponse.data,
    })

    yield put({
      type: actionTypes.UPDATE_COMPARE_LIST_PRODUCTS,
      payload : compareListproductsList,
    })
  } catch (error) {
    console.log('here')
  }
}

function* watchAddProductToCartSaga() {
  yield takeLatest(actionTypes.ADD_PRODUCT_TO_CART, addProductToCartSaga)
}

function* watchRemoveProductFromCartSaga() {
  yield takeLatest(actionTypes.REMOVE_PRODUCT_FROM_CART, removeProductFromCartSaga)
}

function* watchRemoveAllProductsFromCartSaga() {
  yield takeLatest(actionTypes.REMOVE_ALL_PRODUCTS_FROM_CART, removeAllProductsFromCartSaga)
}

function* watchUpdateCartFromCacheSaga() {
  yield takeLatest(actionTypes.UPDATE_CART_FROM_CACHE, updateCartFromCacheSaga)
}

function* watchAddProductToWishListSaga() {
  yield takeLatest(actionTypes.ADD_PRODUCT_TO_WISHLIST, addProductToWishListSaga)
}

function* watchRemoveProductFromWishListSaga() {
  yield takeLatest(actionTypes.REMOVE_PRODUCT_FROM_WISHLIST, removeProductFromWishListSaga)
}

function* watchRemoveAllProductsFromWishListSaga() {
  yield takeLatest(actionTypes.REMOVE_ALL_PRODUCTS_FROM_WISHLIST, removeAllProductsFromWishListSaga)
}


function* watchUpdateWishListFromCacheSaga() {
  yield takeLatest(actionTypes.UPDATE_WISHLIST_FROM_CACHE, updateWishListFromCacheSaga)
}

function* watchAddProductToCompareListSaga() {
  yield takeLatest(actionTypes.ADD_PRODUCT_TO_COMPARE_LIST, addProductToCompareListSaga)
}

function* watchRemoveProductFromCompareListSaga() {
  yield takeLatest(actionTypes.REMOVE_PRODUCT_FROM_COMPARE_LIST, removeProductFromCompareListSaga)
}

function* watchRemoveAllProductsFromCompareListSaga() {
  yield takeLatest(actionTypes.REMOVE_ALL_PRODUCTS_FROM_COMPARE_LIST, removeAllProductsFromCompareListSaga)
}


function* watchUpdateCompareListFromCacheSaga() {
  yield takeLatest(actionTypes.UPDATE_COMPARE_LIST_FROM_CACHE, updateCompareListFromCacheSaga)
}

function* watchValidateCartProductsSaga() {
  yield takeLatest(actionTypes.VALIDATE_CART_PRODUCTS, validateCartProductsSaga)
}

function* watchApplyCouponSaga() {
  yield takeLatest(actionTypes.APPLY_COUPON, applyCouponSaga)
}

function* watchBuyNowSaga() {
  yield takeLatest(actionTypes.BUY_NOW, buyNowSaga)
}

export default function* cartSagas() {
  yield all([
    fork(watchAddProductToCartSaga),
    fork(watchRemoveProductFromCartSaga),
    fork(watchRemoveAllProductsFromCartSaga),
    fork(watchUpdateCartFromCacheSaga),
    fork(watchValidateCartProductsSaga),
    fork(watchApplyCouponSaga),
    fork(watchAddProductToWishListSaga),
    fork(watchRemoveProductFromWishListSaga),
    fork(watchUpdateWishListFromCacheSaga),
    fork(watchRemoveAllProductsFromWishListSaga),
    fork(watchAddProductToCompareListSaga),
    fork(watchRemoveProductFromCompareListSaga),
    fork(watchRemoveAllProductsFromCompareListSaga),
    fork(watchUpdateCompareListFromCacheSaga),
    fork(watchBuyNowSaga)
  ])
}
