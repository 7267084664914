import paymentActions from '../action-types'

const initialState = {
  paymentData : {},
  isPaymentDataLoaded : false
}

export default function paymentReducer(state = initialState, action) {
  const newState = Object.assign({}, state)
  switch (action.type) {
    case paymentActions.UPDATE_PAYMENT_DATA:
      newState.paymentData = { ...action.payload }
      return newState

    case paymentActions.UPDATE_PAYMENT_DATA_LOADED:
      newState.isPaymentDataLoaded = action.payload
      return newState

    default:
      return state
  }
}
