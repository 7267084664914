import { lazy } from 'react'
import CheckoutPage from '../pages/CheckoutPage'
import CollectionsPage from '../pages/Collections'
import ProductPage from '../pages/ProductPage'
const HomePage = lazy(() => import('../pages/HomePage'))
const CartPage = lazy(() => import('../pages/CartPage'))
// const CheckoutPage = lazy(() => import('../pages/CheckoutPage'))
// const CheckoutPage = import('../pages/CheckoutPage'))
const LoginRegisterPage = lazy(() => import('../pages/LoginRegisterPage')) 
const AboutPage = lazy(() => import('../pages/static/about.page')) 
const FranchisePage = lazy(() => import('../pages/static/franchise.page')) 
const TestimonialPage = lazy(() => import('../pages/static/testimonial.page')) 
const TermsOfSalePage = lazy(() => import('../pages/static/terms-of-sale.page')) 
const StoreLocatorPage = lazy(() => import('../pages/static/store-locator.page')) 
const SizeChartPage = lazy(() => import('../pages/static/size-chart.page')) 
const ShippingInfoPage = lazy(() => import('../pages/static/shipping-info.page')) 
const ShippingChargePage = lazy(() => import('../pages/static/shipping-charge.page')) 
const PrivacyPolicyPage = lazy(() => import('../pages/static/privacy-policy.page')) 
const PaymentMethodPage = lazy(() => import('../pages/static/payment-method.page')) 
const JewelleryCarePage = lazy(() => import('../pages/static/jewellery-care.page')) 
const HowToOrderPage = lazy(() => import('../pages/static/how-to-order.page')) 
const DisclaimerPage = lazy(() => import('../pages/static/disclaimer.page')) 
const ContactPage = lazy(() => import('../pages/static/contact.page')) 
const CustomisedServicePage = lazy(() => import('../pages/static/customised-service.page')) 
// const CollectionsPage = lazy(() => import('../pages/Collections')) 
// const ProductPage = lazy(() => import('../pages/ProductPage')) 
const OrderDetailPage = lazy(() => import('../pages/OrderDetailPage')) 
const WishlistPage = lazy(() => import('../pages/WishlistPage')) 
const ComparePage = lazy(() => import('../pages/ComparePage')) 
const FailurePage = lazy(() => import('../pages/FailurePage')) 
const NotFound = lazy(() => import('../pages/NotFoundPage')) 
const MyAccount = lazy(() => import('../pages/MyAccountPage'))
const AccountPage = lazy(() => import('../pages/AccountPage')) 
const OrderStatusPage = lazy(() => import('../pages/OrderStatusPage')) 

const routes = [
    {
        path: '/orderStatus',
        component: OrderStatusPage,
    },
    {
        path: '/order',
        component: OrderDetailPage,
    },
    {
        path: '/compare',
        component: ComparePage,
    },
    {
        path: '/orderSuccess',
        component: OrderDetailPage,
    },
    {
        path: '/orderFailure',
        component: FailurePage,
    },
    {
        path: '/login-signup',
        component: LoginRegisterPage,
    },
    {
        path: '/collections',
        component: CollectionsPage,
    },
    {
        path: '/product',
        component: ProductPage,
    },
    {
        path: '/cart',
        component: CartPage,
    },
    {
        path: '/wishlist',
        component: WishlistPage,
    },
    {
        path: '/checkout',
        component: CheckoutPage,
    },
    {
        path: '/error',
        component: NotFound,
    },
    {
        path: '/myAccount',
        component: AccountPage,
    },
    {
        path: '/',
        component: HomePage,
        exact: true,
    },
];

const staticRoutes = [
    {
        path: '/about',
        component: AboutPage,
    },
    {
        path: '/franchise',
        component: FranchisePage,
    },
    {
        path: '/contact',
        component: ContactPage,
    }, 
    {
        path: '/customised-service',
        component: CustomisedServicePage,
    },
    {
        path: '/disclaimer',
        component: DisclaimerPage,
    },
    {
        path: '/franchise',
        component: FranchisePage,
    },
    {
        path: '/how-to-order',
        component: HowToOrderPage,
    },
    {
        path: '/jewellery-care',
        component: JewelleryCarePage,
    },
    {
        path: '/payment-method',
        component: PaymentMethodPage,
    },
    {
        path: '/privacy-policy',
        component: PrivacyPolicyPage,
    },
    {
        path: '/shipping-charge',
        component: ShippingChargePage,
    },

    {
        path: '/shipping-info',
        component: ShippingInfoPage,
    },
    {
        path: '/size-cart',
        component: SizeChartPage,
    },
    {
        path: '/store-locator',
        component: StoreLocatorPage,
    },
    {
        path: '/terms-of-sale',
        component: TermsOfSalePage,
    },
    {
        path: '/testimonial',
        component: TestimonialPage,
    },
]

const allRoutes = [...staticRoutes,...routes]
export default allRoutes

