import React, { useMemo, useState } from "react";
import { Input, Form, Select, Button } from "antd";
import './address-form.scss'
// import validationRules from "../../shared/form-validation-rules";
import PhoneInput, { formatPhoneNumber, formatPhoneNumberIntl, isValidPhoneNumber } from 'react-phone-number-input'
const { Option, OptGroup } = Select;

const AddressForm = ({ phoneValue, updatePhoneValue, phoneError, title, type, validation, countryList, updateSelectedCountry, fillValues, history }) => {

  const getOptions = (list, key, value, type, optionValue) => {
    const children = [];
    for (let i = 0; i < list.length; i += 1) {
      children.push(
        <Option key={list[i][key]} value={list[i][value]}>
          {list[i][value]}
        </Option>
      );
    }
    return children;
  };

  const onPhoneNumberChange = (value) => {
    updatePhoneValue(type, {
      value,
      isValid: value && isValidPhoneNumber(value),
      format1: value && formatPhoneNumber(value),
      format2: value && formatPhoneNumberIntl(value)
    })
  }

  const countryOptions = useMemo(() => getOptions(countryList, "id", "country"), [countryList]);

  return (
    <div className="billing-info-wrap">
      <h3>{title}</h3>
      {
        history === 'localhost' && type === 'shipping' &&
        <Button onClick={() => fillValues()} >Fill Data</Button>
      }
      <div className="row">
        <div className="col-lg-6 col-md-6">
          <div className="billing-info mb-20">
            <Form.Item label="First Name" name={`${type}_firstName`} rules={[{ required: validation, whitespace: validation, message: "Please input name!" }]}>
              <Input placeholder="Name.." />
            </Form.Item>
          </div>
        </div>
        <div className="col-lg-6 col-md-6">
          <div className="billing-info mb-20">
            <Form.Item label="Last Name" name={`${type}_lastName`} rules={[{ required: validation, whitespace: validation, message: "Please input name!" }]}>
              <Input placeholder="Name.." />
            </Form.Item>
          </div>
        </div>
        <div className="col-lg-6 col-md-6">
          <div className="billing-info mb-20">
            {/* <Form.Item label="Mobile" name={`${type}_phone`} rules={[{ required: validation,whitespace: validation, message: "Please input mobile no!" },{ len: 10, min: 10, max: 10, message: "Please enter valid number" },
              validationRules.isNumber("Please enter valid number"),]}>
              <Input placeholder="Mobile.." />
            </Form.Item> */}
            <div class="ant-col ant-form-item-label"><label for="shipping_lastName" class="ant-form-item-required" title="Mobile">Mobile</label></div>
            <PhoneInput
              placeholder="Enter phone number"
              value={phoneValue}
              onChange={onPhoneNumberChange}
              defaultCountry='IN'
            />
            {
              phoneError !== '' &&
              <div className='errormsg'>{phoneError}</div>
            }
          </div>
        </div>
        <div className="col-lg-6 col-md-6">
          <div className="billing-select mb-20">
            <Form.Item label="Email" name={`${type}_email`} rules={[{ required: validation, whitespace: validation, message: "Please input email id!" }, { type: "email", message: "Please enter valid email" },]}>
              <Input placeholder="Email.." />
            </Form.Item>
          </div>
        </div>
        <div className="col-lg-12">
          <div className="billing-info mb-20">
            <Form.Item label="Address" name={`${type}_address`} rules={[{ required: validation, whitespace: validation, message: "Please input address!" }]}>
              <Input placeholder="Address.." />
            </Form.Item>
          </div>
        </div>
        <div className="col-lg-6 col-md-6">
          <div className="billing-info mb-20">
            <Form.Item label="City" name={`${type}_city`} rules={[{ required: validation, whitespace: validation, message: "Please input city" }]}>
              <Input placeholder="City.." />
            </Form.Item>
          </div>
        </div>
        <div className="col-lg-6 col-md-6">
          <div className="billing-info mb-20">
            <Form.Item label="State" name={`${type}_state`} rules={[{ required: validation, whitespace: validation, message: "Please input state!" }]}>
              <Input placeholder="State.." />
            </Form.Item>
          </div>
        </div>
        {countryList !== undefined && (
          <div className="col-lg-6 col-md-6">
            <div className="billing-info mb-20">
              <Form.Item label="Country" name={`${type}_country`} rules={[{ required: validation, message: "Please input country!" }]}>
                <Select placeholder="Select country" allowClear onSelect={(e) => updateSelectedCountry(e, type)}>
                  {countryOptions}
                </Select>
              </Form.Item>
            </div>
          </div>
        )}
        <div className="col-lg-6 col-md-6">
          <div className="billing-info mb-20">
            <Form.Item label="Pin code" name={`${type}_pinCode`} rules={[{ required: validation, whitespace: validation, message: "Please input pin code!" }]}>
              <Input placeholder="Pin Code.." />
            </Form.Item>
          </div>
        </div>
        {/* <div className="col-lg-6 col-md-6">
          <div className="billing-info mb-20">
            <label>Email Address</label>
            <input type="text" />
          </div>
        </div> */}
      </div>

      {/* <div className="additional-info-wrap">
        <h4>Additional information</h4>
        <div className="additional-info">
          <label>Order notes</label>
          <textarea placeholder="Notes about your order, e.g. special notes for delivery. " name="message" defaultValue={""} />
        </div>
      </div> */}
    </div>
  );
};

export default AddressForm;
