import React from "react";
import commonService from "../../../services/common-service";

const CheckoutCart = ({ cartItems,symbol,rate,currency }) => {
  return (
    <ul>
      {cartItems.map((cartItem, key) => {
        return (
          <li key={key}>
            <span className="order-middle-left">{cartItem.name}</span>{" "}
            <span className="order-price">
              {cartItem.discountList !== undefined && cartItem.discountList !== null && cartItem.discountList.length ? (
                <>
                  <span className="amount old">
                    {symbol}{` `}
                    {commonService.getConvertedPrice(cartItem.price, rate, currency)}
                  </span>
                  <span className="amount">
                    {symbol}{` `}
                    {commonService.getConvertedPrice(cartItem.specialPrice, rate, currency)}
                  </span>
                </>
              ) : (
                <span className="amount">
                  {symbol}{` `}
                  {commonService.getConvertedPrice(cartItem.price, rate, currency)}
                </span>
              )}
            </span>
          </li>
        );
      })}
    </ul>
  );
};

export default CheckoutCart;
