import commonService from '../../services/common-service'
import store from 'store'
import cartActions from '../action-types'

const initialState = {
  cartList: [],
  isCartLoaded: false,
  wishList: [],
  compareList: [],
  defaultTotal: 0,
  total: 0,
  subTotal: 0,
  discount: 0,
  couponDiscount: 0,
  totalDiscount : 0,
  isCouponApplied: false,
  coupon : null,
  couponMessage : '',
  shippingCharge : 0,
  isCartValidated : false
}

const validCouponForProduct=(coupon,product)=>{
  let bool = false
  if(coupon.couponApplicableForAll){
    bool = true
  }else{
    const productCategory = coupon.categoryAndSubCategoryList.find(id => {
      if((id.categoryId === product.categoryList[0].id) && (id.subCategoryId === product.subCategoryList[0].id)){
        bool = true
      }
      return id
    })
  }
  return bool
}

const addPrices = (product, newState) => {
  let primarytotal = 0
  let discount = 0
  let subTotal = 0
  subTotal = newState.subTotal + product.price
  if (product.specialPrice) {
    discount = (product.price - product.specialPrice) + newState.discount
    primarytotal = newState.total + product.specialPrice
  } else {
    primarytotal = newState.total + product.price
  }
  let {couponDiscount} = newState
  let total = primarytotal - couponDiscount
  let totalDiscount = couponDiscount + discount
  if(newState.isCouponApplied){
    // check if product eligible
    if(validCouponForProduct(newState.coupon,product)){
      const productCouponDiscount = commonService.getProductCouponDiscount(newState.coupon,product)
      couponDiscount += productCouponDiscount
      total -= productCouponDiscount
      totalDiscount += productCouponDiscount
    }
  }
  return {
    subTotal,
    total,
    discount,
    totalDiscount,
    couponDiscount
  }
}

const removePrices = (product, newState) => {
  let primarytotal = 0
  let discount = 0
  let subTotal = 0
  subTotal = newState.subTotal - product.price
  if (product.specialPrice) {
    discount = newState.discount - (product.price - product.specialPrice)
    primarytotal = newState.total - product.specialPrice
  } else {
    primarytotal = newState.total - product.price
  }

  let {couponDiscount} = newState
  let total = primarytotal - couponDiscount
  let totalDiscount = couponDiscount + discount
  if(newState.isCouponApplied){
    // check if product eligible
    if(validCouponForProduct(newState.coupon,product)){
      const productCouponDiscount = commonService.getProductCouponDiscount(newState.coupon,product)
      couponDiscount -= productCouponDiscount
      total += productCouponDiscount
      totalDiscount -= productCouponDiscount
    }
  }

  return {
    subTotal,
    total,
    discount,
    totalDiscount,
    couponDiscount
  }
}

const getCartValues = (cartProducts,newState) => {
  let total = 0
  let discount = 0
  let subTotal = 0
  let couponDiscount = 0
  let totalDiscount = 0
  /* let total = primarytotal - couponDiscount
  let totalDiscount = couponDiscount + discount */


  if (cartProducts.length > 0) {
    cartProducts.forEach(product => {
      subTotal += product.price
     
      if (product.specialPrice !== null && product.specialPrice > 0) {
        discount = discount + product.price - product.specialPrice
        total += product.specialPrice
      } else {
        total += product.price
      }
      if(newState.isCouponApplied){
        // check if product eligible
        if(validCouponForProduct(newState.coupon,product)){
          const productCouponDiscount = commonService.getProductCouponDiscount(newState.coupon,product)
          couponDiscount += productCouponDiscount
        }
      }
    });
  }
  total -= couponDiscount
  totalDiscount += couponDiscount
  totalDiscount += discount
  return {
    subTotal,
    total,
    discount,
    totalDiscount,
    couponDiscount
  }
}

export default function cartReducer(state = initialState, action) {
  const newState = Object.assign({}, state)
  switch (action.type) {
    case cartActions.ADD_TO_CART: {
      const product = action.payload
      const productsToAdd = [...newState.cartList]
      productsToAdd.push(product)
      const { subTotal,total,discount,couponDiscount,totalDiscount } = getCartValues(productsToAdd,newState)
      newState.cartList = productsToAdd
      newState.subTotal = subTotal
      newState.total = total
      newState.defaultTotal = total
      newState.discount = discount
      newState.totalDiscount = totalDiscount
      newState.couponDiscount = couponDiscount 
      store.set('cart-products', JSON.stringify(productsToAdd))
      return newState
    }

    case cartActions.REMOVE_FROM_CART: {
      const updatedProducts = action.payload
      // const updatedProducts = newState.cartList.filter(item => item.id !== productToRemove.id)
      newState.cartList = updatedProducts
      const { subTotal,total,discount,couponDiscount,totalDiscount } = getCartValues(updatedProducts,newState)
      newState.subTotal = subTotal
      newState.total = total
      newState.defaultTotal = total
      newState.discount = discount
      newState.totalDiscount = totalDiscount
      newState.couponDiscount = couponDiscount 
      store.set('cart-products', JSON.stringify(updatedProducts))
      return newState
    }

    case cartActions.UPDATE_CART_PRODUCTS: {
      const cartProducts = action.payload
      const { subTotal,total,discount,couponDiscount,totalDiscount } = getCartValues(cartProducts,newState)
      store.set('cart-products', JSON.stringify(cartProducts))
      newState.cartList = cartProducts
      newState.subTotal = subTotal
      newState.total = total
      newState.defaultTotal = total
      newState.discount = discount
      newState.totalDiscount = totalDiscount
      newState.couponDiscount = couponDiscount
      return newState
    }

    case cartActions.UPDATE_COUPON: {
      const coupon = action.payload
      newState.coupon = coupon
      if(coupon){
        newState.isCouponApplied = true
      }else{
        newState.isCouponApplied = false
      }
     
      return newState
    }

    case cartActions.UPDATE_COUPON_MESSAGE: {
      const message = action.payload
      newState.couponMessage = message
      return newState
    }

    case cartActions.REMOVE_COUPON: {
      newState.couponMessage = ''
      newState.isCouponApplied = false
      newState.coupon = null

      const { subTotal,total,discount,couponDiscount,totalDiscount } = getCartValues(newState.cartList,newState)
      newState.subTotal = subTotal
      newState.total = total
      newState.defaultTotal = total
      newState.discount = discount
      newState.totalDiscount = totalDiscount
      newState.couponDiscount = couponDiscount
      /* newState.total = newState.total + newState.couponDiscount
      newState.defaultTotal = newState.total
      newState.totalDiscount = newState.discount
      newState.couponDiscount = 0  */
      return newState
    }

    case cartActions.UPDATE_COUPON_DISCOUNT: {

      const {cDiscount,isValidCoupon} = action.payload
      let total = newState.total + newState.couponDiscount
      let totalDiscount = newState.discount
      let couponDiscount = 0 
      if(isValidCoupon !== null ){
        total = newState.total - cDiscount
        totalDiscount += cDiscount
        couponDiscount = cDiscount
      }
      newState.total = total
      newState.defaultTotal = total
      newState.totalDiscount = totalDiscount
      newState.couponDiscount = couponDiscount 
      
      return newState
    }

    case cartActions.ADD_TO_WISHLIST: {
      const product = action.payload
      const productsToAdd = [...newState.wishList]
      productsToAdd.push(product)
      newState.wishList = productsToAdd
      store.set('wishList-products', JSON.stringify(productsToAdd))
      return newState
    }

    case cartActions.REMOVE_FROM_WISHLIST: {
      const productToRemove = action.payload
      const updatedProducts = newState.wishList.filter(item => item.id !== productToRemove.id)
      newState.wishList = updatedProducts
      store.set('wishList-products', JSON.stringify(updatedProducts))
      return newState
    }

    case cartActions.UPDATE_WISHLIST_PRODUCTS: {
      const wishListProducts = action.payload
      newState.wishList = [...wishListProducts]
      store.set('wishList-products', JSON.stringify(wishListProducts))
      return newState
    }

    case cartActions.ADD_TO_COMPARE_LIST: {
      const product = action.payload
      const productsToAdd = [...newState.compareList]
      productsToAdd.push(product)
      newState.compareList = productsToAdd
      store.set('compareList-products', JSON.stringify(productsToAdd))
      return newState
    }

    case cartActions.REMOVE_FROM_COMPARE_LIST: {
      const productToRemove = action.payload
      const updatedProducts = newState.compareList.filter(item => item.id !== productToRemove.id)
      newState.compareList = updatedProducts
      store.set('compareList-products', JSON.stringify(updatedProducts))
      return newState
    }

    case cartActions.UPDATE_COMPARE_LIST_PRODUCTS: {
      const compareListProducts = action.payload
      newState.compareList = [...compareListProducts]
      store.set('compareList-products', JSON.stringify(compareListProducts))
      return newState
    }

    case cartActions.RESET_CART: 

      newState.cartList = []
      newState.total = 0
      newState.defaultTotal = 0
      newState.subTotal = 0
      newState.discount = 0
      newState.couponDiscount = 0
      newState.totalDiscount  = 0
      newState.isCouponApplied = false
      newState.coupon  = null
      newState.couponMessage  = ''
      newState.shippingCharge = 0
      store.remove('cart-products')
      return newState

    case cartActions.RESET_WISHLIST: 
      newState.wishList = []
      store.remove('wishList-products')
      return newState

    case cartActions.RESET_COMPARE_LIST: 
      newState.compareList = []
      store.remove('compareList-products')
      return newState

    case cartActions.UPDATE_SHIPPING_CHARGE: 
      const total = newState.total - newState.shippingCharge
      newState.total = total + action.payload
      newState.shippingCharge = action.payload
      return newState

    case cartActions.UPDATE_CART_STATUS: 
      newState.isCartLoaded = action.payload
      return newState

    case cartActions.BUY_NOW: 
      return newState
    case 'UPDATE_IS_CART_VALIDATED' :
      newState.isCartValidated = action.payload
      return newState

    default:
      return state
  }
}

// check for coupon when product is added or remved