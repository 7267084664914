import {
  GET_CATEGORIES_URL,
  GET_SUB_CATEGORIES_URL,
  GET_TOP_DEALS_URL,
  GET_ATTRIBUTES_URL,
  GET_BANNERS_URL
} from '../../services/api-service'
import actionTypes from '../action-types'

const getBannersAction = () => {
  return {
    type: actionTypes.GET_BANNERS,
    payload: {
      url: GET_BANNERS_URL,
      data: {
        status: 'Web',
      },
    },
  }
}

const getCategoriesAction = () => {
  return {
    type: actionTypes.GET_CATEGORIES,
    payload: {
      url: GET_CATEGORIES_URL,
      data: {
        status: 'Web',
      },
    },
  }
}

const getSubCategoriesAction = () => {
  return {
    type: actionTypes.GET_SUB_CATEGORIES,
    payload: {
      url: GET_SUB_CATEGORIES_URL,
      data: {
        status: 'Web',
      },
    },
  }
}

const getTopDealsAction = () => {
  return {
    type: actionTypes.GET_TOP_DEALS,
    payload: {
      url: GET_TOP_DEALS_URL,
      data: {
        status: 'All',
      },
    },
  }
}

const getAttributesAction = () => {
  return {
    type: actionTypes.GET_ATTRIBUTES,
    payload: {
      url: GET_ATTRIBUTES_URL,
      data: {
        status: 'All',
      },
    },
  }
}

const HomePageActions = {
  getCategoriesAction,
  getSubCategoriesAction,
  getTopDealsAction,
  getAttributesAction,
  getBannersAction
}

export default HomePageActions
