import React from "react";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import cartSelector from "../../../../redux/selectors/cart.selector";

const MobileMenuHeader = () => {
  const compareList = useSelector(cartSelector.compareList)
  const wishList = useSelector(cartSelector.wishList)

  return (
    <div className='mobile-menu-header'>
      <div className="same-style header-compare">
        <Link to={process.env.PUBLIC_URL + "/compare"}>
          <i className="pe-7s-shuffle" />
          <span className="count-style">{compareList && compareList.length ? compareList.length : 0}</span>
        </Link>
      </div>
      <div className="same-style header-wishlist">
        <Link to={process.env.PUBLIC_URL + "/wishlist"}>
          <i className="pe-7s-like" />
          <span className="count-style">{wishList && wishList.length ? wishList.length : 0}</span>
        </Link>
      </div>
    </div>
  );
};

export default MobileMenuHeader;
