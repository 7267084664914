import { all, call, fork, put, takeLatest } from "redux-saga/effects";
import actionTypes from "../action-types";
import callFetchApi from "../../services/api";
import store from "store";

/* export function* getProductsSaga(action) {
  try {
    yield put({
      type: actionTypes.IS_PRODUCTS_BEING_FETCHED,
      payload: true,
    });
    const { url, path } = action.payload;
    const collectionData = path.split("/");
    const data = {
      status: "Web",
      count: 1000,
      // page: categoryStoreObj.pageNumber,
    };
    if (collectionData.length) {
      if (collectionData.length === 4) {
        data.categoryID = collectionData[2];
        data.subCategoryID = collectionData[3];
      }
      if (collectionData.length === 3) {
        data.categoryID = collectionData[2];
        data.subCategoryID = "";
      }
    }
    const response = yield call(callFetchApi, url, data, "GET");
    if (response.status === 200) {
      yield put({
        type: actionTypes.UPDATE_PRODUCTS,
        payload: response.data,
      });
    }
    yield put({
      type: actionTypes.IS_PRODUCTS_BEING_FETCHED,
      payload: false,
    }); */
/* const storeObj = store.get('visitedCollection')
    if (storeObj) {
      const categoryStoreObj = JSON.parse(storeObj)
      const data = {
        status: 'Web',
        count: categoryStoreObj.count,
// page: categoryStoreObj.pageNumber,
      }
      if (categoryStoreObj.categoryId) {
        data.categoryID = categoryStoreObj.categoryId
      }
      if (categoryStoreObj.subCategoryId) {
        data.subCategoryID = categoryStoreObj.subCategoryId
      }
      const response = yield call(callFetchApi, url, data, 'GET')
      if (response.status === 200) {
        yield put({
          type: actionTypes.UPDATE_PRODUCTS,
          payload: response.data,
        })
      }
    } else {
      console.log('error ')
    } */
/* } catch (error) {
    console.log("here");
    yield put({
      type: actionTypes.IS_PRODUCTS_BEING_FETCHED,
      payload: false,
    });
  }
} */

export function* getProductByIdSaga(action) {
  try {
    const { url, id } = action.payload;
    const response = yield call(callFetchApi, `${url}/${id}`, {}, "GET");
    if (response.status === 200) {
      yield put({
        type: actionTypes.UPDATE_PRODUCT_DETAIL,
        payload: response.data,
      });
    }
  } catch (error) {
    console.log("here");
  }
}

export function* filterProductsSaga(action) {
  try {
    const { data } = action.payload;
    const filteredProducts = filterProducts(data);
    yield put({
      type: actionTypes.UPDATE_FILTERED_PRODUCTS,
      payload: {
        filteredProductList: filteredProducts,
        selectedFilters: data.selectedFilters,
      },
    });
  } catch (error) {
    console.log("here", error);
  }
}

const filterProducts = (data) => {
  const { selectedFilters, productList } = data;
  const filterKeys = Object.keys(selectedFilters);
  let filteredProductList = [];
  if (filterKeys.length > 0) {
    productList.map((product) => {
      let bool = false;
      filterKeys.map((key) => {
        if (key === "price") {
          const minPrice = selectedFilters[key].min;
          const maxPrice = selectedFilters[key].max;
          const productPrice =
            product.specialPrice === "" || product.specialPrice === null || product.specialPrice === 0 ? product.price : product.specialPrice;
          if (Number(minPrice) <= Number(productPrice)) {
            if (Number(productPrice) <= Number(maxPrice)) {
              bool = true;
            }
          }
        } else {
          const { attributeList } = product;
          const selectedAttribute = attributeList.find((attribute) => attribute.key === key);
          if (selectedAttribute !== undefined && selectedAttribute !== null && selectedAttribute.value.length > 0) {
            const found = selectedAttribute.value.some((r) => selectedFilters[key].indexOf(r) >= 0);
            if (found) {
              bool = true;
            } else {
              bool = false;
            }
          }
        }
        return null;
      });
      if (bool) {
        filteredProductList.push(product);
      }
      return null;
    });
  } else {
    filteredProductList = productList;
  }
  return filteredProductList;
};

export function* getProductsSaga(action) {
  try {
    
    yield put({
      type: actionTypes.IS_PRODUCTS_BEING_FETCHED,
      payload: true,
    });
    yield put({
      type: actionTypes.UPDATE_PRODUCTS,
      payload: {},
    });
    const { url, data, type } = action.payload;
    /*  const collectionData = path.split("/");
    const data = {
      status: "Web",
      count: 1000,
      // page: categoryStoreObj.pageNumber,
    };
    if (collectionData.length) {
      if (collectionData.length === 4) {
        data.categoryID = collectionData[2];
        data.subCategoryID = collectionData[3];
      }
      if (collectionData.length === 3) {
        data.categoryID = collectionData[2];
        data.subCategoryID = "";
      }
    } */
    const response = yield call(callFetchApi, url, data, "GET");
    if (response.status === 200) {
      let list = [];
      let paginationData;
      if (type === "bestSelling" || type === "newArrivals") {
        list = response.data.productList;
      } else {
        list = response.data.content;
        paginationData = {
          totalCount: response.data.totalElements,
          page: response.data.totalElements
        }
      }
      yield put({
        type: actionTypes.UPDATE_PRODUCTS,
        payload: { 
          list,
          paginationData
        },
      });
    }
    yield put({
      type: actionTypes.IS_PRODUCTS_BEING_FETCHED,
      payload: false,
    });
  } catch (error) {
    console.log("here", error);
    yield put({
      type: actionTypes.IS_PRODUCTS_BEING_FETCHED,
      payload: false,
    });
  }
}

export function* getSimilarProductsByIdSaga(action) {
  try {
    // yield put({
    //   type: actionTypes.IS_PRODUCTS_BEING_FETCHED,
    //   payload: true,
    // });
    yield put({
      type: actionTypes.UPDATE_SIMILAR_PRODUCTS,
      payload: [],
    });
    const { url, productId } = action.payload;
    const response = yield call(callFetchApi, `${url}/${productId}`, {}, "GET");
    console.log({response})
    if (response.status === 200) {
      yield put({
        type: actionTypes.UPDATE_SIMILAR_PRODUCTS,
        payload: response?.data?.content,
      });
    }
    
    // yield put({
    //   type: actionTypes.IS_PRODUCTS_BEING_FETCHED,
    //   payload: false,
    // });
  } catch (error) {
    console.log("here");
    // yield put({
    //   type: actionTypes.IS_PRODUCTS_BEING_FETCHED,
    //   payload: false,
    // });
  }
}

function* watchGetProductByIdSaga() {
  yield takeLatest(actionTypes.GET_PRODUCT_BY_ID, getProductByIdSaga);
}

function* watchGetSimilarProductsByIdSaga() {
  yield takeLatest(actionTypes.GET_SIMILAR_PRODUCTS_BY_ID, getSimilarProductsByIdSaga);
}

function* watchFilterProductsSaga() {
  yield takeLatest(actionTypes.FILTER_PRODUCTS, filterProductsSaga);
}

function* watchGetProductsSaga() {
  yield takeLatest(actionTypes.GET_PRODUCTS, getProductsSaga);
}

export default function* productSagas() {
  yield all([fork(watchGetProductsSaga), fork(watchGetProductByIdSaga), fork(watchFilterProductsSaga), fork(watchGetSimilarProductsByIdSaga)]);
}
