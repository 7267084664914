import { all,call, fork, takeLatest, put } from 'redux-saga/effects'
import paymentActions from '../action-types'
import callFetchApi from '../../services/api'
import actionTypes from '../action-types'
// import store from 'store'

export function* generateHashSaga(action) {
  try {
    const { url,data } = action.payload
    const response = yield call(callFetchApi, url, {}, 'POST', data)
    let payload = null
    if (response.status === 200 || response.status === 201) {
      payload = response.data
      const paymentData = {
        transactionId : payload.transactionId,
        amount : data.amount,
        productInfo : data.productInfo,
        firstName : data.firstName,
        email : data.email,
        phone : data.phone,
        hash : payload.paymentHash
      }
      yield put({
        type: paymentActions.UPDATE_PAYMENT_DATA,
        payload : {...paymentData},
      })
      yield put({
        type : paymentActions.UPDATE_PAYMENT_DATA_LOADED,
        payload : true
      })
      
    }else{
      // alert('Error while placing order')
    }
    
  } catch (error) {
    console.log('here',error)
  }
}

function* watchGenerateHashSaga() {
  yield takeLatest(paymentActions.GENERATE_HASH, generateHashSaga)
}

export default function* paymentSagas() {
  yield all([
    fork(watchGenerateHashSaga)
  ])
}
