import { all, fork } from 'redux-saga/effects'
import cartSagas from './sagas/cart.saga'
import currencySagas from './sagas/currency.saga'
import customerSagas from './sagas/customer.saga'
import homePageSagas from './sagas/home-page.saga'
import orderSagas from './sagas/order.saga'
import paymentSagas from './sagas/payment.saga'
import productSagas from './sagas/products.saga'
import utilSaga from './sagas/util.saga'

export default function* saga() {
  yield all([
    fork(homePageSagas),
    fork(cartSagas),
    fork(productSagas),
    fork(orderSagas),
    fork(customerSagas),
    fork(currencySagas),
    fork(paymentSagas),
    fork(utilSaga)
    ])
}
