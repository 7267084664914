const Symbol = 'Rs. '

const staticContent = {
  freeDelivery : 'Free Delivery across India' 
}

const links = {
  orderStatus : 'Order status' 
}

const Constants = {
  Symbol,
  staticContent,
  links
}

export default Constants
