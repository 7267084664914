// import React from 'react'
// import AlertExample from 'components/common/alert-box/alert-box';
// import { toast } from 'react-toastify';
import React from 'react'
import { notification } from 'antd'
import confirm from 'antd/lib/modal/confirm';
import store from 'store';
import commonUtil from '../shared/common';
import CryptoJS from 'crypto-js'
import { encryptionKey, iV } from '../shared/config';

const getUrlString=(string)=>{
  return string
}

const addToCart=(dispatch,cartAction,product,cartList)=>{
  const cartItem = cartList.find(item => item.id === product.id)
  if(cartItem === undefined){
    dispatch(cartAction.addProductToCartAction(product))
    showNotification('success','Product Added','Product added to cart')
  }else{
    showNotification('error','Product not added','Product already added to cart')
  }
}

const addToWishList=(dispatch,cartAction,product,wishList)=>{
  const listItem = wishList.find(item => item.id === product.id)
  if(listItem === undefined){
    dispatch(cartAction.addProductToWishListAction(product))
    showNotification('success','Product Added','Product added to wishlist')
  }else{
    showNotification('error','Product not added','Product already added to wishlist')
  }
}

const addToCompareList=(dispatch,cartAction,product,compareList)=>{
  const listItem = compareList.find(item => item.id === product.id)
  if(listItem === undefined){
    dispatch(cartAction.addProductToCompareListAction(product))
    showNotification('success','Product Added','Product added to compare list')
  }else{
    showNotification('error','Product not added','Product already added to compare list')
  }
}

const buyNow=(history,dispatch,cartAction,product,cartList)=>{
  // check if product is in cart
  // yes - go to checkout page
  // no - add product to cart and then go to checkout page
  if(isProductInCart(product,cartList)){
    proceedToCheckout(history,cartList,dispatch,cartAction)
  } else {  
    dispatch(cartAction.buyNowAction(history,product))
  }
}

const removeFromCart=(dispatch,cartAction,cartList,product=null)=>{
  
  if(product){
    dispatch(cartAction.removeProductFromCartAction(product,cartList))
    showNotification('success','Product removed','Product removed from cart')
  } else {
    confirm({
      title: `Do you want to remove all products from your cart ?`,
      icon: '',
      content: '',
      onOk() {
        dispatch(cartAction.removeAllProductsFromCartAction())
      },
      onCancel() {
        // console.log('Cancel')
      },
    })
    
  }
}

const removeFromWishList=(dispatch,cartAction,product=null)=>{
  if(product){
    dispatch(cartAction.removeProductFromWishListAction(product))
    showNotification('success','Product removed','Product removed from wishlist')
  } else {
    confirm({
      title: `Do you want to remove all products from your wishlist ?`,
      icon: '',
      content: '',
      onOk() {
        dispatch(cartAction.removeAllProductsFromWishListAction())
      },
      onCancel() {
       // console.log('Cancel')
      },
    })
    
  }
}

const removeFromCompareList=(dispatch,cartAction,product=null)=>{
  if(product){
    dispatch(cartAction.removeProductFromCompareListAction(product))
    showNotification('success','Product removed','Product removed from compare list')
  } else {
    confirm({
      title: `Do you want to remove all products from your compare list ?`,
      icon: '',
      content: '',
      onOk() {
        dispatch(cartAction.removeAllProductsFromCompareListAction())
      },
      onCancel() {
        // console.log('Cancel')
      },
    })
    
  }
}

const isProductInCart=(product,cartList)=>{
  const cartItem = cartList.find(item => item.id === product.id)
  return cartItem !== undefined 
}

const showErrorNotification = errors => {
  let errorMessage = ``
  if (errors !== undefined) {
    if (errors.length > 0) {
      errorMessage = errors.map(error => {
        return (
          <p style={{ color: 'red' }}>
            {error.field !== undefined && error.field && error.field !== ''
              ? `${error.field} : `
              : ''}
            {error.defaultMessage}
          </p>
        )
      })
    }
    showNotification('error', 'Error', errorMessage)
  }
}

const showNotification = (type, title, message) => {
  notification[type]({
    message: title,
    description: message,
    duration : 2
  })
}

const scrollToTop=()=>{
  window.scrollTo(0,0)
}

const proceedToCheckout=(history,cartList,dispatch,cartAction)=>{
  if(cartList.length > 0){
    // dispatch(cartAction.validateCartProductsAction(cartList,history))
     history.push('/checkout')
  }else{
    showNotification('error','No Products','No products in cart')
  }
}

const navigateToCollection=(e,history,categoryName=null,categoryAliasName=null,subCategoryName=null,subCategoryAliasName=null,count=20,pageNumber=1)=>{
  // e.stopPropagation();
  // e.preventDefault();
  const visitedCollection = {
    categoryName,
    categoryAliasName,
    subCategoryName,
    subCategoryAliasName,
    count,
    pageNumber
  }
  let url = '/collections/'
  /* url += categoryName !== null ? replaceURI(categoryName) : replaceURI(subCategoryName)
  if(categoryName && subCategoryName){
    url += `/${replaceURI(subCategoryName)}`
  }
  history.push(url)
  store.set('visitedCollection',JSON.stringify(visitedCollection)) */
  url += categoryName !== null ? categoryAliasName : subCategoryAliasName
  if(categoryName && subCategoryName){
    url += `/${subCategoryAliasName}`
  }
  url += `?page=1&s=20`
  history.push(url)
  // store.set('visitedCollection',JSON.stringify(visitedCollection))
}

const replaceURI=(string)=>{
  let newString = string
  newString= newString.replace(/ /g, '') // for empty spaces
  newString= newString.replace(/%/g, '') // for empty spaces
  return newString
}

const getDiscountTagValue=(discountName,discountValue,discountType,symbol,rate,currency)=>{
  const discountTagValue = discountType === 'PERCENTAGE' ? `${discountName} ${discountValue}%` : `${discountName} ${symbol}. ${getConvertedPrice(Number(discountValue),rate,currency)}`
  return discountTagValue
}

const getProductCouponDiscount=(coupon,product)=>{
  let productPrice = 0
  let couponDiscount = 0
  if(product.discountList !== undefined && product.discountList.length > 0 && commonUtil.isValidObject(product.specialPrice)){
    productPrice = product.specialPrice
  }else{
    productPrice = product.price
  }
  if(coupon.discountType==='CASH'){
    couponDiscount = coupon.cashValue
  }else{
    couponDiscount = (productPrice * (coupon.discountPercent)/100)
  }
  return couponDiscount
}   

const getConvertedPrice=(originalPrice,conversionRate,currency)=>{
  // Params : originalPrice,conversionRate,currency
  // if currency is INR ignore , else convert price
  return currency === 'INR' ? Number(originalPrice)  :  (Number(Number(conversionRate).toFixed(4)) *  Number(originalPrice)).toFixed(2)
}

const getEncryption = data => {
  const key = CryptoJS.enc.Latin1.parse(encryptionKey)
  const iv = CryptoJS.enc.Latin1.parse(iV)
  const encrypted = CryptoJS.AES.encrypt(data, key, {
    iv,
    mode: CryptoJS.mode.CBC,
    padding: CryptoJS.pad.ZeroPadding,
  })
  return encrypted
}

const getPhoneNumber=(number)=>{
  let newNumber = number.split(' ')
  const code = newNumber.shift()
  let phone = ''
  newNumber.forEach(element => {
    phone += element.toString() 
  });
  return {
    code,
    phone
  }
}

const commonService={
  getUrlString,
  addToCart,
  removeFromCart,
  showNotification,
  scrollToTop,
  isProductInCart,
  proceedToCheckout,
  navigateToCollection,
  getDiscountTagValue,
  getProductCouponDiscount,
  getConvertedPrice,
  addToWishList,
  removeFromWishList,
  removeFromCompareList,
  addToCompareList,
  showErrorNotification,
  getEncryption,
  buyNow,
  getPhoneNumber
}

export default commonService