import React, { useEffect, useState } from "react";
import { useLocation } from "react-router";
import { useDispatch, useSelector } from "react-redux";
import productSelector from "../../../redux/selectors/product.selector";
import productActions from "../../../redux/actions/products.actions";
import ProductImageDescription from "./ProductImageDescription";
import Breadcrumb from "../../../wrappers/breadcrumb/Breadcrumb";
import ProductDescriptionTab from "./ProductDescriptionTab";
import commonService from "../../../services/common-service";
import { BreadcrumbsItem } from "react-breadcrumbs-dynamic";
import ProductGridHome from "../../../wrappers/product/ProductGridHome";
import SectionTitle from "../../section-title/SectionTitle";

const ProductDetailRoot = () => {
  const location = useLocation();
  const productId = location.pathname.split("/")[3];
  const dispatch = useDispatch();
  const product = useSelector(productSelector.productDetail);
  const similarProducts = useSelector(productSelector.similarProductList);

  useEffect(() => {
    if (productId) {
      dispatch(productActions.getProductById(productId));
      dispatch(productActions.getSimilarProductsAction(productId));
      commonService.scrollToTop();
    }
  }, [productId]);

  const [productImages, updateProductImages] = useState([]);

  useEffect(() => {
    const images = [];
    updateProductImages([]);
    if (product !== null && product !== undefined) {
      if (product.productImage.mainImageUrl !== undefined && product.productImage.mainImageUrl !== null && product.productImage.mainImageUrl !== "") {
        images.push(product.productImage.mainImageUrl);
      }
      if (
        product.productImage.otherImage1Url !== undefined &&
        product.productImage.otherImage1Url !== null &&
        product.productImage.otherImage1Url !== ""
      ) {
        images.push(product.productImage.otherImage1Url);
      }
      if (
        product.productImage.otherImage2Url !== undefined &&
        product.productImage.otherImage2Url !== null &&
        product.productImage.otherImage2Url !== ""
      ) {
        images.push(product.productImage.otherImage2Url);
      }
      if (
        product.productImage.otherImage3Url !== undefined &&
        product.productImage.otherImage3Url !== null &&
        product.productImage.otherImage3Url !== ""
      ) {
        images.push(product.productImage.otherImage3Url);
      }
      const imagesLength = images.length;
      if (imagesLength < 4) {
        if (imagesLength === 1) {
          // images.fill(images[0],1,3)
          images[1] = images[0];
          images[2] = images[0];
          images[3] = images[0];
        }
        if (imagesLength === 2) {
          images[2] = images[0];
          images[3] = images[1];
        }
        if (imagesLength === 3) {
          images[3] = images[0];
        }
      }
      updateProductImages(images);
    }
  }, [product]);

  useEffect(() => {
    return () => {
      updateProductImages([]);
    };
  }, []);

  return (
    <>
      {/* breadcrumb */}

      {product !== null && (
        <>
          <BreadcrumbsItem to={process.env.PUBLIC_URL + "/"}>Home</BreadcrumbsItem>
          <BreadcrumbsItem to={process.env.PUBLIC_URL + location.pathname}>{product.name}</BreadcrumbsItem>
          <Breadcrumb />

          {product !== null && (
            <>
              {/* product description with image */}
              {productImages.length && (
                <ProductImageDescription
                  spaceTopClass="pt-100"
                  spaceBottomClass="pb-100"
                  product={product}
                  galleryType="leftThumb"
                  productImages={productImages}
                />
              )}

              {/* product description tab */}
              <ProductDescriptionTab
                spaceBottomClass="pb-90"
                productFullDesc={product.description}
                productVideoUrl={product.videoUrl}
                productAttribute={product.attributeList}
              />
            </>
          )}
          {/* related product slider */}
          <div className="">
            <SectionTitle titleText="Related Products" positionClass="text-center" spaceClass="mb-50" />
            <ProductGridHome type="new" limit={4} spaceBottomClass="mb-35" products={similarProducts} />
          </div>
        </>
      )}
    </>
  );
};

export default ProductDetailRoot;
