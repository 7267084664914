// import PropTypes from "prop-types";
import React, {useEffect} from "react";
import { useSelector } from "react-redux";
import Checkout from "../components/pages/checkout-page/checkout";
import paymentSelector from "../redux/selectors/payment.selector";
import { PAYMENT_C_URL, PAYMENT_FAILURE_URL, PAYMENT_SUCCESS_URL } from "../services/api-service";
import { Merchant_Key, Payment_Gateway_Url } from "../shared/config";

const CheckoutPage = () => {
  const isPaymentDataLoaded = useSelector(paymentSelector.isPaymentDataLoaded);
  const paymentData = useSelector(paymentSelector.paymentData);

  useEffect(() => {
    if (isPaymentDataLoaded) {
      document.getElementById("payment_form_submit").submit();
    }
  }, [isPaymentDataLoaded]);

  return (
    <>
      <Checkout />
      {isPaymentDataLoaded && (
        <form action={Payment_Gateway_Url} id="payment_form_submit" method="post">
          <input type="hidden" id="udf5" name="udf5" value="" />
          <input type="hidden" id="udf4" name="udf4" value="" />
          <input type="hidden" id="udf3" name="udf3" value="" />
          <input type="hidden" id="udf2" name="udf2" value="" />
          <input type="hidden" id="udf1" name="udf1" value="" />

          <input type="hidden" id="surl" name="surl" value={PAYMENT_SUCCESS_URL} />
          <input type="hidden" id="furl" name="furl" value={PAYMENT_FAILURE_URL} />
          <input type="hidden" id="curl" name="curl" value={PAYMENT_C_URL} />
          <input type="hidden" id="key" name="key" value={Merchant_Key} />

          <input type="hidden" id="txnid" name="txnid" value={paymentData.transactionId} />
          <input type="hidden" id="amount" name="amount" value={paymentData.amount} />
          <input type="hidden" id="productinfo" name="productinfo" value={paymentData.productInfo} />
          <input type="hidden" id="firstname" name="firstname" value={paymentData.firstName} />
          <input type="hidden" id="email" name="email" value={paymentData.email} />
          <input type="hidden" id="phone" name="phone" value={paymentData.phone} />
          <input type="hidden" id="hash" name="hash" value={paymentData.hash} />
          <input type="hidden" value="submit" />
        </form>
      )}
    </>
  );
};

export default CheckoutPage;
