import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router";
import cartSelector from "../../../redux/selectors/cart.selector";
import customerSelector from "../../../redux/selectors/customer.selector";
import commonService from "../../../services/common-service";
import Breadcrumb from "../../../wrappers/breadcrumb/Breadcrumb";
import CheckoutRoot from "./checkout-root";
import UserRoot from "./user-root";
import { BreadcrumbsItem } from "react-breadcrumbs-dynamic";
import orderSelector from "../../../redux/selectors/order.selector";
import CartActions from "../../../redux/actions/cart.actions";

const Checkout = () => {
  const history = useHistory();
  const dispatch = useDispatch()
  const cartList = useSelector(cartSelector.cartList);
  const isCartLoaded = useSelector(cartSelector.isCartLoaded);
  const isCustomerLoggedIn = useSelector(customerSelector.isCustomerLoggedIn);
  const isGuestFlow = useSelector(customerSelector.isGuestFlow);
  const [showLoadingModal, updateShowLoadingModal] = useState(true);
  const orderLoading = useSelector(orderSelector.orderLoading);
  const isCartValidated = useSelector(cartSelector.isCartValidated)

  useEffect(() => {
    if (orderLoading) {
      updateShowLoadingModal(true);
      commonService.scrollToTop();
    } else {
      updateShowLoadingModal(false);
    }
  }, [orderLoading]);

  useEffect(() => {
    if(!isCartValidated){
      dispatch(CartActions.validateCartProductsAction(cartList,history))
      
    }
    
  }, [cartList]);
    
  useEffect(() => {
    commonService.scrollToTop();
    // dispatch(CartActions.validateCartProductsAction(cartList,history))
    // dispatch(CartActions.updateShippingChargeAction(0))
  }, []);
  return (
    <>
      {/* breadcrumb */}
      <BreadcrumbsItem to={process.env.PUBLIC_URL + "/"}>Home</BreadcrumbsItem>
      <BreadcrumbsItem to={process.env.PUBLIC_URL + "/checkout"}>Checkout</BreadcrumbsItem>
      <Breadcrumb />
      <div className="checkout-area pt-95 pb-100">
        <div className="container">
          {showLoadingModal ? (
            <div className="loading-order">
              <img src="/assets/images/loading.gif" />
              <h3>Please wait while your order is getting placed.</h3>
            </div>
          ) : (
            <>
              {!isCustomerLoggedIn ? <UserRoot isGuestFlow={isGuestFlow} /> : ""}
              <CheckoutRoot />
            </>
          )}
        </div>
      </div>
    </>
  );
};

export default Checkout;
