const productSelector = {
  productList: state => state.productReducer.productList,
  pageNumber: state => state.productReducer.pageNumber,
  count: state => state.productReducer.count,
  productDetail: state => state.productReducer.productDetail,
  filteredProductList: state => state.productReducer.filteredProductList,
  selectedFilters : state => state.productReducer.selectedFilters,
  isProductsBeingFetched : state => state.productReducer.isProductsBeingFetched,
  similarProductList: state => state.productReducer.similarProductList,
  paginationData: state => state.productReducer.paginationData
}

export default productSelector
