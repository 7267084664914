import currencyActions from '../action-types'

const initialState = {
  currency: [{
    conversionRate: 1,
    country: "",
    name: "INR",
    symbol: 'Rs'
  }],
  selectedCurrency : {
    conversionRate: 1,
    country: "",
    name: "INR",
    symbol: 'Rs'
  }
}

export default function currencyReducer(state = initialState, action) {
  const newState = Object.assign({}, state)
  switch (action.type) {
    case currencyActions.UPDATE_CURRENCYS:
      newState.currency = [...action.payload]
      return newState

    case currencyActions.UPDATED_SELECTED_CURRENCY:
      newState.selectedCurrency = {...action.payload}
      return newState
    
    default:
      return state
  }
}
