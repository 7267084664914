import { combineReducers } from 'redux';
import homePageReducer from './reducers/home-page.reducer'
import cartReducer from './reducers/cart.reducer'
import productReducer from './reducers/product.reducer'
import orderReducer from './reducers/order.reducer'
import customerReducer from './reducers/customer.reducer'
import currencyReducer from './reducers/currency.reducer'
import utilReducer from './reducers/util.reducer'
import paymentReducer from './reducers/payment.reducer'

const rootReducer = combineReducers({
  homePageReducer,
  cartReducer,
  productReducer,
  orderReducer,
  customerReducer,
  currencyReducer,
  utilReducer,
  paymentReducer
});

export default rootReducer